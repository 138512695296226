import { useMemo } from 'react'
import { Center, Loader, clsx } from '@mantine/core'
import classNames from 'classnames'
import { IconCircleCheck } from '@tabler/icons-react'
import './style.scss'

export const Product = (props) => {
  const {
    order = 0,
    className,
    product,
    price,
    priceSelected,
    onSelect = () => {},
  } = props

  const formattedPrice = useMemo(() => {
    if (!price) return
    const priceDecimal = price.unit_amount / 100,
    formatter = new Intl.NumberFormat('en-us', {
        style: 'currency',
        currency: price.currency ?? 'usd',
      })

    return formatter.format(priceDecimal)
  }, [price])

  const animateOrder = {
    '--pt-animate-in-order': order,
  }

  const features = useMemo(() => {
    const { metadata } = price
    if (!metadata) return []

    return Object.keys(metadata).reduce((featureList, metaKey) => {
      if (!/feature/i.test(metaKey)) return featureList
      featureList.push(metadata[metaKey])
      return featureList
    }, [])
  })

  const intervalLabel = price.recurring.interval === 'month'
    ? 'quarter'
    : 'year'

  return (
    <div style={animateOrder} className={clsx('product', className)}>
      <div className="product__content">
        <div className="product__content__name">{product.name}</div>
        <div className="product__content__description">{product.description}</div>

        <div className="product__content__priceAndButton">
          <div className="product__content__price">
            <span className="product__content__price__text">{formattedPrice}</span>
            <span className="product__content__price__interval">per <br /> {intervalLabel}</span>
          </div>
          {priceSelected === price.id ? (
            <div className="product__content__progress__container">
              <Center mt="md">
                <Loader size="md" color="yellow" />
              </Center>
            </div>
          ) : (
            <button
              className="product__content__button"
              disabled={Boolean(priceSelected)}
              onClick={() => onSelect({ product, price })}
            >
              <span>Start trial</span>
            </button>
          )}
        </div>
      </div>

      {features.length ? (
        <div className="product__feature-list">
          <span className="product__feature-list__header">This includes:</span>
          <ul className="product__feature-list__items">
            {features.map((productFeature, index) => (
              <li key={index}>
                <span className="product__feature-list__iems__item-icon">
                  <IconCircleCheck />
                </span>
                <span className="product__feature-list__items__item-text">{productFeature}</span>
              </li>
            ))}
          </ul>
        </div>
      ) : null}
    </div>
  )
}
