import { useEffect, useState } from 'react'
import { z } from 'zod'
import {
  Button,
  Modal,
  rem,
  createStyles,
  TextInput,
  SimpleGrid,
  UnstyledButton,
  Text,
  Flex,
  Loader,
} from '@mantine/core'
import { useForm, zodResolver } from '@mantine/form'
import {
  IconAlertCircle,
} from '@tabler/icons-react'

import { usePhoneInput } from '../../../hooks/usePhoneInput'
import { updateClient } from '../../tickets/modals/share-modal/actions'
import { noop } from '../../../utils/noop'

const useStyles = createStyles((theme) => ({
  button: {
    borderRadius: theme.radius.sm,
    padding: `${theme.spacing.sm} ${theme.spacing.lg}`,
    cursor: 'pointer',
    height: rem('3.25rem'),
    maxWidth: rem('13rem'),
  },
  tableActionRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  tableHeader: {
    backgroundColor: '#ffffff',
  },
  tableHeadCol: {
    position: 'sticky',
    top: 0,
    backgroundColor: '#ffffff',
  },
  tableHeadColIcon: {
    zIndex: 1,
  },
}))

const schemaAggregate = z.object({
  name: z.string().min(1, { message: 'That name looks short' }),
  email: z.string().email({ message: 'That email does not look right' }),
  phone: z.string().min(10, { message: 'That phone number does not look right' }),
})

const transpile = (client) => ({
  business: client.business || '',
  name: client.name,
  phone: client.phone || '',
  email: client.email || '',
})

export const EditClientModal = (props) => {
  const {
    target = null,
    company,
    onClose = noop,
    onSaved = noop,
  } = props

  const [isActive, setActive] = useState(false),
    [errorMessage, setError] = useState(''),
    [processing, setProcessing] = useState(false),
    { classes } = useStyles(),
    [enforceFormat, formatPhone] = usePhoneInput()

  const handleClose = (success = false) => {
    setActive(false)
    onClose(success)
  }

  const clientForm = useForm({
    initialValues: {
      name: '',
      email: '',
      phone: '',
      business: '',
    },

    validate: zodResolver(schemaAggregate.required()),

    transformValues: (values) => ({
      formattedPhone: formatPhone(values.phone),
    }),
  })

  const onPhoneInput = (event) => {
    const { target: { value = '', name = '' } = {} } = event || {}
    if (name === 'client.phone') {
      clientForm.setFieldValue('phone', value.replace(/\D/g, '').substring(0, 10))
    }
  }

  const handleUpdate = async () => {
    setError('')
    setProcessing(true)
    clientForm.validate()
    if (!clientForm.isValid()) {
      setError('Please double check the information above and try again.')
      setProcessing(false)
      return false
    }

    try {
      const updatedClient = { ...target, ...clientForm.values },
        updateClientResponse = await updateClient(updatedClient, company)

      if (!updateClientResponse.ok) {
        throw new Error('Failed to update client')
      }

      onSaved(updatedClient)
    } catch (err) {
      setError('We hit a snag updating this client. Please try again later or contact support.')
    } finally {
      setProcessing(false)
    }
  }

  const handleCancelAdd = () => {
    setProcessing(false)
    clientForm.reset()
    handleClose(true)
  }

  // Set active client
  useEffect(() => {
    setActive(!!target)
    if (target) {
      clientForm.setValues(transpile(target))
    }
  }, [target])

  return (
    <Modal opened={isActive} onClose={handleCancelAdd} title="Edit Client">
      <SimpleGrid
        cols={2}
        mt="lg"
        spacing="md"
        breakpoints={[
          { maxWidth: '30rem', cols: 1, spacing: 'sm' },
          { maxWidth: '72rem', cols: 2, spacing: 'sm' },
        ]}
      >
        <TextInput
          label="Full name"
          name="client.name"
          placeholder="John Smith"
          size="sm"
          withAsterisk
          {...clientForm.getInputProps('name')}
        />

        <TextInput
          className={classes.textInput}
          label="Business name"
          name="client.business"
          placeholder="Acme Co."
          size="sm"
          {...clientForm.getInputProps('business')}
        />

        <TextInput
          label="Phone number"
          type="tel"
          name="client.phone"
          placeholder="3125555555"
          size="sm"
          withAsterisk
          {...clientForm.getInputProps('phone')}
          value={clientForm.getTransformedValues().formattedPhone}
          onKeyDown={enforceFormat}
          onChange={onPhoneInput}
        />

        <TextInput
          className={classes.textInput}
          label="Email address"
          name="client.email"
          placeholder="jsmith@acme.com"
          type="email"
          size="sm"
          withAsterisk
          {...clientForm.getInputProps('email')}
        />
      </SimpleGrid>

      {errorMessage ? (
        <Flex mt="md" mb="md">
          <IconAlertCircle size="1.5rem" color="red" />
          <Text fz="sm" ml="md" color="red">{errorMessage}</Text>
        </Flex>
      ) : null}

      <Flex
        mt="md"
        mih={50}
        gap="md"
        justify="center"
        align="center"
        direction="row"
        wrap="wrap"
      >
        <UnstyledButton onClick={handleCancelAdd} disabled={processing}>
          Cancel
        </UnstyledButton>
        <Button onClick={handleUpdate} disabled={processing}>
          {processing ? (
            <Loader color="blue" size="sm" />
          ) : 'Update client'}
        </Button>
      </Flex>
    </Modal>
  )
}
