export class DownloadService {
  _origin

  constructor() {
    this._origin = process.env.NODE_ENV === 'production'
      ? 'https://api.econcrete.ly'
      : 'http://localhost:3001'
  }

  _handleDownload = async (options) => {
    const { path } = options,
      downloadPath = `${path}`.replace(/([^:]\/)\/+/g, '$1'), // remove duplicate slashes
      url = new URL(`auth/download/${downloadPath}`, this._origin)

    window.open(url.toString(), '_blank')
  }

  download = (path, options = {}) => {
    return this._handleDownload({ ...options, path })
  }
}

const downloadService = new DownloadService()
export default downloadService
