import { Text, Container, SimpleGrid, rem, Flex, Box, createStyles } from '@mantine/core';
import { IconCheck } from '@tabler/icons-react';
import classes from './zigzag.module.scss';

export const zigZagFeatures = [
  {
    title: 'Streamlined Project Documentation',
    titleSubtext: 'e-Tickets',
    description: `Effortlessly manage your concrete operations with eConcretely's e-Ticket feature. From recording essential details to ensuring compliance and transparency, our e-ticketing system revolutionizes the way you handle document projects.`,
    points: [
      'Capture, Store, and Share',
      'Inspector-Ready Compliance',
      'Location and Weather Tracking',
    ],
    imageSrc: 'https://econcrete.nyc3.cdn.digitaloceanspaces.com/assets/demo-create-ticket.jpg',
    imageAlt: 'Screenshot of Ticket Creation',
  },
  {
    title: 'Optimize Your Concrete Mixer Fleet',
    titleSubtext: 'Truck Management',
    description: `Take control of your concrete fleet with eConcretely's Truck Management feature. From efficient configurations to maintenance tracking, we empower you to optimize and enhance the performance of your volumetric cement trucks.`,
    points: [
      'Configurations Made Easy',
      'Material and Maintenance Tracking',
      'Scaled Truck Management',
    ],
    direction: 'rtl',
    imageSrc: 'https://econcrete.nyc3.cdn.digitaloceanspaces.com/assets/demo-edit-truck.jpg',
    imageAlt: 'Screenshot of Truck Editing',
  },
  {
    title: 'Tailor Concrete Formulations',
    titleSubtext: 'Mix Design Mastery',
    description: `Achieve precision in every concrete mix with eConcretely's Mix Design Mastery feature. Our customizable and compliant interface allows you to tailor mix designs effortlessly, supporting both standard and specialty formulations.`,
    points: [
      'Customization at Your Fingertips',
      'Secure and Unlimited Storage',
      'Specialty Mix Support',
    ],
    imageSrc: 'https://econcrete.nyc3.cdn.digitaloceanspaces.com/assets/demo-create-mix.jpg',
    imageAlt: 'Screenshot of Mix Design Creation',
  },
];


const Feature = (props) => {
  const {
    title = '',
    titleSubtext = '',
    description = '',
    points = [],
    direction = '', // '' || 'rtl'
    imageSrc = '',
    imageAlt = '',
  } = props

  const useStyles = createStyles(() => ({}))
  const { cx } = useStyles()
  const isRTL = direction === 'rtl'

  const imageProps = direction === 'rtl'
    ? { mr: "sm" }
    : { ml: "sm" }

  return (
    <Flex
      className={cx(classes.zigzagFeature, { [classes.zigzagFeatureRTL]: isRTL })}
      mih={50}
      gap="md"
      justify="flex-start"
      align="flex-start"
      direction={direction === 'rtl' ? 'row-reverse' : 'row'}
    >
      <Box className={classes.zigzagFeatureImageWrapper}>
        <img src={imageSrc} alt={imageAlt} data-aos="fade-up" {...imageProps} />
      </Box>
      <Box className={classes.zigzagFeatureTextWrapper} data-aos={direction === 'rtl' ? 'fade-left' : 'fade-right'}>
        <Text mb={1} fz="md" className={classes.zigzagFeatureSubtext}>{titleSubtext}</Text>
        <Text mb={7} fz="lg" fw={500}>{title}</Text>
        <Text className={classes.zigzagFeatureDescription} fz="md" c="dimmed" lh={1.6} mb="lg">
          {description}
        </Text>
        <ul className={classes.zigzagFeatureList}>
          {points.map((point) => (
            <Box component="li" mb="md">
              <IconCheck style={{ width: rem(20), height: rem(20) }} stroke={1.5} color="green" />
              <Text component="span" ml="sm" size="md">{point}</Text>
            </Box>
          ))}
        </ul>
      </Box>
    </Flex>
  )
}

export const Zigzag = () => {
  const features = zigZagFeatures.map((feature, index) => <Feature {...feature} key={index} />);

  return (
    <Container className={classes.wrapper}>
      <SimpleGrid
        cols="1"
        spacing="xl"
      >
        {features}
      </SimpleGrid>
    </Container>
  );
}
