import { v4 as uuidv4 } from 'uuid'
import { load as loadFingerprint } from '@fingerprintjs/fingerprintjs'
import Cookies from 'js-cookie'

export const DEVICE_FINGERPRINT = 'device_finger_print'

export class FingerprintService {
  dfp // device finger print

  constructor() {
    this.getFingerprint()
  }

  getFingerprint = async () => {
    const cookieDFP = Cookies.get(DEVICE_FINGERPRINT)
    if (cookieDFP) return cookieDFP

    try {
      const fingerprint = await loadFingerprint(),
        { visitorId } = await fingerprint.get()

      this.dfp = visitorId
    } catch (err) {
      console.warn(`Unable to set device fingerprint using 'FingerprintJS' -- Failling back to uuidv4`, err)
      this.dfp = uuidv4()
    }

    // store new device fingerprint as cookie (Exp 10 years)
    Cookies.set(
      DEVICE_FINGERPRINT,
      String(this.dfp),
      {
        expires: 365 * 10,
        sameSite: 'lax',
        secure: false,
      }
    )

    return this.dfp
  }
}

const fingerprintService = new FingerprintService()
export default fingerprintService
