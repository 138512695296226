
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Container,
  Title,
  rem,
  ActionIcon,
  createStyles,
  Menu,
  SegmentedControl,
  ScrollArea,
  Box,
  Table,
  Center,
  Button,
} from '@mantine/core'
import {
  IconDotsVertical,
  IconTrash,
  IconPencil,
  IconPlus,
  IconExternalLink,
  IconChecks,
  IconClipboardCheck,
  IconShare2,
  IconCloudDownload,
  IconSettings,
} from '@tabler/icons-react'
import fetchService from '../../services/fetch.service'
import browserStorageService, { STORAGE_KEY } from '../../services/browser-storage.service'
import { LoadingData } from '../../common/loading-data'
import { useAnalytics } from '../../hooks/useAnalytics'
import { TICKET_STATUSES } from './helper'
import { approveTicket, downloadTicketPDF, submitTicket, viewTicketPDF, voidTicket } from './actions'
import { TicketStatusBadge } from './shared/ticket-status-badge'
import { ShareModal } from './modals/share-modal'
import { LimitTextOpen } from '../../common/shorten-text'

const useStyles = createStyles((theme) => ({
  card: {
    overflow: 'inherit',
  },
  button: {
    borderRadius: theme.radius.sm,
    padding: `${theme.spacing.sm} ${theme.spacing.lg}`,
    cursor: 'pointer',
    height: rem('3.25rem'),
    maxWidth: rem('13rem'),
  },
  tableActionRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  tableHeader: {
    backgroundColor: '#ffffff',
  },
  tableHeadCol: {
    position: 'sticky',
    top: 0,
    backgroundColor: '#ffffff',
  },
  tableHeadColIcon: {
    zIndex: 1,
  },
}))

const ManageMenu = (props) => {
  const {
    children,
    ticket = {},

    onViewPDF,
    onDownloadPDF,
    onVoid,
    onEdit,
    onView,
    onApprove,
    onSubmit,
    onShare,
  } = props

  const iconSize = 16,
    status = ticket.status

  const editableStatus = ![TICKET_STATUSES.VOID, TICKET_STATUSES.PUBLISHED, TICKET_STATUSES.APPROVED].includes(status),
    reviewable = status === TICKET_STATUSES.IN_REVIEW,
    voidable = status !== TICKET_STATUSES.VOID,
    submittable = status === TICKET_STATUSES.DRAFT,
    canViewPDF = ticket.meta.storedFile?.length,
    [canModify, setCanModify] = useState(false),
    [canEdit, setCanEdit] = useState(false)

  useEffect(() => {
    browserStorageService.getItem(STORAGE_KEY.ROLE)
      .then((role) => {
        setCanModify(role === 'OWNER')
      })
  }, [setCanModify])

  useEffect(() => {
    browserStorageService.getItem(STORAGE_KEY.SESSION)
      .then((session) => {
        const modifyAccess = [ticket?.refs.submittedBy, ticket?.refs.assignedTo].includes(session.userId) || canModify;
        setCanEdit(modifyAccess);
      })
  }, [canModify, setCanEdit, ticket?.refs])

  return (
    <Menu shadow="md" width={200}>
      <Menu.Target>{children}</Menu.Target>
      <Menu.Dropdown>
        <Menu.Label>{ticket?.ticketUid || '<UUID>'}</Menu.Label>
        <Menu.Item
          onClick={() => onView(ticket)}
          icon={<IconExternalLink size={iconSize} />}
        >
          View Ticket
        </Menu.Item>

        <Menu.Item
          onClick={() => onShare(ticket)}
          icon={<IconExternalLink size={iconSize} />}
        >
          Share Ticket
        </Menu.Item>

        {canViewPDF ? (
          <>
            <Menu.Item
              onClick={() => onViewPDF(ticket)}
              icon={<IconShare2 size={iconSize} />}
            >
              Open PDF
            </Menu.Item>

            <Menu.Item
              onClick={() => onDownloadPDF(ticket)}
              icon={<IconCloudDownload size={iconSize} />}
            >
              Download PDF
            </Menu.Item>
          </>
        ) : null}

        {submittable ? (
          <Menu.Item
            onClick={() => onSubmit(ticket)}
            icon={<IconClipboardCheck size={iconSize}/>}
          >
            Submit Ticket
          </Menu.Item>
        ) : null}

        {(canEdit && editableStatus) ? (
          <Menu.Item
            onClick={() => onEdit(ticket)}
            icon={<IconPencil size={iconSize}/>}
          >
            Edit Ticket
          </Menu.Item>
        ) : null}

        {canModify ? (
          <>
            {reviewable ? (
              <Menu.Item
                color="green"
                onClick={() => onApprove(ticket)}
                icon={<IconChecks size={iconSize}/>}
              >
                Approve Ticket
              </Menu.Item>
            ) : null}
            {voidable ? (
              <Menu.Item
                color="red"
                onClick={() => onVoid(ticket)}
                icon={<IconTrash size={iconSize} />}
              >
                Void Ticket
              </Menu.Item>
            ) : null}
          </>
        ) : null}
      </Menu.Dropdown>
    </Menu>
  )
}

const fetchTickets = async (ticketOwnership) => {
  if (ticketOwnership !== 'all') return await fetchService.get('/auth/tickets/me')
  return await fetchService.get('/auth/tickets')
}

export const TicketsView = () => {
  const navigate = useNavigate()
  const [trackEvent] = useAnalytics()
  const [loaded, setLoaded] = useState(false)
  const [tickets, setTickets] = useState([])
  const [ticketOwnership, setTicketOwnership] = useState('mine')
  const [shareTarget, setShareTarget] = useState(null)
  const { classes, cx } = useStyles()

  const refreshTicketView = useCallback(() => {
    setLoaded(false)

    fetchTickets(ticketOwnership)
      .then(({ status, result }) => {
        const tickets = result?.tickets || []
        setTickets(tickets)
      })
      .catch((err) => {
        console.warn('Unable to fetch tickets', err)
      })
      .finally(() => {
        setLoaded(true)
      })
  }, [ticketOwnership])

  const handleView = (ticket) => {
    trackEvent('ticketing', 'view_ticket', 'handle_view')
    navigate(`/dashboard/ticket/${ticket.id}`)
  }

  const handleEdit = (ticket) => {
    trackEvent('ticketing', 'edit_ticket', 'handle_edit')
    navigate(`/dashboard/ticket/${ticket.id}/edit`)
  }

  const handleApprove = async (ticket) => {
    trackEvent('ticketing', 'approve_ticket', 'handle_approve')
    if (await approveTicket(ticket)) {
      refreshTicketView()
    } else {
      trackEvent('ticketing', 'approve_ticket_failure', 'handle_approve')
    }
  }

  const handleSubmit = async (ticket) => {
    trackEvent('ticketing', 'submit_ticket', 'handle_submit')
    if (await submitTicket(ticket)) {
      refreshTicketView()
    } else {
      trackEvent('ticketing', 'submit_ticket_failure', 'handle_submit')
    }
  }

  const handleVoid = async (ticket) => {
    trackEvent('ticketing', 'void_ticket', 'handle_void')
    if (await voidTicket(ticket)) {
      refreshTicketView()
    } else {
      trackEvent('ticketing', 'void_ticket_failure', 'handle_void')
    }
  }

  const handleAdd = () => {
    trackEvent('ticketing', 'create_ticket', 'create_button')
    navigate(`/dashboard/ticket/create`)
  }

  const handleViewPDF = (ticket) => {
    trackEvent('ticketing', 'view_pdf', 'handle_view_pdf')
    viewTicketPDF(ticket)
  }

  const handleDownloadPDF = (ticket) => {
    trackEvent('ticketing', 'download_pdf', 'handle_download_pdf')
    downloadTicketPDF(ticket)
  }

  const handleShare = (ticket) => {
    trackEvent('ticketing', 'share', 'handle_share_open')
    setShareTarget(ticket)
  }

  const handleCloseShare = () => {
    trackEvent('ticketing', 'share', 'handle_share_close')
    setShareTarget(null)
  }

  useEffect(() => {
    fetchTickets()
      .then(({ status, result }) => {
        const tickets = result?.tickets || []
        setTickets(tickets)
      })
      .catch((err) => {
        console.warn('Unable to fetch tickets', err)
      })
      .finally(() => {
        setLoaded(true)
      })
  }, [])

  useEffect(() => {
    refreshTicketView()
  }, [ticketOwnership, refreshTicketView])

  const TicketFilter = (props) => {
    const handleChange = (value) => {
      if (props.disabled) return
      props.onChange(value)
    }

    return (
      <SegmentedControl
        value={props.value}
        onChange={handleChange}
        data={[
          { label: 'My Tickets', value: 'mine' },
          { label: 'All Tickets', value: 'all' },
        ]}
      />
    )
  }

  const header = (
    <tr>
      <th className={classes.tableHeadCol}>Project</th>
      <th className={classes.tableHeadCol}>Location</th>
      <th className={classes.tableHeadCol}>Status</th>
      <th className={cx(classes.tableHeadCol, classes.tableHeadColIcon)}>
        <Center><IconSettings /></Center>
      </th>
    </tr>
  )

  const tableRows = tickets.map((ticket) => {
    const ActionMenu = (props) => {
      const methods = {
        onViewPDF:      handleViewPDF,
        onDownloadPDF:  handleDownloadPDF,
        onVoid:         handleVoid,
        onEdit:         handleEdit,
        onView:         handleView,
        onApprove:      handleApprove,
        onSubmit:       handleSubmit,
        onShare:        handleShare,
      }

      return (
        <ManageMenu ticket={props.ticket} {...methods}>
          <ActionIcon variant="transparent" color="blue" radius="xl" size="sm" aria-label="Options">
            <IconDotsVertical />
          </ActionIcon>
        </ManageMenu>
      )
    }

    const handleNameClick = () => {
      trackEvent('ticketing', 'view_ticket', 'handle_view')
      navigate(`/dashboard/ticket/${ticket.id}`)
    }

    return (
      <tr key={ticket.meta.id}>
        <td>
          <LimitTextOpen style={{ cursor: 'pointer' }} onClick={handleNameClick}>
            {ticket.meta.name}
          </LimitTextOpen>
        </td>
        <td>{ticket.project.location}</td>
        <td><TicketStatusBadge ticket={ticket} /></td>
        <td><Center><ActionMenu ticket={ticket} /></Center></td>
      </tr>
    )
  })

  const body = !loaded
    ? (<LoadingData />)
    : (
      <ScrollArea mb="xl" h={400}>
        <Table highlightOnHover withColumnBorders withBorder striped>
          <thead className={classes.tableHeader}>{header}</thead>
          <tbody>
            {tableRows}
          </tbody>
        </Table>
      </ScrollArea>
    )

  return (
    <Container p="0">
      <Title order={2} weight={500} mt="xl" mb="lg">Manage Tickets</Title>
      <ShareModal target={shareTarget} onClose={handleCloseShare}  />

      <Box mb="sm" className={classes.tableActionRow}>
        <TicketFilter disabled={!loaded} value={ticketOwnership} onChange={setTicketOwnership} />
        <Button
          onClick={handleAdd}
          leftIcon={<IconPlus size="1.125rem" />}
          pr={12}
        >
          New ticket
        </Button>
      </Box>

      {body}
    </Container>
  );
}
