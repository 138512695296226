import fetchService from "./fetch.service"

export class WeatherService {
  constructor() {}

  /**
   * @typedef RelativeLocation
   * @property {string} city The detected city
   * @property {string} state The detected state
   */

  /**
   * @typedef PointsResponse
   * @property {string} forecast URL to query forecast
   * @property {string} forecastGridData URL to query grid data forecast
   * @property {string} forecastHourly URL to query hourly data forecast
   * @property {string} forecastZone URL to query zone data
   * @property {string} gridId The grid identifier
   * @property {number} gridX The X grid axis
   * @property {number} gridY The Y grid axis
   * @property {RelativeLocation} relativeLocation Relative location properties
   */

  /**
   * Fetch station points for a given LAT/LONG position
   * 
   * @returns {PointsResponse} The response from fetching points
   */
  async getPoints(latitude, longitude) {
    try {
      const response = await fetchService.get(`https://api.weather.gov/points/${latitude},${longitude}`, {
        headers: {
          accept: 'application/ld+json',
        },
        withCredentials: false,
      })

      return response
    } catch(err) {
      console.warn('could not load response', err)
      throw err 
    }
  }

  /**
   * @typedef Dewpoint
   * @property {string} unit Unit code (wmoUnit:degC)
   * @property {number} value Dewpoint value
   */

  /**
   * @typedef RelativeHumidity
   * @property {string} unit Unit code (wmoUnit:percent)
   * @property {number} value Relative humidity value
   */

  /**
   * @typedef PrecipitationProbability
   * @property {string} unit Unit code (wmoUnit:percent)
   * @property {number} value Percent probable to precipitate
   */

  /**
   * @typedef HourlyPeriod
   * @property {Dewpoint} dewpoint The dewpoint during this range
   * @property {RelativeHumidity} relativeHumidity The relative humidity during this range
   * @property {PrecipitationProbability} probabilityOfPrecipitation The % chance of rain during this range
   * @property {string} startTime Hourly range start YYYY-MM-DDTHH:MM:SS
   * @property {string} endTime Hourly range start YYYY-MM-DDTHH:MM:SS
   * @property {string} shortForecast Short description of forecast (i.e., Sunny, Chance Rain Showers)
   * @property {number} temperature Temperature
   * @property {string} temperatureUnit Unit for temperature (i.e., "F")
   * @property {string} windDirection Direction of wind (i.e., "SW")
   * @property {string} windSpeed How fast wind is going (i.e., "5 mph")
   */

  /**
   * @typedef HourlyForecaseResponse
   * @property {HourlyPeriod[]} periods Array of hourly periods for forecast request
   */

  /**
   * Fetch hourly weather details with a provided URL (previously returned from `getPoints` call)
   * 
   * @returns {HourlyForecaseResponse} The response from fetching hourly forecast
   */
  async getHourly(url) {
    try {
      const response = await fetchService.get(url, {
        headers: {
          accept: 'application/ld+json',
        },
        withCredentials: false,
      })

      return response
    } catch(err) {
      console.warn('could not load response', err)
      throw err 
    }
  }

  async getZoneObservations(url) {
    try {
      const response = await fetchService.get(`${url}/observations`, {
        headers: {
          accept: 'application/ld+json',
        },
        withCredentials: false,
      })

      return response
    } catch(err) {
      console.warn('could not load response', err)
      throw err 
    }
  }

  async fetchLocation(lat, long) {
    try {
      const response = await fetchService.get(`/auth/location?point=${lat},${long}`)

      return response
    } catch(err) {
      console.warn('could not load response', err)
      throw err 
    }
  }
}

const weatherService = new WeatherService()
export default weatherService
