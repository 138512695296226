import { Button, Card, Center, Code, Container, Select, SimpleGrid, TextInput, Text, Box } from '@mantine/core'
import { SectionHeader } from './section-header'
import { useForm, zodResolver } from '@mantine/form'
import { useEffect, useMemo, useRef, useState } from 'react'
import { randomId, useScrollIntoView } from '@mantine/hooks'
import mod from '../fleet.module.scss'
import { z } from 'zod'
import { IconX } from '@tabler/icons-react'
import { getInputSectionText } from '../../../common/input-section-text'


const FormInput = (props) => {
  const {
    type,
    setState,
    state,
    name,
    label,
    step,
    rightSection,
    rightSectionWidth,
  } = props

  const extraProps = {
    ...(step ? { step } : {}),
    ...(rightSection ? { rightSection } : {}),
    ...(rightSectionWidth ? { rightSectionWidth } : {}),
  }

  return (
    <TextInput
      name={name}
      label={label}
      type={type}
      value={state}
      onChange={(e) => setState(e.target.value)}
      {...extraProps}
    />
  )
}

const AggregateForm = (props) => {
  const {
    aggregate,
    index = 0,
    key = index,
    form,
    initialValues = null,
  } = props

  const isEven = (index % 2) === 0,
    cardClass = isEven ? mod.formCardEven : ''

  const [moistureFactor, setMoistureFactor] = useState(''),
    [gateSetting, setGateSetting] = useState(''),
    [loaded, setLoaded] = useState(false)

  useEffect(() => {
    if (initialValues && initialValues.aggregates[index]) {
      const { aggregates } = initialValues
      setMoistureFactor(aggregates[index].moistureFactor)
      setGateSetting(aggregates[index].gateSetting)
      setLoaded(true)
    }
  }, [initialValues])

  useEffect(() => {
    if (loaded && moistureFactor !== undefined) {
      form.setFieldValue(`aggregates.${index}.moistureFactor`, moistureFactor)
    }
  }, [moistureFactor])

  useEffect(() => {
    if (loaded && gateSetting !== undefined) {
      form.setFieldValue(`aggregates.${index}.gateSetting`, gateSetting)
    }
  }, [gateSetting])

  return (
    <Card key={key} data-key2={key} className={cardClass}>
      <SimpleGrid
        mb="lg"
        cols={2}
        spacing="md"
        breakpoints={[
          { maxWidth: '20rem', cols: 1, spacing: 'sm' },
          { maxWidth: '72rem', cols: 2, spacing: 'sm' },
        ]}
      >
        <Box>
          <Text size="xs" color="dimmed">Type</Text>
          <Text fw={500} size="sm">{aggregate.type}</Text>
        </Box>

        <Box>
          <Text size="xs" color="dimmed">Name</Text>
          <Text fw={500} size="sm">{aggregate.name}</Text>
        </Box>
      </SimpleGrid>

      <SimpleGrid
        cols={2}
        spacing="md"
        breakpoints={[
          { maxWidth: '52rem', cols: 1, spacing: 'sm' },
          { maxWidth: '72rem', cols: 2, spacing: 'sm' },
        ]}
      >
        {initialValues ? (
          <FormInput
            type="number"
            step=".1"
            name="gateSetting"
            label="Gate Setting"
            state={gateSetting}
            setState={setGateSetting}
          />
        ) : (
          <TextInput
            name={`aggregates.${index}.gateSetting`}
            label="Gate Setting"
            type="number"
            step=".1"
            {...form.getInputProps(`aggregates.${index}.gateSetting`)}
          />
        )}

        {initialValues ? (
          <FormInput
            type="number"
            step=".1"
            label="Cement Count"
            name="moistureFactor"
            state={moistureFactor}
            setState={setMoistureFactor}
            {...getInputSectionText('%')}
          />
        ) : (
          <TextInput
            type="number"
            step="1"
            label="Moisture Correction Factor"
            name={`aggregates.${index}.moistureFactor`}
            {...form.getInputProps(`aggregates.${index}.moistureFactor`)}
            {...getInputSectionText('%')}
          />
        )}
      </SimpleGrid>
    </Card>
  )
}

const AdmixtureForm = (props) => {
  const {
    admixture,
    index = 0,
    key = index,
    form,
    initialValues = null,
  } = props

  const isEven = (index % 2) === 0,
    cardClass = isEven ? mod.formCardEven : ''

  const [calibratedFlow, setCalibratedFlow] = useState(''),
    [loaded, setLoaded] = useState(false)

  useEffect(() => {
    if (initialValues && initialValues.admixtures[index]) {
      const { admixtures } = initialValues
      setCalibratedFlow(admixtures[index].calibratedFlow)
      setLoaded(true)
    }
  }, [initialValues])

  useEffect(() => {
    if (loaded && calibratedFlow !== undefined) {
      form.setFieldValue(`admixtures.${index}.calibratedFlow`, calibratedFlow)
    }
  }, [calibratedFlow])

  return (
    <Card key={key} className={cardClass}>
      <SimpleGrid
        mb="lg"
        cols={2}
        spacing="md"
        breakpoints={[
          { maxWidth: '20rem', cols: 1, spacing: 'sm' },
          { maxWidth: '72rem', cols: 2, spacing: 'sm' },
        ]}
      >
        <Box>
          <Text size="xs" color="dimmed">Name</Text>
          <Text fw={500} size="sm">{admixture.name}</Text>
        </Box>

        <Box>
          <Text size="xs" color="dimmed">Manufacturer Code</Text>
          <Text fw={500} size="sm">{admixture.mfrCode}</Text>
        </Box>
      </SimpleGrid>

      <SimpleGrid
        cols={2}
        spacing="md"
        breakpoints={[
          { maxWidth: '52rem', cols: 1, spacing: 'sm' },
          { maxWidth: '72rem', cols: 2, spacing: 'sm' },
        ]}
      >
        {initialValues ? (
          <FormInput
            type="number"
            name="calibratedFlow"
            label="Calibrated Flow Setting"
            state={calibratedFlow}
            setState={setCalibratedFlow}
          />
        ) : (
          <TextInput
            name={`admixtures.${index}.calibratedFlow`}
            label="Calibrated Flow Setting"
            type="number"
            {...form.getInputProps(`admixtures.${index}.calibratedFlow`)}
          />
        )}
      </SimpleGrid>
    </Card>
  )
}

// const aggregateSchema = z.object({
//   moistureFactor: z.coerce.number(),
//   gateSetting: z.coerce.number().nonnegative({ message: 'Gate Setting should be greater than 0' }),
// })

// const admixtureSchema = z.object({
//   calibratedFlow: z.coerce.number(),
// })

// const schema = z.object({
//   mixDesignId: z.number({ required_error: 'You must select a Mix Design to configure' }),
//   cement: z.object({
//     count: z.coerce.number().nonnegative({ message: 'Cement count should be >= 0' }),
//   }),
//   aggregates: z.array(aggregateSchema),
//   admixtures: z.array(admixtureSchema),
// })

export const ManageModal = (props) => {
  const {
    onSave = () => {},
    onUpdate = () => {},
    classes = {},
    publishedDesigns = [],
    missingConfigs = [],
    initialValues = null,
  } = props

  const form = useForm({
    // validate: zodResolver(schema),
    initialValues: {
      mixDesignId: '',
      cement: {
        count: '',
      },
      aggregates: [], // moisture correction factor (+/-), gate setting
      admixtures: [], // calibrated flow setting
      saved: false,
    },
  })

  const [errors, setErrors] = useState([])
  const { getInputProps } = form
  const disabled = useMemo(() => {
    return form.values.mixDesignId === ''
  }, [form.values.mixDesignId])
  const [showDebug, setDebug] = useState(false)

  const selectedConfig = useMemo(() => {
    return publishedDesigns.find((design) => design.id === form.values.mixDesignId)
  }, [ form.values.mixDesignId ])

  const handleSave = async () => {
    console.log('handle save ... ')
    const _errors = [],
      { values } = form

    if (!values.mixDesignId) {
      _errors.push('You must select a Mid Design to configure')
      setErrors(_errors)
      return
    }

    const ERROR_MESSAGES = {
      required: (label) => `${label} is required`,
      positive: (label) => `${label} should be greater than 0`,
      unexpected: 'An error occurred while saving this configuration, please try again',
    }

    if (values.cement.count === '') {
      _errors.push(ERROR_MESSAGES.required('Cement count'))
    } else if (Number(values.cement.count) <= 0) {
      _errors.push(ERROR_MESSAGES.positive('Cement count'))
    }
    
    values.aggregates.forEach((agg, idx) => {
      if (agg.moistureFactor === '') {
        _errors.push(ERROR_MESSAGES.required(`Aggregate [${selectedConfig.aggregates[idx].name}] Moisture Factor`))
      }

      if (agg.gateSetting === '') {
        _errors.push(ERROR_MESSAGES.required(`Aggregate [${selectedConfig.aggregates[idx].name}] Gate Setting`))
      }
    })

    values.admixtures.forEach((admx, idx) => {
      if (admx.calibratedFlow === '') {
        _errors.push(ERROR_MESSAGES.required(`Admixture [${selectedConfig.admixtures[idx].name}] Calibrated Flow`))
      } else if (Number(admx.calibratedFlow) <= 0) {
        _errors.push(ERROR_MESSAGES.positive(`Admixture [${selectedConfig.admixtures[idx].name}] Calibrated Flow`))
      }
    })

    setErrors(_errors)
    if (_errors.length) {
      console.warn(`Errors found ${_errors.length}`, _errors)
      // scrollIntoView({
      //   alignment: 'center',
      // })

      // if (viewport) {
      //   console.log('has viewport', viewport.current.scrollHeight)
      //   viewport.current.scrollTo({ top: 100, behavior: 'smooth' })

      //   errorRef?.current.focus()
      // }
      // onSave(form.values)
    } else {
      try {
        if (!initialValues) {
          const newConfig = {
            ...form.values,
            meta: {
              name: selectedConfig.meta.name,
              mixDesignNumber: selectedConfig.meta.mixDesignNumber,
            },
          }

          if (!await onSave(newConfig)) throw new Error('Failed saving')
          form.reset()
        } else {
          const { index, ...formValues } = form.values
          if (!await onUpdate(index, formValues)) throw new Error('Failed updating')
          form.reset()
        }
      } catch (err) {
        console.warn('Failed to make changes', err)
        _errors.push(ERROR_MESSAGES.unexpected)
        setErrors(_errors)
      }
    }
  }

  useEffect(() => {
    return () => {
      setErrors([])
      form.reset()
    }
  }, [])

  useEffect(() => {
    if (initialValues) {
      form.setValues(initialValues)
    }
  }, [initialValues])

  useEffect(() => {
    if (!initialValues) {
      console.log('reset aggregates')
      form.setFieldValue('aggregates', [])

      if (selectedConfig && selectedConfig.aggregates.length) {
        selectedConfig.aggregates.forEach((agg) => {
          form.insertListItem('aggregates', {
            uid: agg.uid,
            moistureFactor: '',
            gateSetting: '',
          })
        })
      }
    }
  }, [initialValues, selectedConfig])

  useEffect(() => {
    if (!initialValues) {
      console.log('reset admixtures')
      form.setFieldValue('admixtures', [])

      if (selectedConfig && selectedConfig.admixtures.length) {
        selectedConfig.admixtures.forEach((admx) => {
          form.insertListItem('admixtures', {
            uid: admx.uid,
            calibratedFlow: '',
          })
        })
      }
    }
  }, [initialValues, selectedConfig])

  const aggFormCards = useMemo(() => {
    if (selectedConfig && !selectedConfig?.aggregates.length) {
      return (<Text c="dimmed" pl="sm">No Aggregates found in design spec</Text>)
    }

    return selectedConfig?.aggregates.map((agg, index) => (
      <Box key={index}>
        <AggregateForm aggregate={agg} index={index} form={form} initialValues={initialValues} />
      </Box>
    ))
  }, [ selectedConfig ])

  const admixFormCards = useMemo(() => {
    if (selectedConfig && !selectedConfig?.admixtures.length) {
      return (<Text c="dimmed" pl="sm">No Admixtures found in design spec</Text>)
    }

    return selectedConfig?.admixtures.map((admx, index) => (
      <Box key={index}>
        <AdmixtureForm admixture={admx} index={index} form={form} initialValues={initialValues} />
      </Box>
    ))
  }, [ selectedConfig ])

  return (
    <Container pb="xl">
      {showDebug ? (
        <Code block>
          {JSON.stringify(form.values, null, 2)}
        </Code>
      ) : null}

      {!initialValues ? (
        <Select
          label="Published Mix Design"
          placeholder="Select ..."
          name="configure.design.name"
          data={missingConfigs}
          dropdownPosition="bottom"
          {...getInputProps('mixDesignId')}
        />
      ) : (
        <SimpleGrid
          mb="lg"
          cols={3}
          spacing="md"
          breakpoints={[
            { maxWidth: '52rem', cols: 1, spacing: 'sm' },
            { maxWidth: '72rem', cols: 3, spacing: 'sm' },
            { minWidth: '92rem', cols: 3, spacing: 'md' },
          ]}
        >
          <Box>
            <Text size="sm" color="dimmed">Design Name</Text>
            <Text fw={500} size="md">{initialValues.meta.name || '<custom_name_missing>'}</Text>
          </Box>

          <Box>
            <Text size="sm" color="dimmed">Mix Design Number Name</Text>
            <Text fw={500} size="md">{initialValues.meta.mixDesignNumber || '<mix_design_number_missing>'}</Text>
          </Box>
        </SimpleGrid>
      )}

      <SectionHeader>Cement</SectionHeader>
      <TextInput
        label="Counts per yard"
        name="cement.count"
        disabled={disabled}
        {...getInputProps('cement.count')}
      />

      <SectionHeader>Aggregates</SectionHeader>
      {aggFormCards}

      <SectionHeader>Admixtures</SectionHeader>
      {admixFormCards}

      {errors.length ? (
        <Box mt="lg">
          <Text>Please correct these configuration errors:</Text>
          {errors.map((err) => (
            <Text key={randomId()} component="div" c="red" mt={5} size="sm">
              <Center inline>
                <IconX size="0.9rem" stroke={1.5} />
                <Box ml={7}>{err}</Box>
              </Center>
            </Text>
          ))}
        </Box>
      ) : null}

      <Center mt="xl">
        <Button className={classes.button} radius="sm" size="sm" onClick={handleSave} disabled={disabled}>
          {!initialValues ? 'Save' : 'Update'} Configuration
        </Button>
      </Center>
    </Container>
  )
}
