import React from 'react'
import { Text, ActionIcon, createStyles } from '@mantine/core'
import { IconExternalLink } from '@tabler/icons-react'

const useStyles = createStyles((theme) => ({
  container: {
    display: 'inline-flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  text: {
    marginRight: theme.spacing.xs,
  },
}))

export function LimitText({ text, children, onClick, rightIcon, maxLength = 50, ...textProps }) {
  const { classes } = useStyles()
  const content = text || children
  const isString = typeof content === 'string'
  const isTruncated = isString && content.length > maxLength
  const displayText = isTruncated ? `${content.slice(0, maxLength)}...` : content

  const containerProps = {
    className: classes.container,
    onClick: onClick,
    ...(isTruncated && { title: content }) // Add title attribute if text is truncated
  }

  return (
    <div {...containerProps}>
      <Text className={classes.text} {...textProps}>
        {displayText}
      </Text>
      {rightIcon && <ActionIcon size="sm">{rightIcon}</ActionIcon>}
    </div>
  )
}

export function LimitTextOpen(props) {
  return (
    <LimitText
      {...props}
      rightIcon={<IconExternalLink size={16} />}
    />
  );
}