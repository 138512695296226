import { Title, Container } from '@mantine/core'
import classes from './onboarding-view.module.scss'

export const OnboardingView = () => {

  return (
    <Container className={classes.wrapper}>
      <Title className={classes.title}>eConcretely Demo</Title>

      <Container>
        <div style={{ maxWidth: '600px', margin: '0 auto' }}>
          <div style={{ position: 'relative', paddingBottom: '75%', height: '0' }}>
            <iframe
              style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%' }}
              src="https://www.youtube.com/embed/UjK2PWrRt-Y?si=tvzpMl9Eq7UVfw1V"
              title="eConcretely Demo | YouTube"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </Container>
    </Container>
  );
}
