import { useEffect, useState } from 'react'
import { useForm, zodResolver } from '@mantine/form'
import {
  TextInput,
  Group,
  Button,
  Title,
  SegmentedControl,
  Center,
  rem,
  Code,
  Box,
} from '@mantine/core'
import { z } from 'zod'
import { PerYardText } from './per-yard-text'
import { DynamicField } from './dynamic-field'
import { shortUID } from '../../../utils/short-uid'

const errorMessages = {
  minCharacters: (label = '', minimum = 2) => `${label} should be at least ${minimum} characters. You can use A-Z, 0-9`
}

const schemaAggregate = z.object({
  name: z.string().min(3, { message: errorMessages.minCharacters('Name', 3) }),
  materialCode: z.string().min(3, { message: errorMessages.minCharacters('Material Code', 3) }),
  mfrCode: z.string().min(3, { message: errorMessages.minCharacters('Manufacturer Code', 3) }),
  weight: z.coerce.number().positive({ message: 'Total weight should be greater than 0' }),
})

export const aggregateTypes = [
  { label: 'Sand', value: 'sand' },
  { label: 'Stone', value: 'stone' },
]

export const AggregateForm = (props) => {
  const {
    initialValues = {},
    onSave = null,
    onUpdate = null,
    onCancel = null,
    createMode = false,
    editMode = false,
  } = props

  const form = useForm({
    // validateInputOnBlur: true,
    validate: zodResolver(schemaAggregate),
    initialValues: {
      type: 'sand',
      name: '',
      materialCode: '',
      mfrCode: '',
      weight: '',
      ...initialValues,
    },
  })

  const handleCancel = () => {
    onCancel()
  }

  const handleSave = () => {
    onSave({
      ...form.values,
      uid: shortUID(),
    })
  }

  const handleSubmit = (evt) => {
    evt?.preventDefault?.()

    form.validate()
    if (form.isValid()) {
      handleSave()
      if (!editMode) form.reset()
    }
  }

  const updateField = (name, value) => {
    onUpdate(name, value)
  }

  // can initialize as empty {} will get filled when fields are being edited
  // const [editingFields, setEditingField] = useState({
  //   name: false,
  //   materialCode: false,
  //   mfrCode: false,
  //   weight: false,
  // })

  // const toggleInlineEdit = (field) => {
  //   console.log('toggleInlineEdit', {field, editingFields, val: !editingFields[field]})
  //   if (inline) {
  //     handleSubmit()
  //   }
  //   setEditingField({
  //     ...editingFields,
  //     [field]: !editingFields[field],
  //   })
  // }

  // useEffect(() => {
  //   console.log('AGG EDITING', editingFields)
  // }, [editingFields])

  const initialProps = {
    form,
    inline: !!editMode,
    onUpdate: updateField,
  }

  return (
    <Box pr="3rem">
      <form onSubmit={handleSubmit}>
        <DynamicField
          {...initialProps}
          name="type"
          label="Type"
          type="select"
          data={aggregateTypes}
        />

        <DynamicField
          {...initialProps}
          name="name"
          label="Name"
        />

        <DynamicField
          {...initialProps}
          name="materialCode"
          label="Material Code"
        />

        <DynamicField
          {...initialProps}
          name="mfrCode"
          label="Manufacturer Code"
        />

        <DynamicField
          {...initialProps}
          name="weight"
          label="Total Lbs"
          type="number"
          rightSection={<PerYardText />}
          rightSectionWidth={rem(82)}
        />

        {(createMode) ? (
          <>
            <TextInput label="Moisture Correction Factor" placeholder="This value is set per truck" disabled mt="md" />
            <TextInput label="Gate Setting" placeholder="This value is set per truck" disabled mt="md" />

            <Group mt="lg" position="center" grow="1">
              <Button radius="sm" size="sm" variant="outline" onClick={handleCancel}>
                Cancel
              </Button>

              <Button radius="sm" size="sm" type="submit">
                Save
              </Button>
            </Group>
          </>
        ) : null}
      </form>
    </Box>
  )
}
