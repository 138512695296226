import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Group,
  Box,
  Collapse,
  Text,
  UnstyledButton,
  rem,
} from '@mantine/core'
import {
  IconChevronRight
} from '@tabler/icons-react'
import { randomId } from '@mantine/hooks'
import { navbarStyleIconStroke, useNavbarStyles } from './styles'
import classes from './navbar-link-group.module.scss'

const LinkText = (props) => {
  const {
    link,
    toggleMenu,
    isDesktop,
    redirect,
    basePath,
  } = props
  
  const { link: path, label } = link,
    { classes: navbarStyles } = useNavbarStyles()

  const handleClick = (evt) => {
    evt.preventDefault()
    redirect(`${path}`)

    // collapse menu if mobile
    if (!isDesktop) {
      toggleMenu(false)
    }
  }

  return (
    <Text
      component="a"
      className={`${navbarStyles.link} ${navbarStyles.linkInner} link-group__child-links__child`}
      href={`${basePath}${path}`}
      key={label}
      onClick={handleClick}
    >
      {label}
    </Text>
  )
}

export const LinksGroup = (props) => {
  const {
    basePath,
    icon: Icon,
    label,
    initiallyOpened,
    link,
    links,
    menuOpen,
    toggleMenu = () => {},
    isDesktop = false,
  } = props

  const hasLinks = Array.isArray(links),
    { classes: navbarStyles, cx } = useNavbarStyles(),
    [opened, setOpened] = useState(initiallyOpened || false),
    navigate = useNavigate(),
    handleRedirect = (path) => navigate(`${basePath}${path}`),
    items = (hasLinks ? links : []).map((link) => (
      <LinkText
        key={randomId()}
        link={link}
        toggleMenu={toggleMenu}
        isDesktop={isDesktop}
        basePath={basePath}
        redirect={handleRedirect}
      />
    ))

  const handleNavClick = () => {
    if (!hasLinks && link) {
      handleRedirect(link)
      return
    }

    if (menuOpen) {
      setOpened((o) => !o)
    } else {
      handleRedirect(link)
    }
  }

  useEffect(() => {
    if (!menuOpen) {
      setOpened(false)
    }
  }, [menuOpen])

  return (
    <div className={`link-group${opened ? ' is-active' : ''}`}>
      <UnstyledButton onClick={handleNavClick} className={classes.control}>
        <Group className="link-group__group" justify="space-between" gap={0}>
          <Box className={cx(navbarStyles.linkBox)}>
            <Box className={navbarStyles.navIcon}>
              <Icon size={24} stroke={navbarStyleIconStroke} />
            </Box>
            <Box ml="md" className={`${navbarStyles.link} link-group__label`}>{label}</Box>
          </Box>
          {hasLinks && (
            <IconChevronRight
              className={classes.chevron}
              stroke={1.5}
              style={{
                width: rem(16),
                height: rem(16),
                transform: opened ? 'rotate(-90deg)' : 'none',
              }}
            />
          )}
        </Group>
      </UnstyledButton>
      {hasLinks ? <Collapse className="link-group__child-links" in={opened}>{items}</Collapse> : null}
    </div>
  )
}

export const NavbarLinksGroup = () => {
  return (
    <Box mih={220} p="md">
      <LinksGroup />
    </Box>
  )
}
