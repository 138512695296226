import { Text, rem } from '@mantine/core'

export const InputRightLabel = (props) => {
  const { label = 'label' } = props

  return (
    <Text size="sm" color="dimmed">{label}</Text>
  )
}

export const getRightLabelProps = (props) => {
  const { label = 'label', width = 80 } = props

  return {
    rightSection: (<InputRightLabel label={label} />),
    rightSectionWidth: rem(width),
  }
}
