import { useEffect } from "react";
import { useRouteError } from "react-router-dom";

export default function NotFoundErrorView() {
  const error = useRouteError()

  useEffect(() => {
    if (error) {
      console.error(error)
    }
  }, [error])
  
  if (!error) {
    return (
      <div id="error-page">
        <h1>Oops!</h1>
        <p>Page not found</p>
      </div>
    )
  }
  
  return (
    <div id="error-page">
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>{error?.statusText || error?.message || 'Some error'}</i>
      </p>
    </div>
  )
}
