import {
  Paper,
  createStyles,
  TextInput,
  PasswordInput,
  Checkbox,
  Button,
  Title,
  Text,
  Anchor,
  rem,
  MediaQuery,
  Center,
  Container,
  Stepper,
  Group,
  Box,
  SimpleGrid,
  Stack,
  Code,
  Select,
  Flex,
  Loader,
} from '@mantine/core'
import { useEffect, useState } from 'react'
import fingerprintService, { DEVICE_FINGERPRINT } from '../../services/fingerprint.service'

// import { useEffect, useRef, useState } from 'react'
import { Link, redirect, useNavigate } from 'react-router-dom'
import browserStorageService from '../../services/browser-storage.service';
import fetchService from '../../services/fetch.service';
import highway from '../../static/highway.jpg'
import { IconAlertCircle, IconCircleCheck, IconMailCheck, IconShieldCheck, IconUserCheck } from '@tabler/icons-react';
import { useForm, zodResolver } from '@mantine/form';
import states from '../../utils/states';
import { z } from 'zod';
import { PricingPage } from '../../common/stripe-pricing-table';
import { ProductTable } from '../../common/product-table';
// import Cookies from 'js-cookie'
// import SidebarRoutes from '../routes'
// import browserStorageService from '../../services/browser-storage.service'
// import fetchService from '../../services/fetch.service'
// import fingerprintService, { DEVICE_FINGERPRINT } from '../../services/fingerprint.service'
const MAX_AUTH_LENGTH = 12
const MIN_AUTH_LENGTH = 6
const useStyles = createStyles((theme) => ({
  wrapper: {
    // minHeight: rem(900),
    backgroundSize: 'cover',
    backgroundImage: `url(${highway})`, //'url(../static/highway.jpg)',
    maxWidth: 'initial',
    minHeight: '100vh',

    // [theme.fn.smallerThan('sm')]: {
    //   // backgroundColor: theme.colors.yellow[6],
    //   height: '100%',
    // },
  },

  formDesktop: {
    backgroundColor: theme.fn.rgba('#1d1224', 0.9),
    borderRight: `${rem(1)} solid #363636`,
    // borderRight: `${rem(1)} solid ${
    //   theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[3]
    // }`,
    // minHeight: rem(900),
    height: '100vh',
    maxWidth: rem(450),
    paddingTop: rem(80),
    color: '#f8f9f7',
    
    // [theme.fn.smallerThan('sm')]: {
    //   maxWidth: '100%',
    // },
  },

  wrapperMobile: {
    display: 'flex',
    height: '100%',
    backgroundSize: 'cover',
    backgroundImage: `url(${highway})`, //'url(../static/highway.jpg)',
  },

  formMobile: {
    backgroundColor: theme.fn.rgba('#1d1224', 0.9), //'#1d1224',
    border: `${rem(1)} solid ${theme.fn.darken('#f9af42', 0.5)}`,
    color: '#f8f9f7',
  },

  form: {
    width: rem(700),
    paddingTop: rem(80),
    backgroundColor: theme.fn.rgba('#1d1224', 0.9), //'#1d1224',
    border: `${rem(1)} solid ${theme.fn.darken('#f9af42', 0.5)}`,
    color: '#f8f9f7',


    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      maxWidth: "80%",
    },

    // Simplify media query writing with theme functions
    [theme.fn.smallerThan('sm')]: {
      // backgroundColor: theme.colors.yellow[6],
      padding: rem(10),
    },
  },

  title: {
    // color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    color: '#f8f9f7',
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },

  textInput: {
    color: '#f8f9f7',

    label: {
      color: '#f8f9f7',
    },

    input: {
      '::placeholder': {
        color: theme.colors.gray[6],
      },
    },
  },

  link: {
    color: '#f9af42',
    position: 'relative',

    '&:hover': {
      textDecoration: 'none',
    },
  },

  stepLabel: {
    color: theme.colors.gray[0],
  },

  stepDescription: {
    color: theme.colors.gray[5],
    lineHeight: '1.2rem'
  },

  separator: {
    color: 'red',
    backgroundColor: theme.colors.gray[5],
  },

  separatorActive: {
    backgroundColor: theme.colors.yellow[8],
  },

  stepIcon: {
    color: theme.colors.yellow[8],

    '&[data-progress]': {
      borderColor: theme.colors.yellow[8],
    },

    '&[data-completed]': {
      backgroundColor: theme.colors.yellow[8],
      borderColor: theme.colors.yellow[8],
    },
  },

  stepCompletedIcon: {
    color: theme.colors.gray[0],
  },

  stepWrapper: {
  },
  
  stepRoot: {
    [theme.fn.smallerThan('xs')]: {
      display: 'flex',
    },
  },

  stepSteps: {
    [theme.fn.smallerThan('xs')]: {
      marginRight: theme.spacing.sm,
    },
  },

  errorWrap: {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    padding: theme.spacing.sm,
    marginTop: theme.spacing.md,
    borderLeft: `3px solid ${theme.colors.red[5]}`
  },

  hideStepper: {
    display: 'none',
  },
}));

const usStates = [
  { label: 'Illinois', value: 'IL ' },
  { label: '-------', value: ' ' },
  ...states.map(({ name, abbreviation }) => ({
    label: name,
    value: abbreviation,
  })),
]

const personalSchema = z.object({
  name: z.string().min(3, { message: 'Your name should be more than 3 characters' }),
  email: z.string().email({ message: 'Your email does not look right' }),
  authCode: z.string().min(MIN_AUTH_LENGTH, { message: `Passcode should be at least ${MIN_AUTH_LENGTH} digits` }).max(12, { message: `Passcode should be less than ${MAX_AUTH_LENGTH} digits` }),
  phone: z.string().min(10, { message: 'Your phone number does not look right' }),
})

const businessSchema = z.object({
  name: z.string(),
  phone: z.string(),
  address: z.string(),
  city: z.string().min(1, { message: 'You must provide us the city for your business' }),
  state: z.string().trim().length(2, { message: 'You must select the state for your business' }),
  zipcode: z.string().min(1, { message: 'You must provide the zipcode for your business' }),
})

const stripeKey = process.env.NODE_ENV === 'development'
  ? 'pk_test_51NxwRrEFi0j9GNnTiewA5pRLGOOGroctJufePlWLiwLhyMeUXwMeMBAprNcsndWg8e5cRwq8eZ1ncK5x8mmVu8V400lIeM27tv'
  : ''

const stripeTableId = process.env.NODE_ENV === 'development'
  ? 'prctbl_1NyRMPEFi0j9GNnTyVmbZu0x'
  : ''

export const RegisterView = () => {
  const { classes, cx } = useStyles(),
    navigate = useNavigate()

  const [active, setActive] = useState(0)
  const [fingerprint, setFingerprint] = useState('')
  const [submitting, setSubmitting] = useState(false)
  const [completed, setCompleted] = useState(false)
  const [error, setError] = useState('')
  const [showLogin, setShowLogin] = useState(false)
  const [showSupport, setShowSupport] = useState(false)
  const [showDashboard, setShowDashboard] = useState(false)
  const [products, setProducts] = useState([])

  const isNumericInput = (event) => {
    const key = event.keyCode
    return ((key >= 48 && key <= 57) || // Allow number line
        (key >= 96 && key <= 105) // Allow number pad
    )
  }

  const isModifierKey = (event) => {
    const key = event.keyCode
    return (event.shiftKey === true || key === 35 || key === 36) || // Allow Shift, Home, End
      (key === 8 || key === 9 || key === 13 || key === 46) || // Allow Backspace, Tab, Enter, Delete
      (key > 36 && key < 41) || // Allow left, up, right, down
      (
        // Allow Ctrl/Command + A,C,V,X,Z
        (event.ctrlKey === true || event.metaKey === true) &&
        (key === 65 || key === 67 || key === 86 || key === 88 || key === 90)
      )
  }

  const enforceFormat = (event) => {
    const isNumeric = isNumericInput(event),
      isModifier = isModifierKey(event)

    // Input must be of a valid number format or a modifier key, and not longer than ten digits
    if (!isNumeric && !isModifier) event.preventDefault()
  }

  const formatPhone = (value) => {
    const input = value.replace(/\D/g, '').substring(0, 10), // First ten digits of input only
      areaCode = input.substring(0, 3),
      middle = input.substring(3, 6),
      last = input.substring(6, 10)

    if (input.length > 6) value = `(${areaCode}) ${middle} ${last}`
    else if (input.length > 3) value = `(${areaCode}) ${middle}`
    else if (input.length > 0) value = `(${areaCode}`
    return value
  }

  const personal = useForm({
    // validateInputOnBlur: true,
    // validate: zodResolver(schema),
    initialValues: {
      name: '',
      email: '',
      authCode: '',
      phone: '',
      acceptTos: false,
      acceptSms: false,
    },

    validate: zodResolver(personalSchema.required()),

    transformValues: (values) => ({
      formattedPhone: formatPhone(values.phone),
    }),
  })

  const business = useForm({
    initialValues: {
      name: '',
      phone: '',
      address: '',
      city: '',
      state: '',
      zipcode: '',
      country: '',
    },

    validate: zodResolver(businessSchema.required()),

    transformValues: (values) => ({
      formattedPhone: formatPhone(values.phone),
    }),
  })

  const onPhoneInput = (event) => {
    const { target: { value = '', name = '' } = {} } = event || {}
    if (name === 'personal.phone') {
      personal.setFieldValue('phone', value.replace(/\D/g, '').substring(0, 10))
    } else if (name === 'business.phone') {
      business.setFieldValue('phone', value.replace(/\D/g, '').substring(0, 10))
    }
  }

  const onAuthInput = (event) => {
    const { target: { value = '' } = {} } = event || {}
    if (!/^[0-9]+$/.test(value) && value !== '') return
    if (value.length > MAX_AUTH_LENGTH) return

    personal.setFieldValue('authCode', value)
  }

  const onZipcodeInput = (event = {}) => {
    const { target: { value = '' } = {} } = event
    business.setFieldValue('zipcode', value.replace(/\D/g, '').substring(0, 5))
  }

  const nextStep = async () => {
    try {
      if (active === 0) {
        personal.validate()
        if (!personal.isValid()) throw new Error('personal error')
        handleSaveContact(personal.values)
      } else if (active === 1) {
        business.validate()
        if (!business.isValid()) throw new Error('business error')
        await handleRegistration(personal.values, business.values)
        await handleLogin(personal.values)
      }

      setActive((current) => (current < 3 ? current + 1 : current))
    } catch (err) { }
  }

  const prevStep = () => {
    if (error) setError('')
    if (showLogin) setShowLogin(false)
    if (showSupport) setShowSupport(false)

    setActive((current) => (current > 0 ? current - 1 : current))
  }

  const handleServerError = (serverError) => {
    const genericErrorMessage = 'An unexpected error occurred and we were unable to process your registration. Please try again. If the issue persists please reach out to us.'

    if (!serverError.response ||
      !serverError.response.data ||
      !serverError.response.data.error) {
      setError(genericErrorMessage)
      setShowSupport(true)
    }

    const error = serverError.response.data.error
    if ([400, 422].includes(error.code) && error.message) setError(error.message)
    if (error.code === 409 && error.message) {
      setShowLogin(true)
      setError(error.message)
    }
    if (error.code === 500 && error.message) {
      setShowSupport(true)
      setError(`${genericErrorMessage} [${error.message}]`)
    }
  }

  const handleSaveContact = async (values) => {
    const contact = {
      name: values.name,
      phone: values.phone,
      email: values.email,
    }

    try {
      await fetchService.post('/public/contact', {
        body: { contact }
      })
    } catch (err) {
      console.warn('Unable to store contact details')
    }
  }

  const handleRegistration = async (personalProfile, businessProfile) => {
    setSubmitting(true)
    setError('')

    try {
      const response = await fetchService.post('/public/register', {
        body: { personalProfile, businessProfile }
      })

      if (!response.result.complete) {
        throw new Error('Registration response was invalid')
      }
    } catch (err) {
      setSubmitting(false)
      handleServerError(err)
      throw err && err.response || err
    }
  }

  const handleLogin = async ({ email, authCode }) => {
    try {
      const response = await fetchService.post('/public/login', {
        body: { email, authCode, fingerprint }
      })

      const userId = response.user.user_id || response.user.userId
      if (!userId) {
        throw new Error('Login response was invalid')
      }
      
      if (!browserStorageService.initialized) {
        browserStorageService.initialize(userId)
      }

      await browserStorageService.setItem('role', response.user.role)
      await browserStorageService.setItem('session', response.user)

      setSubmitting(false)
      setCompleted(true)
    } catch (err) {
      setSubmitting(false)
      handleServerError(err)
      throw err && err.response || err
    }
  }

  useEffect(() => {
    const loadFingerprint = async () => {
      return await fingerprintService.getFingerprint()
    }

    loadFingerprint()
      .then(setFingerprint)
      .catch((err) => {
        console.warn('Unable to load fingerprint', err)
      })
  }, [])

  useEffect(() => {
    const loadProducts = async () => {
      const { result } = await fetchService.get('/public/products')
      console.log(result.products)
      return result.products
    }

    loadProducts()
      .then(setProducts)
      .catch((err) => {
        console.warn('Unable to load products', err)
      })
  }, [])

  const stepperClasses = {
    root: classes.stepRoot,
    steps: classes.stepSteps,
    stepLabel: classes.stepLabel,
    stepDescription: classes.stepDescription,
    separator: classes.separator,
    separatorActive: classes.separatorActive,
    stepIcon: classes.stepIcon,
    stepCompletedIcon: classes.stepCompletedIcon,
    stepWrapper: classes.stepWrapper,
  }

  const tosAccepted = personal.values?.acceptTos || false

  return (
    <Container className={classes.wrapper}>
      <Center id="center" mx="auto">
        <Paper className={classes.form} radius={0} p={30} mt="lg" mb="lg">
          <Title order={2} className={classes.title} ta="center" mb={50}>
            { !completed ? 'Create eConcretely Account' : 'Registration complete!' }
          </Title>

          <Stepper breakpoint="xs" className={cx({ ...stepperClasses, [classes.hideStepper]: completed === true })} classNames={stepperClasses} active={active} completedIcon={<IconCircleCheck size="1.5rem" />} size="md">
            <Stepper.Step icon={<IconUserCheck size="1.5rem" />} label="Step 1" description="Create an account">
              <SimpleGrid
                cols={2}
                mt="lg"
                spacing="md"
                breakpoints={[
                  { maxWidth: '30rem', cols: 1, spacing: 'sm' },
                  { maxWidth: '72rem', cols: 2, spacing: 'sm' },
                ]}
              >
                <TextInput
                  className={classes.textInput}
                  label="Full name"
                  name="personal.name"
                  placeholder="John Smith"
                  size="sm"
                  withAsterisk
                  {...personal.getInputProps('name')}
                />

                <TextInput
                  className={classes.textInput}
                  label="Phone number"
                  type="tel"
                  name="personal.phone"
                  placeholder="3125555555"
                  size="sm"
                  withAsterisk
                  {...personal.getInputProps('phone')}
                  value={personal.getTransformedValues().formattedPhone}
                  onKeyDown={enforceFormat}
                  onChange={onPhoneInput}
                />

                <TextInput
                  className={classes.textInput}
                  label="Email address"
                  name="personal.email"
                  placeholder="jsmith@acme.com"
                  type="email"
                  size="sm"
                  withAsterisk
                  {...personal.getInputProps('email')}
                />

                <Stack>
                  <PasswordInput
                    className={classes.textInput}
                    label="Passcode"
                    name="code"
                    placeholder="Login Passcode (123456)"
                    size="sm"
                    withAsterisk
                    {...personal.getInputProps('authCode')}
                    onChange={onAuthInput}
                  />
                  {!personal.errors.phone ? (
                    <Text mt={rem(-10)} pl={rem(5)} pr={rem(5)} fz="xs" c="dimmed" ta="justify" fw={500}>
                      Numbers only. {MIN_AUTH_LENGTH}-{MAX_AUTH_LENGTH} digits for logging in.
                    </Text>
                  ) : null}
                </Stack>
              </SimpleGrid>

              <Checkbox
                mt="lg"
                mb="md"
                {...personal.getInputProps('acceptSms')}
                checked={personal.values.acceptSms}
                label={
                  <Text color="white" component="span">
                    I opt-in to receive SMS messages from eConcretely for identity authentication purposes and transaction based events. Message and data rates may apply. Opt out anytime.
                  </Text>
                }
              />

              <Checkbox
                {...personal.getInputProps('acceptTos')}
                checked={personal.values.acceptTos}
                label={
                  <Text color="white" component="span">
                    I acknowledge I have read and accept both the{' '}
                    <Anchor href="/terms-of-service" target="_blank">
                      Terms of Service
                    </Anchor>
                    {' '} and{' '}
                    <Anchor href="/privacy-policy" target="_blank">
                      Privacy Policy
                    </Anchor>
                    {' '}for eConcretely. <Text color="red" component="span">*required</Text>
                  </Text>
                }
              />
            </Stepper.Step>

            <Stepper.Step icon={<IconMailCheck size="1.5rem" />} label="Step 2" description="Business profile">
              <SimpleGrid
                cols={2}
                mt="lg"
                spacing="md"
                breakpoints={[
                  { maxWidth: '30rem', cols: 1, spacing: 'sm' },
                  { maxWidth: '72rem', cols: 2, spacing: 'sm' },
                ]}
              >
                <TextInput
                  className={classes.textInput}
                  label="Business Name"
                  name="business.name"
                  placeholder="Acme Mixing"
                  size="sm"
                  {...business.getInputProps('name')}
                />

                <TextInput
                  className={classes.textInput}
                  label="Business Phone"
                  type="tel"
                  name="business.phone"
                  placeholder="3125555555"
                  size="sm"
                  {...business.getInputProps('phone')}
                  value={business.getTransformedValues().formattedPhone}
                  onKeyDown={enforceFormat}
                  onChange={onPhoneInput}
                />

                <TextInput
                  className={classes.textInput}
                  label="Address"
                  name="business.address"
                  placeholder="123 E. Main St"
                  size="sm"
                  {...business.getInputProps('address')}
                />

                <TextInput
                  className={classes.textInput}
                  label="City"
                  name="business.city"
                  size="sm"
                  withAsterisk
                  {...business.getInputProps('city')}
                />
                
                <Select
                  className={classes.textInput}
                  label="State"
                  placeholder="Select state"
                  data={usStates}
                  withAsterisk
                  {...business.getInputProps('state')}
                />

                <TextInput
                  className={classes.textInput}
                  label="Zipcode"
                  name="business.zipcode"
                  size="sm"
                  withAsterisk
                  type="number"
                  maxLength={5}
                  {...business.getInputProps('zipcode')}
                  onKeyDown={enforceFormat}
                  onChange={onZipcodeInput}
                />
              </SimpleGrid>
            </Stepper.Step>

            <Stepper.Step icon={<IconShieldCheck size="1.5rem" />} label="Step 3" description="Setup payment" />
          </Stepper>

          {error ? (
            <Box className={classes.errorWrap}>
              <Flex mb="md">
                <IconAlertCircle size="1.5rem" color="red" />
                <Text ml="md" color="red">{error}</Text>
              </Flex>
              {showLogin ? (
                <>
                  You can try&nbsp;
                  <Link className={classes.link} to="/">
                    <Text component="span">logging in</Text>
                  </Link>
                  &nbsp;instead
                </>
              ) : null}
              {showSupport ? (
                <>
                  For help reach out to&nbsp;
                  <Link className={classes.link} to="mailto:support@econcrete.ly">
                    <Text component="span">support@econcrete.ly</Text>
                  </Link>
                </>
              ) : null}
              {showDashboard ? (
                <>
                  Access your &nbsp;
                  <Link className={classes.link} to="/dashboard">
                    <Text component="span">dashboard</Text>
                  </Link>
                </>
              ) : null}
            </Box>
          ) : null}

          {!completed ? (
            <Group position="center" mt="xl" mb="md">
              <Button
                variant="default"
                onClick={prevStep}
                disabled={active === 0 || submitting || completed}
              >
                  Back
              </Button>
              <Button
                onClick={nextStep}
                disabled={submitting || completed || !tosAccepted}
              >
                {submitting ? (
                  <Loader color="blue" size="sm" />
                ) : 'Next step'}
              </Button>
            </Group>
          ) : (
            <Box mt={error ? 'lg' : ''}>
              <Text mb="sm">
                Your personal and business profiles have been stored with eConcretely.
              </Text>

              <Text mb="sm">
                In order to begin onboarding and unlock the full suite of features eConcretely Ticketing has to offer, you'll need to setup payment information with our third party payment-processing partner Stripe.
              </Text>

              <Text mb="lg">
                Below you'll find the available subscriptions for our service, each allows you a free 30 day trial.
              </Text>

              <ProductTable
                products={products}
                setErrors={setError}
                showDashboardLink={setShowDashboard}
                user={personal.values}
              />
            </Box>
          )}
        </Paper>
      </Center>
    </Container>
  );
}
