
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useForm, zodResolver } from '@mantine/form'
import { notifications } from '@mantine/notifications'
import {
  CloseButton,
  Container,
  Select,
  SimpleGrid,
  TextInput,
  Text,
  Title,
  Code,
  Stack,
  Tooltip,
  rem,
  ActionIcon,
  Button,
  createStyles,
  Center,
  NumberInput,
  Card,
  Modal,
  ScrollArea,
  Menu,
  Flex,
  Box,
  ThemeIcon,
} from '@mantine/core'
import { randomId, useDisclosure } from '@mantine/hooks'
import { z } from 'zod'
// import { DynamicFormList } from './form/dynamic-form-list'
// import { AggregateForm } from './form/aggregate-form'
// import { AdmixtureForm } from './form/admixture-form'
// import { getRightLabelProps } from './form/input-right-label'
// import './index.scss'
// import mockValues from './mock.json'
import fetchService from '../../services/fetch.service'
import { StatusCodes } from 'http-status-codes'
import { IconCheck, IconDotsVertical, IconExclamationCircle, IconPencil, IconPlus, IconTrash } from '@tabler/icons-react'
import { useLoaderData, useNavigate, useParams } from 'react-router-dom'
import { ActionLinkToFleet } from './shared/action-link-to-fleet'
import viewClasses from './fleet.module.scss'
import { SectionHeader } from './shared/section-header'
import { ManageModal } from './shared/manage-modal'

const useStyles = createStyles((theme) => ({
  button: {
    borderRadius: theme.radius.sm,
    padding: `${theme.spacing.sm} ${theme.spacing.lg}`,
    cursor: 'pointer',
    height: rem('3.25rem'),
    maxWidth: rem('13rem'),
  },
}));

const errorMessages = {
  minCharacters: (label = '', minimum = 2) =>
    `${label} should be at least ${minimum} characters. You can use A-Z, 0-9`
}

const mixMeta = z.object({
  name: z.string().min(2, { message: errorMessages.minCharacters('Name', 2) }),
})

// const mixCement = z.object({
//   type: z.string().min(1, { message: 'You must select a cement type or choose "Other"' }),
//   customType: z.string(),
//   name: z.string().min(3, { message: errorMessages.minCharacters('Cement Name', 3) }),
//   materialCode: z.string().min(3, { message: errorMessages.minCharacters('Cement Material Code', 3) }),
//   mfrCode: z.string().min(3, { message: errorMessages.minCharacters('Cement Manufacturer Code', 3) }),
//   weight: z.number().positive({ message: 'Cement weight should be greater than 0' }),
//   bags: z.number().positive({ message: 'Total cement bags should be greater than 0' }),
// })

// const mixWater = z.object({
//   total: z.coerce.number().nonnegative({ message: 'Total gallons of water should be >= 0' }),
//   ratio: z.coerce.number().nonnegative({ message: 'Water:Cement ratio should be >= 0 and is usually a fraction (less than 1)'}),
// }) 

// const mixSpecialty = z.object({
//   name: z.string().min(3, { message: errorMessages.minCharacters('Specialty Name', 3) }),
//   materialCode: z.string().min(3, { message: errorMessages.minCharacters('Specialty Material Code', 3) }),
//   mfrCode: z.string().min(3, { message: errorMessages.minCharacters('Specialty Manufacturer Code', 3) }),
//   total: z.coerce.number().nonnegative({ message: 'Total per yard should be greater than 0' }),
// })

const schema = z.object({
  meta: mixMeta.required(),
  // cement: mixCement.required({
  //   type: true,
  //   name: true,
  //   materialCode: true,
  //   mfrCode: true,
  //   weight: true,
  //   bags: true,
  // }),
  // water: mixWater.required(),
  // specialty: mixSpecialty.required(),
})

/**
 *  
 */

export const transpile = (importedSpec = null) => {
  if (!importedSpec || importedSpec === undefined) return {}

  const {
    name,
    truckId,
    designs = [],
    configurations = [],
  } = importedSpec

  return {
    meta: {
      name: name || '',
      id: truckId || 0,
    },
    designs: configurations.map((config) => {
      const matchingDesign = designs.find((d) => d.id === config.refs.design)

      return {
        mixDesignId: Number(config.refs.design),
        meta: {
          name: matchingDesign?.meta.name || '',
          mixDesignNumber: matchingDesign?.meta.mixDesignNumber || '',
        },
        cement: {
          count: config?.cement?.count || '',
        },
        aggregates: config.aggregates.map((agg) => ({
          moistureFactor: agg?.moistureFactor || '',
          gateSetting: agg?.gateSetting || '',
          uid: agg?.uid || '',
        })),
        admixtures: config.admixtures.map((admx) => ({
          calibratedFlow: admx?.calibratedFlow || '',
          uid: admx?.uid || '',
        })),
      }
    }),
  }
}

const ConfiguredDesigns = (props) => {
  const {
    designs = [],
    onDelete = () => {},
    onEdit = () => {},
    onSave = () => {},
  } = props

  const handleDelete = (index) => {
    onDelete(index)
  }

  const handleEdit = (index) => {
    onEdit(index)
  }

  const handleSave = (index) => {
    onSave(index)
  }

  return designs.map((design, index) => {
    const hasName = !!design?.meta.name,
      label = hasName ? 'Design Name' : 'Mix Design Number',
      value = hasName ? design.meta.name : design.meta?.mixDesignNumber || 'missing_design_number'

    return (
      <Card className={viewClasses.manageCard} key={randomId()} withBorder p="xs">
        <Flex justify="flex-start" align="center">
          <Box pr="xs" mr="auto">
            <Text size="xs" color="dimmed">{label}</Text>
            <Text style={{ cursor: 'pointer' }} fw={500} size="sm" onClick={() => handleEdit(index)}>{value}</Text>
          </Box>
          <ManageMenu design={design} onDelete={() => handleDelete(index)} onEdit={() => handleEdit(index)}>
            <ActionIcon className={viewClasses.mangageMenuBtn} variant="transparent" color="blue" radius="xl" size="sm" aria-label="Options">
              <IconDotsVertical />
            </ActionIcon>
          </ManageMenu>
        </Flex>
      </Card>
    )
  })
}

const ManageMenu = (props) => {
  const {
    children,
    design = {},
    onDelete,
    onEdit,
  } = props

  const iconSize = 16

  return (
    <Menu shadow="md" width={200}>
      <Menu.Target>{children}</Menu.Target>
      <Menu.Dropdown>
        <Menu.Label>{design?.meta?.mixDesignNumber || '<MIX>'}</Menu.Label>
        <Menu.Item
          onClick={() => onEdit(design)}
          icon={<IconPencil size={iconSize}/>}
        >
          Edit Config
        </Menu.Item>
        <Menu.Item
          color="red"
          onClick={() => onDelete(design)}
          icon={<IconTrash size={iconSize} />}
        >
          Delete Config
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  )
}

/**
 * TODO - Autosave draft
 * @returns 
 */

export const ManageTruckView = () => {
  const importedTruck = useLoaderData()
  const { classes } = useStyles()
  const navigate = useNavigate()

  const truckForm = useForm({
    // validateInputOnBlur: true,
    // validate: zodResolver(schema),
    initialValues: {
      meta: {
        name: '',
      },
      designs: [],
    },
  })

  const [opened, { open, close }] = useDisclosure(false),
    [publishedDesigns, setPublishedDesigns] = useState([])

  const { getInputProps } = truckForm,
    [showDebug, setDebug] = useState(false),
    [hasValidTruck, setValidTruck] = useState(false),
    [submitting, setSubmitting] = useState(false),
    [savedConfigs, setSavedConfigs] = useState([]),
    [configIndex, setConfigIndex] = useState(-1),
    [loadedConfig, setLoadedConfig] = useState(null),
    [loadedTruck, setLoadedTruck] = useState(null),
    numericRegex = /^([\d.-]+)$/i,
    notificationId = {
      upload: 'fleet-manage-truck-upload',
    },
    params = useParams(),
    isEditMode = useMemo(() => {
      if (loadedTruck) return true
      return (importedTruck !== null && importedTruck !== undefined) && !!params.truckId
    }, [importedTruck, params, loadedTruck])

  useEffect(() => {
    if (importedTruck) {
      const truck = transpile(importedTruck)
      truckForm.setValues(truck)
      setValidTruck(true)
      setLoadedTruck(truck)
    }
  }, [importedTruck])

  const validateTruck = () => {
    truckForm.validate()
    return truckForm.isValid()
  }

  const deleteTruckSpec = async (spec) => {
    const { values: truckSpec } = truckForm
    const truckName = truckSpec?.meta?.name || '<TRUCK_NAME>'
    let truck = loadedTruck || {}

    try {
      if (!hasValidTruck) {
        truck = await uploadTruck(null, false)
        setValidTruck(true)
      }

      const deleteUrl = `/auth/truck/${truck?.meta?.id || params.truckId}/${spec.mixDesignId}`
      const response = await fetchService.delete(deleteUrl)

      if (response.status === StatusCodes.OK) {
        notifications.update({
          id: notificationId.upload,
          color: 'teal',
          title: `${truckName} updated`,
          icon: <IconCheck style={{ width: rem(18), height: rem(18) }} />,
          loading: false,
        })

        return true
      }

      throw new Error(`Failed to delete truck config ${response.status}`)
    } catch (err) {
      console.warn('Failed to delete truck config', err)

      notifications.update({
        id: notificationId.upload,
        color: 'red',
        title: 'Unable to delete truck config',
        message: `We ran into difficulty updating your Truck "${truckName}" configurations. Please try again, if the issue persists you can contact support.`,
        icon: <IconExclamationCircle style={{ width: rem(18), height: rem(18) }} />,
        loading: false,
        autoClose: false,
      })
    } finally {
      setSubmitting(false)
    }
  }

  const updateTruckSpec = async (spec) => {
    const { values: truckSpec } = truckForm
    const truckName = truckSpec?.meta?.name || '<TRUCK_NAME>'
    let truck = loadedTruck || {}

    try {
      if (!hasValidTruck) {
        truck = await uploadTruck(null, false)
        setValidTruck(true)
      }
      
      const patchUrl = `/auth/truck/${truck?.meta?.id || params.truckId}/${spec.mixDesignId}`
      const response = await fetchService.patch(patchUrl, { body: { spec } })

      if (response.status === StatusCodes.OK) {
        notifications.update({
          id: notificationId.upload,
          color: 'teal',
          title: `${truckName} updated`,
          icon: <IconCheck style={{ width: rem(18), height: rem(18) }} />,
          loading: false,
        })

        close()
        setLoadedConfig(null)
        return true
      }

      throw new Error(`Failed to update truck ${response.status}`)
    } catch (err) {
      console.warn('Failed to update truck', err)

      notifications.update({
        id: notificationId.upload,
        color: 'red',
        title: 'Unable to update truck',
        message: `We ran into difficulty updating your Truck "${truckName}" configurations. Please try again, if the issue persists you can contact support.`,
        icon: <IconExclamationCircle style={{ width: rem(18), height: rem(18) }} />,
        loading: false,
        autoClose: false,
      })

      return false
    } finally {
      setSubmitting(false)
    }
  }

  const uploadTruck = async (updatedSpec = null, shouldRedirect = true) => {
    // const truckSpec = !!updatedSpe
    const { values: truckSpec } = truckForm
    let truck = loadedTruck || {}

    try {
      const body = { truck: truckSpec }
      const dynamicRequest = isEditMode
        ? fetchService.patch(`/auth/truck/${truck?.meta?.id || params.truckId}`, { body })
        : fetchService.post('/auth/truck', { body })
      const response = await dynamicRequest

      if (response.status === StatusCodes.OK) {
        const {
          result: {
            added = [],
            deleted = [],
            updated = [],
            errors = [],
            truck = {},
          },
        } = response

        if (!loadedTruck) {
          setLoadedTruck(transpile(truck))
        }

        const truckName = truck?.name || truckSpec?.name || '<TRUCK_NAME>',
          title = isEditMode ? 'Truck Updated!' : 'Truck Created!',
          action = isEditMode ? 'updated' : 'created',
          pluralize = (word, count) => count === 1 ? word : `${word}s`
        
        let message = `Your truck  "${truckName}" was ${action}.`
        if (added.length) {
          message = `${message} We saved ${added.length} new ${pluralize('configuration', added.length)}.`
        } else if (!isEditMode && !added.length) {
          message = `${message} There were no configurations to apply, but you can always configure more in the future through Fleet Management.`
        }

        if (updated.length) {
          message = `${message} We updated ${updated.length} ${pluralize('configuration', updated.length)}.`
        }

        if (deleted.length) {
          message = `${message} We removed ${deleted.length} ${pluralize('configuration', deleted.length)}.`
        }

        if (errors.length) {
          message = `${message} But we did have trouble applying changes for: [${errors.map(({ design: d }) => d.mixDesignNumber).join(', ')}] — You can try configuring them again by managing your truck.`
        }

        notifications.update({
          id: notificationId.upload,
          color: 'teal',
          title,
          message,
          icon: <IconCheck style={{ width: rem(18), height: rem(18) }} />,
          loading: false,
        })

        // if (!isEditMode && shouldRedirect) {
        //   navigate('/dashboard/fleet')
        // }

        return transpile(truck)
      }
    } catch (err) {
      console.warn('Failed to upload truck', err)
      const truckName = truckSpec?.name || '<TRUCK_NAME>',
          title = isEditMode ? 'Unable to update truck' : 'Unable to save truck',
          message = isEditMode
            ? `We ran into difficulty updating your Truck "${truckName}" and configurations. Please try again, if the issue persists you can contact support.`
            : `We ran into difficulty saving your Truck "${truckName}" and configurations. Please try again, if the issue persists you can contact support.`

      setSubmitting(false)
      notifications.update({
        id: notificationId.upload,
        color: 'red',
        title,
        message,
        icon: <IconExclamationCircle style={{ width: rem(18), height: rem(18) }} />,
        loading: false,
        autoClose: false,
      })

      return {}
    } finally {
      setSubmitting(false)
    }
  }

  const saveTruck = (updatedSpec = null) => {
    if (submitting) return

    setSubmitting(true)
    const valid = validateTruck()
    if (!valid) {
      notifications.show({
        id: notificationId.upload,
        title: 'Unable to save Truck Details',
        message: 'Please correct any identified errors.',
        autoClose: false,
      })

      console.warn('Unable to submit form', truckForm.errors)
      return
    }

    if (updatedSpec) {
      notifications.show({
        id: notificationId.upload,
        loading: true,
        title: 'Updating Truck Settings',
        autoClose: false,
        withCloseButton: false,
      })
  
      updateTruckSpec(updatedSpec)
      return
    }

    notifications.show({
      id: notificationId.upload,
      loading: true,
      title: 'Saving Truck',
      autoClose: false,
      withCloseButton: false,
    })

    uploadTruck()
  }

  useEffect(() => {
    const fetchDesigns = async () => {
      return await fetchService.get('/auth/query?model=designs&status=published')
    }

    fetchDesigns()
      .then(({ result }) => {
        const designs = result?.designs || []
        setPublishedDesigns(designs)
      })
      .catch((err) => {
        console.warn('Unable to fetch mix designs', err)
      })

    return () => {
      console.log('RESET MANAGE FORM')
      truckForm.reset()
    }
  }, [])

  const mixDesignSelections = useMemo(() => {
    const configuredDesignIds = truckForm.values.designs.map((design) => design.mixDesignId)

    return publishedDesigns
      .filter((design) => {
        return !configuredDesignIds.includes(design.id)
      })
      .map((design) => ({
        label: design.meta.name
          ? `${design.meta.name} (Mix #: ${design.meta.mixDesignNumber})`
          : design.meta.mixDesignNumber,
        value: design.id,
        group: 'Available to configure',
      }))
  }, [truckForm.values.designs, publishedDesigns])

  const handleAddConfig = () => {
    open()
  }

  const handleCloseConfig = () => {
    setLoadedConfig(null)
    close()
  }

  const handleSaveConfig = async (config) => {
    if (submitting) return
    setSubmitting(true)

    notifications.show({
      id: notificationId.upload,
      loading: true,
      title: 'Adding Truck Configuration',
      autoClose: false,
      withCloseButton: false,
    })

    if (await updateTruckSpec(config)) {
      truckForm.insertListItem('designs', config)
      return true
    }

    return false
  }

  // TODO can be combined with handleSaveConfig
  const handleUpdateConfig = async (index, config) => {
    if (submitting) return
    setSubmitting(true)

    notifications.show({
      id: notificationId.upload,
      loading: true,
      title: 'Updating Truck Configuration',
      autoClose: false,
      withCloseButton: false,
    })

    if (await updateTruckSpec(config)) {
      truckForm.setFieldValue(`designs.${index}`, config)
      return true
    }

    return false
  }

  const handleDeleteConfig = async (index) => {
    if (submitting) return
    setSubmitting(true)

    notifications.show({
      id: notificationId.upload,
      loading: true,
      title: 'Deleting Truck Configuration',
      autoClose: false,
      withCloseButton: false,
    })

    console.log('index', index)
    const spec = truckForm.values.designs[index]
    console.log('spec', spec)
    if (await deleteTruckSpec(spec)) {
      truckForm.removeListItem('designs', index)
    }
    return
  }

  const handleEditConfig = (index) => {
    setLoadedConfig({
      index,
      ...truckForm.values.designs[index],
    })
  }

  useEffect(() => {
    if (loadedConfig !== null) open()
  }, [loadedConfig])

  return (
    <Container my="md">
      <Modal classNames={{ content: viewClasses.modalContent }} opened={opened} onClose={handleCloseConfig} title="Configure Mix Design" size="lg" pb="xl">
        <ManageModal
          onSave={handleSaveConfig}
          onUpdate={handleUpdateConfig}
          publishedDesigns={publishedDesigns}
          missingConfigs={mixDesignSelections}
          classes={classes}
          initialValues={loadedConfig}
        />
      </Modal>

      <Title order={2} weight={500} mt="xl" mb="lg">{isEditMode ? 'Edit' : 'Create'} Truck</Title>

      {isEditMode ? <ActionLinkToFleet /> : null}

      <Card withBorder padding="lg" radius="md">
        {showDebug ? (
          <Code block>
            {JSON.stringify(truckForm.values, null, 2)}
          </Code>
        ) : null}

        <SectionHeader>Truck Details</SectionHeader>
        <TextInput
          label="Name"
          name="meta.name"
          {...getInputProps('meta.name')}
        />

        <SectionHeader>Design Configurations</SectionHeader>
        {!mixDesignSelections.length ? (
          <Flex align="center" mb="lg">
            <ThemeIcon color="red" variant="" size="md">
              <IconExclamationCircle size="20" />
            </ThemeIcon>
            <Text size="sm" ml="sm" color="red">Publish Additional Mix Designs To Configure</Text>
          </Flex>
        ) : null}

        <SimpleGrid
          cols={3}
          spacing="md"
          breakpoints={[
            { maxWidth: '52rem', cols: 1, spacing: 'sm' },
            { maxWidth: '72rem', cols: 3, spacing: 'sm' },
            { minWidth: '92rem', cols: 3, spacing: 'md' },
          ]}
        >
          <ConfiguredDesigns
            designs={truckForm.values.designs}
            onSave={saveTruck}
            onEdit={handleEditConfig}
            onDelete={handleDeleteConfig}
          />

          {mixDesignSelections.length ? (
            <Card
              withBorder
              component="button"
              className={viewClasses.createCardBtn}
              onClick={handleAddConfig}
            >
              <div className={viewClasses.createCard}>
                <IconPlus size="1.125rem" />
                <Text size="md" ml="sm">Select Design</Text>
              </div>
            </Card>
          ) : null}
        </SimpleGrid>
        

        <Center mt="xl">
          <Button className={classes.button} radius="sm" size="sm" onClick={() => saveTruck()}>
            {isEditMode ? 'Update' : 'Save'} Truck
          </Button>
        </Center>
      </Card>
    </Container>
  );
}
