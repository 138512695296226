import { createStyles, getStylesRef } from '@mantine/core';

export const navbarStyleIconStroke = 1.5
export const useNavbarStyles = createStyles((theme) => ({
  link: {
    ...theme.fn.focusStyles(),
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    fontSize: theme.fontSizes.sm,
    color: '#eae6db',
    paddingLeft: theme.spacing.sm,
    fontWeight: 500,
    marginLeft: '0 !important',
  },

  linkInner: {
    marginLeft: `${theme.spacing.xl} !important`,
    display: 'block',
    padding: `${theme.spacing.xs} ${theme.spacing.md}`,
    paddingLeft: theme.spacing.md,
    color: '#eae6db',
    position: 'relative',
    transition: 'background 250ms ease-out',
    background: 'rgb(255,129,0)',
    background: 'linear-gradient(-90deg, rgba(255,129,0,0.30) 0%, rgba(255,129,0,0) 90%)',
    backgroundSize: '100% 90px',
    backgroundRepeat: 'repeat-y',
    backgroundPosition: '275px 0',

    '&:hover': {
      backgroundPosition: '30px 0',

      '&::after': {
        width: '2px',
      },
    },

    '&::after': {
      content: '""',
      height: '100%',
      width: '0',
      backgroundColor: 'red',
      position: 'absolute',
      top: '0',
      right: '0',
      transition: 'width 150ms ease',
    }
  },

  navIcon: {
    color:' #ffffff',
  },

  linkBox: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },

  linkBoxHover: {
    padding: `${theme.spacing.xs} ${theme.spacing.md}`,

    '&:hover': {
      backgroundColor: theme.fn.lighten(
        '#1d1224',
        // theme.fn.variant({ variant: 'filled', color: theme.primaryColor }).background,
        0.1
      ),
    },
  },

  linkIcon: {
    ref: getStylesRef('icon'),
    color: theme.white,
    opacity: 0.75,
    marginRight: theme.spacing.sm,
  },

  linkActive: {
    '&, &:hover': {
      backgroundColor: theme.fn.lighten(
        theme.fn.variant({ variant: 'filled', color: theme.primaryColor }).background,
        0.15
      ),
      [`& .${getStylesRef('icon')}`]: {
        opacity: 0.9,
      },
    },
  },
}));
