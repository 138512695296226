import { useState } from 'react'
import { useForm, zodResolver } from '@mantine/form'
import {
  TextInput,
  Group,
  Button,
  rem,
  Container,
  Code,
  Box,
} from '@mantine/core'
import { z } from 'zod'
import { DynamicField } from './dynamic-field'
import { shortUID } from '../../../utils/short-uid'

const errorMessages = {
  minCharacters: (label = '', minimum = 2) => `${label} should be at least ${minimum} characters. You can use A-Z, 0-9`
}

const schemaAggregate = z.object({
  name: z.string().min(3, { message: errorMessages.minCharacters('Name', 3) }),
  materialCode: z.string().min(3, { message: errorMessages.minCharacters('Material Code', 3) }),
  mfrCode: z.string().min(3, { message: errorMessages.minCharacters('Manufacturer Code', 3) }),
  dosageRate: z.coerce.number().positive({ message: 'Dosage Rate should be greater than 0' }),
})

export const AdmixtureForm = (props) => {
  const {
    initialValues = {},
    inlineEdit = false,
    onSave = null,
    onUpdate = null,
    onCancel = null,
    createMode = false,
    editMode = false,
  } = props

  const form = useForm({
    // validateInputOnBlur: true,
    validate: zodResolver(schemaAggregate),
    initialValues: {
      name: '',
      materialCode: '',
      mfrCode: '',
      dosageRate: '',
      ...initialValues,
    },
  })

  const handleCancel = () => {
    onCancel()
  }

  const handleSave = () => {
    onSave({
      ...form.values,
      uid: shortUID(),
    })
  }

  const handleSubmit = (evt) => {
    evt.preventDefault()

    form.validate()
    if (form.isValid()) {
      handleSave()
      if (!editMode) form.reset()
    }
  }

  const updateField = (name, value) => {
    onUpdate(name, value)
  }

  // can initialize as empty {} will get filled when fields are being edited
  // const [editingFields, setEditingField] = useState({})

  // const toggleInlineEdit = (field) => {
  //   setEditingField({
  //     ...editingFields,
  //     [field]: !editingFields[field],
  //   })
  // }

  const initialProps = {
    form,
    inline: !!editMode,
    onUpdate: updateField,
  }

  return (
    <Box pr="3rem">
      <form onSubmit={handleSubmit}>
        <DynamicField
          {...initialProps}
          name="name"
          label="Name"
        />

        <DynamicField
          {...initialProps}
          name="materialCode"
          label="Material Code"
        />

        <DynamicField
          {...initialProps}
          name="mfrCode"
          label="Manufacturer Code"
        />

        <DynamicField
          {...initialProps}
          name="dosageRate"
          label="Dosage Rate"
          type="number"
          placeholder="1.42"
        />

        {createMode ? (
          <>
            <TextInput label="Calibrated Flow Setting" placeholder="This value is set per truck" disabled mt="md" />

            <Group mt="lg" position="center" grow="1">
              <Button radius="sm" size="sm" variant="outline" onClick={handleCancel}>
                Cancel
              </Button>

              <Button radius="sm" size="sm" type="submit">
                Save
              </Button>
            </Group>
          </>
        ) : null}
      </form>
    </Box>
  )
}
