import random from 'random-string-alphanumeric-generator'

export const generateTicketId = (companyName = '') => {
  const randomAlpha = () => random.randomAlphanumeric(5, 'uppercase'),
    uid = `${randomAlpha()}-${randomAlpha()}`

  return companyName
    ? `${companyName.substring(0, 3)}-${uid}`
    : uid
}
