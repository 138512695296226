import {
  Modal,
} from '@mantine/core'
import { noop } from '../../../utils/noop'
import { NewClientForm } from '../../tickets/forms/new-client-form'

export const AddClientModal = (props) => {
  const {
    opened = false,
    onSuccess = noop,
    onCancel = noop,
  } = props

  const handleClose = () => {
    onCancel()
  }

  const handleCancel = () => {
    handleClose()
  }

  const handleSuccess = (newClient) => {
    onSuccess(newClient)
  }

  return (
    <Modal opened={opened} onClose={handleClose} title="Add New Client">
      <NewClientForm
        saveLabel="Save client"
        onSuccess={handleSuccess}
        onCancel={handleCancel}
      />
    </Modal>
  )
}
