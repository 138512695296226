import { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Container, Paper, createStyles, Center, Text } from '@mantine/core'

const useStyles = createStyles((theme) => ({
  wrapper: {
    backgroundColor: '#eae6db',
    maxWidth: 'initial',
    minHeight: '100vh',
  },

  link: {
    // color: '#1d1224',
    position: 'relative',

    '&:hover': {
      textDecoration: 'none',
    },
  },
}))

export const LogoutView = () => {
  const { classes } = useStyles(),
    navigate = useNavigate(),
    [countdown, setCountdown] = useState(4),
    latestTimerId = useRef(null)

  const countdownOne = () => {
    latestTimerId.current = setTimeout(() => {
      if (countdown > 0) {
        setCountdown(countdown - 1)
        countdownOne()
      } else {
        navigate('/')
      }
    }, 1000)
  }

  useEffect(() => {
    countdownOne()
    return () => {
      if (latestTimerId.current) {
        clearTimeout(latestTimerId.current)
      }
    }
  })

  return (
    <Container className={classes.wrapper}>
      <Center id="center" mx="auto" data-aos="fade-up">
        <Paper className={classes.form} radius={0} p={30} mt="lg" mb="lg">
          {countdown > 0 ? (
            <Text>Logging you out in {countdown}...</Text>
          ) : (
            <Text>Redirecting ...</Text>
          )}
          <Text>If you are not redirected in a few seconds, <Link to="/">click here</Link>.</Text>
        </Paper>
      </Center>
    </Container>
  )
}
