import { ActionIcon, Flex, Title } from '@mantine/core'
import { IconArrowNarrowLeft } from '@tabler/icons-react'
import { useNavigate } from 'react-router-dom'

export const ActionLinkToFleet = () => {
  const navigate = useNavigate(),
    handleViewAll = () => navigate(`/dashboard/fleet`)

  return (
    <Flex align="center" mb="xl">
      <ActionIcon size="lg" color="blue" mr="xs" onClick={handleViewAll}>
        <IconArrowNarrowLeft size={24} />
      </ActionIcon>
      <Title order={4} weight={300}>View all trucks</Title>
    </Flex>
  )
}
