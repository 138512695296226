
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { z } from 'zod'
import { useForm, zodResolver } from '@mantine/form'
import {
  Container,
  SimpleGrid,
  TextInput,
  Title,
  createStyles,
  Center,
  Card,
  Checkbox,
  Text,
  Loader,
} from '@mantine/core'

import { FormHeader } from '../../common/form-header'
import { ErrorSection } from '../../common/error-section'
import { SubmitButton } from '../../common/submit-button'
import browserStorageService, { STORAGE_KEY } from '../../services/browser-storage.service'
import { MAX_AUTH_LENGTH, MIN_AUTH_LENGTH } from '../users/helper'
import { updateAccount, updateAccountAuth, updatePreference } from './actions'

const useStyles = createStyles((theme) => ({
  card: {
    overflow: 'inherit',
  },
  textInput: {
    input: {
      '::placeholder': {
        color: theme.colors.gray[6],
      },
    },
  },
}))

/**
 *  
 */

export const transpile = (session = null) => {
  if (!session || session === undefined) return {}

  const {
    name,
    email,
    phone,
  } = session

  return {
    name,
    email,
    phone,
  }
}

const personalSchema = z.object({
  name: z.string({
    required_error: 'You cannot have an empty name',
    invalid_type_error: 'This name does not look right',
  }).min(3, { message: 'Your name should be more than 3 characters' }),
  email: z.string({
    required_error: 'You must provide an email for yourself',
    invalid_type_error: 'This email does not look right',
  }).email({ message: 'Your email does not look right' }),
  phone: z.string({
    required_error: 'You must provide a phone number for yourself',
    invalid_type_error: 'This phone number does not look right',
  }).min(10, { message: 'Your phone number does not look right' }),
})

const authSchema = z.object({
  current: z.string()
    .min(MIN_AUTH_LENGTH, { message: `Your current auth code should be at least ${MIN_AUTH_LENGTH} digits` })
    .max(MAX_AUTH_LENGTH, { message: `Your current auth code should be less than ${MAX_AUTH_LENGTH} digits` }),
  new: z.object({
    auth: z.string()
      .min(MIN_AUTH_LENGTH, { message: `New Auth code should be at least ${MIN_AUTH_LENGTH} digits` })
      .max(MAX_AUTH_LENGTH, { message: `New Auth code should be less than ${MAX_AUTH_LENGTH} digits` }),
    auth_confirm: z.string()
      .min(MIN_AUTH_LENGTH, { message: `New Auth code should be at least ${MIN_AUTH_LENGTH} digits` })
      .max(MAX_AUTH_LENGTH, { message: `New Auth code should be less than ${MAX_AUTH_LENGTH} digits` }),
  }).superRefine((values, ctx) => {
    const { auth, auth_confirm } = values
    if (auth !== auth_confirm) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'New auth and confirmation should match',
        // fatal: true,
        path: ['auth_confirm'],
      })

      return z.NEVER
    }
  })
})

export const ManageAccountView = () => {
  const { classes, cx } = useStyles(),
    navigate = useNavigate(),
    [preferences, setPreferences] = useState({}),
    [updatingPreferences, setUpdatingPreferences] = useState(false)

  const isNumericInput = (event) => {
    const key = event.keyCode
    return ((key >= 48 && key <= 57) || // Allow number line
        (key >= 96 && key <= 105) // Allow number pad
    )
  }

  const isModifierKey = (event) => {
    const key = event.keyCode
    return (event.shiftKey === true || key === 35 || key === 36) || // Allow Shift, Home, End
      (key === 8 || key === 9 || key === 13 || key === 46) || // Allow Backspace, Tab, Enter, Delete
      (key > 36 && key < 41) || // Allow left, up, right, down
      (
        // Allow Ctrl/Command + A,C,V,X,Z
        (event.ctrlKey === true || event.metaKey === true) &&
        (key === 65 || key === 67 || key === 86 || key === 88 || key === 90)
      )
  }

  const enforceFormat = (event) => {
    const isNumeric = isNumericInput(event),
      isModifier = isModifierKey(event)

    // Input must be of a valid number format or a modifier key, and not longer than ten digits
    if (!isNumeric && !isModifier) event.preventDefault()
  }

  const formatPhone = (value) => {
    if (!value || !value.length) return value
    const input = value.replace(/\D/g, '').substring(0, 10), // First ten digits of input only
      areaCode = input.substring(0, 3),
      middle = input.substring(3, 6),
      last = input.substring(6, 10)

    if (input.length > 6) value = `(${areaCode}) ${middle} ${last}`
    else if (input.length > 3) value = `(${areaCode}) ${middle}`
    else if (input.length > 0) value = `(${areaCode}`
    return value
  }

  const personal = useForm({
    initialValues: {
      name: '',
      email: '',
      authCode: '',
      phone: '',
    },

    validate: zodResolver(personalSchema.required()),

    transformValues: (values) => ({
      formattedPhone: formatPhone(values.phone),
    }),
  })

  const auth = useForm({
    initialValues: {
      current: '',
      new: {
        auth: '',
        auth_confirm: '',
      },
    },

    validate: zodResolver(authSchema.required()),
  })

  const onPhoneInput = (event) => {
    const { target: { value = '', name = '' } = {} } = event || {}
    if (name === 'personal.phone') {
      personal.setFieldValue('phone', value.replace(/\D/g, '').substring(0, 10))
    }
  }

  const { getInputProps: getFormProps } = personal,
    [user, setUser] = useState(null),
    [submitting, setSubmitting] = useState(false),
    [submittingAuth, setSubmittingAuth] = useState(false),
    [error, setError] = useState(null),
    [errorAuth, setErrorAuth] = useState(null)

  // load session user
  useEffect(() => {
    if (browserStorageService.sessionStarted) {
      browserStorageService.getItem(STORAGE_KEY.SESSION)
        .then((session) => {
          setUser(session)
          const sessionValues = transpile(session)
          if (sessionValues.phone && sessionValues.phone.includes('+1')) {
            sessionValues['phone'] = sessionValues.phone.substring(2)
          }

          personal.setValues(sessionValues)
        })
    }
  }, [browserStorageService.sessionStarted])

  useEffect(() => {
    browserStorageService.getItem(STORAGE_KEY.SETTINGS)
      .then((settings = {}) => {
        const { preferences = {} } = settings
        console.log(preferences)
        setPreferences(preferences)
      })
  }, [setPreferences, browserStorageService.lastUpdated])

  const validatePersonal = () => {
    personal.validate()
    return personal.isValid()
  }

  /**
   * Save account
   */
  const submitAccount = async () => {
    if (submitting) return

    if (!validatePersonal()) {
      setError('There was some invalid information found, please correct')
      console.warn('Unable to submit form', {
        errors: {
          personal: personal.errors,
        },
      })
      return
    }

    setError('')
    setSubmitting(true)

    if (await updateAccount(user, personal.values)) {
      navigate('.', { replace: true })
    } else {
      setErrorAuth('Failed updating your personal information at this time')
    }
    
    setSubmitting(false)
  }

  const validateAuth = () => {
    auth.validate()
    return auth.isValid()
  }

  /**
   * Save auth
   */
  const submitAuthCode = async () => {
    if (submittingAuth) return

    if (!validateAuth()) {
      setErrorAuth('There was some invalid information found, please correct and try again')
      console.warn('Unable to submit form', {
        errors: {
          auth: auth.errors,
        },
      })
      return
    }

    setErrorAuth('')
    setSubmittingAuth(true)

    if (await updateAccountAuth(user, { auth: auth.values })) {
      navigate('.', { replace: true })
      auth.reset()
    } else {
      setErrorAuth('Failed updating your login auth code at this time')
    }
    
    setSubmittingAuth(false)
  }

  const togglePreference = (preferenceId) => () => {
    setUpdatingPreferences(true)
    
    updatePreference(user, preferenceId)
    .finally(() => {
      setUpdatingPreferences(false)
    })
  }

  return (
    <Container my="md">
      <Title order={2} weight={500} mt="xl" mb="lg">
        Account Settings
      </Title>

      {/** Personal Details */}
      <Card withBorder padding="lg" radius="md" mb="lg">
        <FormHeader>Personal Profile</FormHeader>

        <SimpleGrid
          cols={2}
          mt="lg"
          mb="xl"
          spacing="md"
          breakpoints={[
            { maxWidth: '30rem', cols: 1, spacing: 'sm' },
            { maxWidth: '72rem', cols: 2, spacing: 'sm' },
          ]}
        >
          <TextInput
            className={classes.textInput}
            label="Full name"
            name="personal.name"
            placeholder="John Smith"
            size="sm"
            withAsterisk
            {...personal.getInputProps('name')}
          />

          <TextInput
            className={classes.textInput}
            label="Phone number"
            type="tel"
            name="personal.phone"
            placeholder="3125555555"
            size="sm"
            withAsterisk
            {...personal.getInputProps('phone')}
            value={personal.getTransformedValues().formattedPhone}
            onKeyDown={enforceFormat}
            onChange={onPhoneInput}
          />

          <TextInput
            className={classes.textInput}
            description="Contact support to edit your email address"
            label="Email address"
            name="personal.email"
            placeholder="jsmith@acme.com"
            type="email"
            size="sm"
            withAsterisk
            disabled
            {...personal.getInputProps('email')}
          />
        </SimpleGrid>

        <Checkbox
          mt="lg"
          mb="md"
          checked={preferences['NOTIFICATION_SMS']?.value}
          disabled={updatingPreferences}
          onChange={togglePreference('NOTIFICATION_SMS')}
          label={
            <div style={{ display: 'flex', gap: '0.2rem', alignItems: 'center' }}>
              {updatingPreferences ? (<Loader size="xs" color="blue" />) : null}
              <Text component="span">
                Enable SMS messages from eConcretely
              </Text>
            </div>
          }
        />

        {error ? (
          <ErrorSection message={error} />
        ) : null}

        <Center>
          <SubmitButton
            onClick={submitAccount}
            text="Update Account"
            submitting={submitting}
          />
        </Center>
      </Card>

      {/** Auth Code */}
      <Card withBorder padding="lg" radius="md" mb="lg">
        <FormHeader>Login Auth Code</FormHeader>

        <SimpleGrid
          cols={2}
          mt="lg"
          mb="sm"
          spacing="md"
          breakpoints={[
            { maxWidth: '30rem', cols: 1, spacing: 'sm' },
            { maxWidth: '72rem', cols: 2, spacing: 'sm' },
          ]}
        >
          <TextInput
            className={classes.textInput}
            label="Current Auth Code"
            name="auth.current"
            placeholder="123123"
            size="sm"
            withAsterisk
            {...auth.getInputProps('current')}
          />
        </SimpleGrid>

        <SimpleGrid
          cols={2}
          mb="xl"
          spacing="md"
          breakpoints={[
            { maxWidth: '30rem', cols: 1, spacing: 'sm' },
            { maxWidth: '72rem', cols: 2, spacing: 'sm' },
          ]}
        >
          <TextInput
            className={classes.textInput}
            label="New Auth Code"
            name="auth.new.auth"
            placeholder="456456"
            size="sm"
            withAsterisk
            {...auth.getInputProps('new.auth')}
          />

          <TextInput
            className={classes.textInput}
            label="Confirm New Auth Code"
            name="auth.new.auth_confirm"
            placeholder="456456"
            size="sm"
            withAsterisk
            {...auth.getInputProps('new.auth_confirm')}
          />
        </SimpleGrid>

        {errorAuth ? (
          <ErrorSection message={errorAuth} />
        ) : null}

        <Center>
          <SubmitButton
            onClick={submitAuthCode}
            text="Update Auth Code"
            submitting={submittingAuth}
          />
        </Center>
      </Card>
    </Container>
  );
}
