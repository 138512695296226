import { useMemo } from 'react'
import { ThemeIcon, Text, Title, Container, SimpleGrid, rem, Box } from '@mantine/core'
import {
  IconNotebook,
  IconUsers,
  IconFileDescription,
  IconTruck,
  IconDeviceMobile,
  IconUserShare,
} from '@tabler/icons-react'
import classes from './features.module.scss'


export function Feature({ icon: Icon, title, description, index }) {
  return (
    <Box className={classes.feature} key={index} data-aos="fade-up" data-aos-anchor="[data-aos-id-blocks]" data-aos-delay={100 * index}>
      <ThemeIcon variant="light" size={60} radius={40} color="blue">
        <Icon style={{ width: rem(30), height: rem(30) }} stroke={1} />
      </ThemeIcon>
      <Text fz="lg" fw={500} mt="sm" mb={7}>
        {title}
      </Text>
      <Text className={classes.featureDescription} size="sm" lh={1.6} maw={300} >
        {description}
      </Text>
    </Box>
  )
}

export const Features = () => {
  const featureList = [
    {
      icon: IconFileDescription,
      title: 'e-Tickets',
      description:
        'Streamline concrete operations with e-tickets. Effortlessly record, store, and share essential details, ensuring compliance and transparency in every pour.',
    },
    {
      icon: IconUsers,
      title: 'User Collaboration',
      description:
        'Collaborate confidently with team, enhancing communication and coordination between on-site and off-site teams. Security and control ensure smooth teamwork.',
    },
    {
      icon:  IconNotebook,
      title: 'Mix Design Mastery',
      description:
        'Customize mix designs effortlessly with a compliant interface. Support for specialty mixes and secure storage for your designs ensure precision in every formulation.',
    },
    {
      icon: IconTruck,
      title: 'Truck Management',
      description:
        'Manage and configure volumetric cement trucks efficiently. Track materials, maintenance, and configurations, ensuring optimal performance and adaptability for diverse projects.',
    },
    {
      icon: IconDeviceMobile,
      title: 'Mobile-First Technology',
      description:
        'Experience the convenience of a mobile-first platform with offline capabilities. Integration with cloud storage and compatibility with modern browsers ensure flexibility and accessibility.',
    },
    {
      icon: IconUserShare,
      title: 'Internal CRM',
      description:
        'Enhance customer interactions with an integrated CRM. Streamlined communication, data-driven decision-making, and personalized service delivery empower businesses to build lasting client connections.',
    },
  ]

  const features = useMemo(() => {
    return featureList.map((feature, index) => (
      <div key={index}>
        <Feature {...feature} index={index} />
      </div>
    ))
  }, [featureList]) 

  return (
    <Container className={classes.wrapper}>
      <Title className={classes.title}>Empower Your Concrete Operations with eConcretely</Title>

      <Container size={560} p={0}>
        <Text size="md" className={classes.description}>
          Discover the Cutting-Edge Tools Transforming Concrete Management: Unlock Efficiency, Precision, and Collaboration with eConcretely's Dynamic Features.
        </Text>
      </Container>

      <SimpleGrid
        mt={60}
        ml="sm"
        mr="sm"
        cols="3"
        spacing="lg"
        breakpoints={[
          { maxWidth: '36rem', cols: 1, spacing: 'sm' },
          { maxWidth: '72rem', cols: 2, spacing: 'sm' },
          { minWidth: '92rem', cols: 3, spacing: 'md' },
        ]}
        data-aos-id-blocks
      >
        {features}
      </SimpleGrid>
    </Container>
  );
}
