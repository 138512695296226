
import { useEffect, useMemo, useState } from 'react'
import { notifications } from '@mantine/notifications'
import {
  Container,
  SimpleGrid,
  Text,
  Title,
  rem,
  ActionIcon,
  createStyles,
  Card,
  Badge,
  Flex,
  Menu,
} from '@mantine/core'
import { randomId, useHover } from '@mantine/hooks'
import {
  IconDotsVertical,
  IconTrash,
  IconPencil,
  IconX,
  IconCheck,
  IconPlus,
} from '@tabler/icons-react'
import fetchService from '../../services/fetch.service'
import { useNavigate } from 'react-router-dom'
import viewClasses from './fleet.module.scss'
import { LoadingData } from '../../common/loading-data'

const useStyles = createStyles((theme) => ({
  card: {
    overflow: 'inherit',
  },
  button: {
    borderRadius: theme.radius.sm,
    padding: `${theme.spacing.sm} ${theme.spacing.lg}`,
    cursor: 'pointer',
    height: rem('3.25rem'),
    maxWidth: rem('13rem'),
  },
}));

const ManageMenu = (props) => {
  const {
    children,
    truck = {},
    onDelete,
    onEdit,
  } = props

  const iconSize = 16

  return (
    <Menu shadow="md" width={200}>
      <Menu.Target>{children}</Menu.Target>
      <Menu.Dropdown>
        <Menu.Label>{truck?.name || '<MISSING_TRUCK_NAME>'}</Menu.Label>
        {/* <Menu.Item
          onClick={() => onView(truck)}
          icon={<IconExternalLink size={iconSize} />}
        >
          View Truck
        </Menu.Item> */}
        <Menu.Item
          onClick={() => onEdit(truck)}
          icon={<IconPencil size={iconSize}/>}
        >
          Edit Truck
        </Menu.Item>
        <Menu.Item
          color="red"
          onClick={() => onDelete(truck)}
          icon={<IconTrash size={iconSize} />}
        >
          Delete Truck
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  )
}

const CreateCard = (props) => {
  const { onClick } = props
  const { hovered, ref } = useHover()

  return (
    <Card
      withBorder
      component="button"
      onClick={onClick}
      shadow={hovered ? 'sm' : 'xs'}
      ref={ref}
      className={viewClasses.createCardBtn}
    >
      <div className={viewClasses.createCard}>
        <IconPlus size="1.125rem" />
        <Text size="md" ml="sm">Add Truck</Text>
      </div>
    </Card>
  )
}

const TruckConfigurationBadges = (props) => {
  const {
    truck = {},
  } = props

  const matchingDesignName = (config = {}) => {
    const matchedDesign = truck?.designs.find((design) => design.id === config?.refs?.design)
    return matchedDesign
      ? matchedDesign?.meta?.name || matchedDesign?.meta?.mixDesignNumber || '<MIX_DESIGN_NUMBER>'
      : '<MISSING_MIX_DESIGN_NUMBER>'
  }

  return (
    <>
      {truck?.configurations.map((config) => (
        <Badge key={randomId()} mr="sm" color="green">
          {matchingDesignName(config)}
        </Badge>
      ))}
    </>
  )
}

export const FleetView = () => {
  const navigate = useNavigate()
  const [loaded, setLoaded] = useState(false)
  const [fleet, setFleet] = useState([])
  const { classes } = useStyles()
  const notificationId = {
    delete: 'manage-fleet-delete',
  }

  const removeFromView = (truck) => {
    const index = fleet.findIndex((t) => {
      return t.truckId === truck.truckId
    })

    fleet.splice(index, 1)
    setFleet([...fleet])
  }

  const handleView = (truck) => {
    navigate(`/dashboard/truck/${truck.truckId}`)
  }

  const handleEdit = (truck) => {
    navigate(`/dashboard/truck/${truck.truckId}/edit`)
  }

  const handleAddTruck = () => {
    navigate(`/dashboard/truck/create`)
  }

  const handleDelete = async (truck) => {
    notifications.show({
      id: `${notificationId.delete}.${truck.truckId}`,
      loading: true,
      title: 'Deleting Truck',
      autoClose: false,
      withCloseButton: false,
    })

    const truckName = truck?.name || `<MISSING_TRUCK_NAME.${truck.truckId}>`

    await fetchService.delete(`/auth/truck/${truck.truckId}`)
      .then(({ status, result }) => {
        notifications.update({
          id: `${notificationId.delete}.${truck.truckId}`,
          color: 'teal',
          title: 'Truck Deleted',
          message: `Successfully deleted "${truckName}". You can make a new truck through Fleet Management.`,
          icon: <IconCheck style={{ width: rem(18), height: rem(18) }} />,
          loading: false,
        })

        removeFromView(truck)
      })
      .catch((err) => {
        console.warn('Unable to delete truck', err)
        const errorMessage = (err?.response?.status === 404)
          ? `Your Truck "${truckName}" was not found and could not be deleted. Please try again, or refresh the page as it could have already been deleted.`
          : `We ran into an issue trying to delete "${truckName}" you can refresh the page and try again, or contact support.${err?.response?.data?.error?.message ? `[${err.response.data.error.message}]` : ''}`

        notifications.update({
          id: `${notificationId.delete}.${truck.truckId}`,
          color: 'red',
          title: 'Unable to delete Truck',
          message: errorMessage,
          icon: <IconX style={{ width: rem(18), height: rem(18) }} />,
          loading: false,
        })
      })
      .finally(() => {
      })
  }

  useEffect(() => {
    const fetchFleet = async () => {
      return await fetchService.get('/auth/fleet')
    }

    fetchFleet()
      .then(({ status, result }) => {
        const fleet = result?.fleet || []
        setFleet(fleet)
      })
      .catch((err) => {
        console.warn('Unable to fetch fleet', err)
      })
      .finally(() => {
        setLoaded(true)
      })
  }, [])

  const truckCards = useMemo(() => {
    return fleet.map((truck, index) => {
      return (
        <Card key={index} className={classes.card} withBorder padding="sm" radius="md">
          <Flex justify="space-between" align="center" mb="sm">
            <Text c="dimmed" fz="xs">
              Updated on {truck?.updatedOn}
            </Text>
    
            <ManageMenu truck={truck} onDelete={handleDelete} onView={handleView} onEdit={handleEdit}>
              <ActionIcon variant="transparent" color="blue" radius="xl" size="sm" aria-label="Options">
                <IconDotsVertical />
              </ActionIcon>
            </ManageMenu>
          </Flex>
  
          <Text fz="md" fw={500} mb="sm">
            <span style={{ cursor: 'pointer' }} onClick={() => handleEdit(truck)}>
              {truck?.name || '<TRUCK_NAME_NOT_SET>'}
            </span>
          </Text>
  
          <Flex>
            {truck?.configurations.length ? (
              <TruckConfigurationBadges truck={truck} />
            ) : (
              <Badge mr="sm" color="red" variant="filled">No Configurations</Badge>
            )}
          </Flex>
        </Card>
      )
    })
  }, [fleet])

  return (
    <Container p="0">
      <Title order={2} weight={500} mt="xl" mb="lg">Manage Fleet</Title>

      {!loaded ? (
        <LoadingData />
      ) : null}

      <SimpleGrid
        cols={3}
        spacing="md"
        breakpoints={[
          { maxWidth: '52rem', cols: 1, spacing: 'sm' },
          { maxWidth: '72rem', cols: 2, spacing: 'sm' },
          { minWidth: '92rem', cols: 3, spacing: 'md' },
        ]}
      >
        {truckCards}
        <CreateCard onClick={handleAddTruck} />
      </SimpleGrid>
    </Container>
  );
}
