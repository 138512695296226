import { rem } from '@mantine/core'
import { notifications } from '@mantine/notifications'
import { StatusCodes } from 'http-status-codes'
import { IconCheck, IconExclamationCircle } from '@tabler/icons-react'
import fetchService from '../../services/fetch.service'

export const blockUser = async (user, notificationId) => {
  const userId = user.id || user.userId,
      name = user.name,
      nId = notificationId ? notificationId : `action.user.${userId}.block`
   
  notifications.show({
    id: nId,
    loading: true,
    title: 'Blocking User',
    autoClose: false,
    withCloseButton: false,
  })

  try {
    const body = { block: true },
      response = await fetchService.patch(`/auth/user/${userId}`, { body })

    if (response.status === StatusCodes.OK) {
      notifications.update({
        id: nId,
        color: 'teal',
        title: `${name} was blocked`,
        icon: <IconCheck style={{ width: rem(18), height: rem(18) }} />,
        loading: false,
      })

      return true
    }
  } catch (err) {
    console.error('Unable to block user', err)
    notifications.update({
      id: nId,
      color: 'red',
      title: 'Unable to block user',
      message: `We encountered an error blocking ${name}. Please try again or contact support.`,
      icon: <IconExclamationCircle style={{ width: rem(18), height: rem(18) }} />,
      loading: false,
      autoClose: false,
    })
  }

  return false
}

export const unblockUser = async (user, notificationId) => {
  const userId = user.id || user.userId,
      name = user.name,
      nId = notificationId ? notificationId : `action.user.${userId}.block`
   
  notifications.show({
    id: nId,
    loading: true,
    title: 'Unblocking User',
    autoClose: false,
    withCloseButton: false,
  })

  try {
    const body = { block: false },
      response = await fetchService.patch(`/auth/user/${userId}`, { body })

    if (response.status === StatusCodes.OK) {
      notifications.update({
        id: nId,
        color: 'teal',
        title: `${name} was unblocked`,
        icon: <IconCheck style={{ width: rem(18), height: rem(18) }} />,
        loading: false,
      })

      return true
    }
  } catch (err) {
    console.error('Unable to block user', err)
    notifications.update({
      id: nId,
      color: 'red',
      title: 'Unable to unblock user',
      message: `We encountered an error unblocking ${name}. Please try again or contact support.`,
      icon: <IconExclamationCircle style={{ width: rem(18), height: rem(18) }} />,
      loading: false,
      autoClose: false,
    })
  }

  return false
}

export const saveUser = async (user, userSpec, notificationId) => {
  const userId = user.id || user.userId,
      name = user.name,
      nId = notificationId ? notificationId : `action.user.${userId}.save`

  notifications.show({
    id: nId,
    loading: true,
    title: 'Saving User',
    autoClose: false,
    withCloseButton: false,
  })

  try {
    const body = { ...userSpec },
      response = await fetchService.patch(`/auth/user/${userId}`, { body })

    if (response.status === StatusCodes.OK) {
      notifications.update({
        id: nId,
        color: 'teal',
        title: `Saved ${name}`,
        icon: <IconCheck style={{ width: rem(18), height: rem(18) }} />,
        loading: false,
      })

      return true
    }
  } catch (err) {
    console.error('Unable to save user', err)

    if (err.response.status === 403) {
      let message = 'You lack the permission to adjust this user.'
      if (user.role !== userSpec.role) {
        message += " You are unable to change this user's role."
      }

      notifications.update({
        id: nId,
        color: 'red',
        title: 'Unable to save user',
        message: `${message} If you believe this to be an error, please try again or contact support.`,
        icon: <IconExclamationCircle style={{ width: rem(18), height: rem(18) }} />,
        loading: false,
        autoClose: false,
      })

      return false
    } else if (err.response.status === 423) { // LOCKED
      let message = 'User cannot be updated at this time.'
      if (err.response?.data?.error?.context?.message) {
        message = err.response.data.error.context.message
      }

      notifications.update({
        id: nId,
        color: 'red',
        title: 'Unable to save user',
        message: `${message} – If you believe this to be an error, please try again or contact support.`,
        icon: <IconExclamationCircle style={{ width: rem(18), height: rem(18) }} />,
        loading: false,
        autoClose: false,
      })

      return false
    }

    notifications.update({
      id: nId,
      color: 'red',
      title: 'Unable to save user',
      message: `We encountered an error saving ${name}. Please try again or contact support.`,
      icon: <IconExclamationCircle style={{ width: rem(18), height: rem(18) }} />,
      loading: false,
      autoClose: false,
    })
  }

  return false
}
