import { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  Button,
  Modal,
  rem,
  createStyles,
  Text,
  ScrollArea,
  Table,
  Loader,
} from '@mantine/core'

import { noop } from '../../../utils/noop'
import { UserInfoCard } from '../../../common/user-info-card'
import { TicketStatusBadge } from '../../tickets/shared/ticket-status-badge'
import { fetchClient, revokeClientTicketAccess } from '../../tickets/modals/share-modal/actions'

const useStyles = createStyles((theme) => ({
  button: {
    borderRadius: theme.radius.sm,
    padding: `${theme.spacing.sm} ${theme.spacing.lg}`,
    cursor: 'pointer',
    height: rem('3.25rem'),
    maxWidth: rem('13rem'),
  },
  tableActionRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  tableHeader: {
    backgroundColor: '#ffffff',
  },
  tableHeadCol: {
    position: 'sticky',
    top: 0,
    backgroundColor: '#ffffff',
  },
  tableHeadColIcon: {
    zIndex: 1,
  },
}))

export const ManageClientModal = (props) => {
  const {
    company,
    target = null,
    onClose = noop,
    onRevokeSuccess = noop,
    onRevokeError = noop,
  } = props

  const [isActive, setActive] = useState(false),
    { classes } = useStyles(),
    [sharedTickets, setSharedTickets] = useState([]),
    [loadError, setLoadError] = useState(false),
    [processing, setProcessing] = useState(false),
    [revokeId, setRevokeId] = useState(null)

  const hydrateSharedTickets = () => {
    fetchClient(target, company)
      .then((response) => {
        if (!response.ok) {
          setLoadError(true)
          setSharedTickets(null)
        } else {
          setSharedTickets(response.shared)
          setLoadError(false)
        }
      })
  }

  const revokeAccess = async (ticket) => {
    setProcessing(true)
    setRevokeId(ticket.meta.id)

    try {
      const response = await revokeClientTicketAccess(target, ticket)
      if (!response.ok) {
        throw new Error('Failed to revoke access')
      }

      onRevokeSuccess(target, ticket)
      hydrateSharedTickets()
    } catch (err) {
      onRevokeError(target, ticket)
    } finally {
      setProcessing(false)
      setRevokeId(null)
    }
  }

  const handleClose = () => {
    setActive(false)
    onClose()
  }

  useEffect(() => {
    setActive(!!target)
    if (target) {
      hydrateSharedTickets()
    }
  }, [target])

  const tableRows = useMemo(() => {
    if (!sharedTickets || !sharedTickets.length) return null

    return sharedTickets.map((ticket, i) => {
      const isRevoking = ticket.meta.id === revokeId

      return (
        <tr key={i}>
          <td><Link to={`/dashboard/ticket/${ticket.meta.id}`}>{ticket.meta.name}</Link></td>
          <td><TicketStatusBadge ticket={ticket} /></td>
          <td>#{ticket.project.projectNumber}<br/>p/o {ticket.project.purchaseOrder}</td>
          <td>
            <Button color="red" onClick={() => revokeAccess(ticket)} disabled={processing}>
              {isRevoking && processing ? (
                <Loader color="white" size="sm" />
              ) : 'Revoke'}
            </Button>
          </td>
        </tr>
      )
    })
  }, [processing, revokeId, sharedTickets])

  return (
    <Modal opened={isActive} onClose={handleClose} title="Manage Client Access">
      <Text fz="sm">All tickets this client has access to can be found below. You can revoke their access at any point. You can also manage access to a ticket by navigating to the Ticket View.</Text>

      <UserInfoCard mt="md" person={target} type="client" />
      
      <Text fz="lg" fw={300} mt="lg" mb="sm">Ticket Access</Text>

      {!loadError && (!sharedTickets || !sharedTickets.length) ? (
        <Text fz="sm">No tickets shared yet</Text>
      ) : null}
      
      {!loadError ? (
        <ScrollArea mb="xl" h={400}>
          <Table highlightOnHover withColumnBorders withBorder striped>
            <thead className={classes.tableHeader}>
              <tr>
                <th className={classes.tableHeadCol}>Name</th>
                <th className={classes.tableHeadCol}>Status</th>
                <th className={classes.tableHeadCol}>Project</th>
                <th className={classes.tableHeadCol}>{' '}</th>
                {/* <th className={cx(classes.tableHeadCol, classes.tableHeadColIcon)}>
                  <Center><IconSettings /></Center>
                </th> */}
              </tr>
            </thead>
            <tbody>
              {tableRows}
            </tbody>
          </Table>
        </ScrollArea>
      ) : (
        <Text fz="sm">We had trouble loading tickets for this client</Text>
      )}
    </Modal>
  )
}
