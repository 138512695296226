
import { useEffect, useMemo, useState } from 'react'
import { useLoaderData, useNavigate } from 'react-router-dom'
import { randomId } from '@mantine/hooks'
import {
  Container,
  SimpleGrid,
  Text,
  Title,
  rem,
  ActionIcon,
  createStyles,
  Card,
  Flex,
  Menu,
  Badge,
} from '@mantine/core'
import {
  IconDotsVertical,
  IconPencil,
  IconShieldOff,
  IconShield,
} from '@tabler/icons-react'
import browserStorageService, { STORAGE_KEY } from '../../services/browser-storage.service'
import { ActionLinkToUsers } from './shared/action-link-to-users'
import { UserStatusBadge } from './shared/user-status-badge'
import { formatTimestamp } from '../tickets/helper'
import { userCanModify } from './helper'
import { blockUser, unblockUser } from './actions'

// import moduleClasses from './users.module.scss'

const useStyles = createStyles((theme) => ({
  card: {
    overflow: 'inherit',
  },
  button: {
    borderRadius: theme.radius.sm,
    padding: `${theme.spacing.sm} ${theme.spacing.lg}`,
    cursor: 'pointer',
    height: rem('3.25rem'),
    maxWidth: rem('13rem'),
  },
}));

const ManageUserMenu = (props) => {
  const {
    children,
    user = {},

    onEdit,
    onBlock,
    onUnblock,
  } = props

  const iconSize = 16,
    [canModify, setCanModify] = useState(false)

  useEffect(() => {
    browserStorageService.getItem(STORAGE_KEY.ROLE)
      .then((role) => {
        setCanModify(userCanModify(role))
      })
  }, [setCanModify])

  return (
    <Menu shadow="md" width={200}>
      <Menu.Target>{children}</Menu.Target>
      <Menu.Dropdown>
        <Menu.Label>{user.name || 'No name set'}</Menu.Label>
 
        {canModify ? (
          <>
            <Menu.Item
              onClick={() => onEdit(user)}
              icon={<IconPencil size={iconSize}/>}
            >
              Edit User
            </Menu.Item>
            {user?.flags?.blocked ? (
              <Menu.Item
                onClick={() => onUnblock(user)}
                icon={<IconShieldOff size={iconSize} />}
              >
                Unblock User
              </Menu.Item>
            ) : (
              <Menu.Item
                onClick={() => onBlock(user)}
                icon={<IconShield size={iconSize} />}
              >
                Block User
              </Menu.Item>
            )}
          </>
        ) : null}
      </Menu.Dropdown>
    </Menu>
  )
}

export const UserView = () => {
  const importedUser = useLoaderData(),
    [user, setUser] = useState(null),
    [loaded, setLoaded] = useState(false),
    [forbidden, setForbidden] = useState(false),
    navigate = useNavigate(),
    classes = useStyles()

  // importedUser
  useEffect(() => {
    if (importedUser && !importedUser.errorCode) {
      setLoaded(true)
      setUser(importedUser)
    } else if (importedUser.errorCode) {
      if (importedUser.errorCode === 403) setForbidden(true)
    }
  }, [importedUser])

  const missingSpec = useMemo(() => {
    if (!loaded) return null
    if (loaded && !user) {
      return (
        <Text>404 User Not Found</Text>
      )
    }
  }, [loaded, user])

  const handleEdit = (ticket) => {
    navigate(`/dashboard/user/${user.userId || user.id}/edit`)
  }

  const handleBlock = async (user) => {
    if (await blockUser(user)) {
      navigate('.', { replace: true })
    }
  }

  const handleUnblock = async (user) => {
    if (await unblockUser(user)) {
      navigate('.', { replace: true })
    }
  }

  const renderSpecList = (specs, index = null) => {
    const specList = specs.map(({ label, value }) => (
      <div key={randomId()} mb="md" pl="md" pr="md">
        <Text size="xs" color="dimmed">{label}</Text>
        <Text fw={500} size="sm">{value}</Text>
      </div>
    ))

    return (
      <SimpleGrid
        className={isNaN(index) ? '' : `spec-${index}`}
        cols={3}
        spacing="md"
        breakpoints={[
          { maxWidth: '52rem', cols: 1, spacing: 'sm' },
          { maxWidth: '72rem', cols: 3, spacing: 'sm' },
          { minWidth: '92rem', cols: 3, spacing: 'md' },
        ]}
        p={5}
        key={randomId()}
      >
        {specList}
      </SimpleGrid>
    )
  }

  const manageUserMenu = useMemo(() => {
    if (!loaded || (loaded && !user)) return null

    const methods = {
      onEdit:         handleEdit,
      onBlock:        handleBlock,
      onUnblock:      handleUnblock,
    }

    return (
      <ManageUserMenu user={user} {...methods}>
        <ActionIcon variant="transparent" color="blue" radius="xl" size="sm" aria-label="Options">
          <IconDotsVertical />
        </ActionIcon>
      </ManageUserMenu>
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded, user])

  const renderSpec = useMemo(() => {
    if (!loaded || (loaded && !user)) return null
    
    const missingValue = '<empty>'
    const fillSpec = (label, value) => ({
      label,
      value: value === undefined || value === null || value === '' ? missingValue : value,
    })

    const {
      createdAt,
      updatedAt,
      role,
      phone,
      name,
      email,
      flags = {},
    } = user

    const personalSpec = [
      fillSpec('Name', name),
      fillSpec('Email', email),
      fillSpec('Phone', phone),
    ]

    const companySpec = [
      fillSpec('Role', role),
    ]

    const accountSpec = [
      fillSpec('Deleted', flags.deleted ? 'Yes' : 'No'),
      fillSpec('Blocked', flags.blocked ? 'Yes' : 'No'),
      fillSpec('Verified', flags.verified ? 'Yes' : 'No'),
    ]

    const createdOn = createdAt
      ? `${formatTimestamp(createdAt)}`
      : '---'

    const updatedOn = updatedAt
      ? `${formatTimestamp(updatedAt)}`
      : '---'

    return (
      <Card className={classes.card} withBorder padding="lg" radius="md">
        <Flex justify="flex-end" align="center">
          {user.flags.blocked ? (
            <Badge mr="sm" variant="filled" color="red">Blocked</Badge>
          ) : null}
          <UserStatusBadge user={user} />

          {manageUserMenu}
        </Flex>

        <Text fz="lg" fw={300} mt="lg" mb="md">Personal Details</Text>
        {renderSpecList(personalSpec)}

        <Text fz="lg" fw={300} mt="lg" mb="md">Company</Text>
        {renderSpecList(companySpec)}

        <Text fz="lg" fw={300} mt="lg" mb="md">Account Status</Text>
        {renderSpecList(accountSpec)}

        <Text c="dimmed" fz="sm" mt="md">
          Created on {createdOn}
        </Text>

        <Text c="dimmed" fz="sm" mt="md">
          Updated on {updatedOn}
        </Text>
      </Card>
    )
  }, [loaded, user, manageUserMenu, classes.card])

  if (forbidden) {
    return (
      <Container my="md">
      <Title order={2} weight={500} mt="xl" mb="lg">View User</Title>
      <ActionLinkToUsers />

      <Card className={classes.card} withBorder padding="lg" radius="md">
        <Text>You lack permission to view this user</Text>
      </Card>
    </Container>
    )
  }

  return (
    <Container my="md">
      <Title order={2} weight={500} mt="xl" mb="lg">View User</Title>
      <ActionLinkToUsers />

      {missingSpec}
      {renderSpec}
    </Container>
  );
}
