import ReactGA from 'react-ga4'

export const useAnalytics = () => {
  const trackEvent = (category, action, label) => {
    console.info(`GA Event
    category: ${category}
    action: ${action}
    label: ${label}`)

    ReactGA.event({
      category,
      action,
      label,
    })
  }

  return [trackEvent]
}
