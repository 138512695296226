import { rem } from '@mantine/core'
import { notifications } from '@mantine/notifications'
import { StatusCodes } from 'http-status-codes'
import { IconCheck, IconExclamationCircle } from '@tabler/icons-react'
import fetchService from '../../services/fetch.service'

export const saveBusiness = async (company, companySpec, notificationId) => {
  const companyId = company.id || company.companyId,
      name = company.name,
      nId = notificationId ? notificationId : `action.company.${companyId}.save`

  notifications.show({
    id: nId,
    loading: true,
    title: 'Saving Company',
    autoClose: false,
    withCloseButton: false,
  })

  try {
    const body = { ...companySpec },
      response = await fetchService.patch(`/auth/companies/${companyId}`, { body })

    if (response.status === StatusCodes.OK) {
      let title = `Saved ${name}`
      if (company.name !== companySpec.name) {
        title = `Updated ${companySpec.name}`
      }

      notifications.update({
        id: nId,
        color: 'teal',
        title,
        icon: <IconCheck style={{ width: rem(18), height: rem(18) }} />,
        loading: false,
      })

      return true
    }
  } catch (err) {
    console.error('Unable to save company', err)

    if (err.response.status === 403) {
      let message = 'You lack the permission to adjust this company.'

      notifications.update({
        id: nId,
        color: 'red',
        title: 'Unable to save company',
        message: `${message} If you believe this to be an error, please try again or contact support.`,
        icon: <IconExclamationCircle style={{ width: rem(18), height: rem(18) }} />,
        loading: false,
        autoClose: false,
      })

      return false
    }

    notifications.update({
      id: nId,
      color: 'red',
      title: 'Unable to save company',
      message: `We encountered an error saving ${company.name}. Please try again or contact support.`,
      icon: <IconExclamationCircle style={{ width: rem(18), height: rem(18) }} />,
      loading: false,
      autoClose: false,
    })
  }

  return false
}
