
import { useEffect, useMemo, useState } from 'react'
import { useLoaderData, useNavigate, useParams } from 'react-router-dom'
import {
  Container,
  SimpleGrid,
  Text,
  Title,
  rem,
  ActionIcon,
  createStyles,
  Card,
  Flex,
  Menu,
} from '@mantine/core'
import { randomId } from '@mantine/hooks'
import {
  IconDotsVertical,
  IconTrash,
  IconPencil,
  IconShare2,
  IconCloudDownload,
  IconChecks,
  IconClipboardCheck,
  IconLockShare,
} from '@tabler/icons-react'

import browserStorageService, { STORAGE_KEY } from '../../services/browser-storage.service'
import { ActionLinkToTickets } from './shared/action-link-to-tickets'
import { TicketStatusBadge } from './shared/ticket-status-badge'
import { TICKET_STATUSES, formatTimestamp, formatUnix } from './helper'
import { approveTicket, downloadTicketPDF, submitTicket, viewTicketPDF, voidTicket } from './actions'
import { ShareModal } from './modals/share-modal'

const useStyles = createStyles((theme) => ({
  card: {
    overflow: 'inherit',
  },
  button: {
    borderRadius: theme.radius.sm,
    padding: `${theme.spacing.sm} ${theme.spacing.lg}`,
    cursor: 'pointer',
    height: rem('3.25rem'),
    maxWidth: rem('13rem'),
  },
}));

const ManageDesignMenu = (props) => {
  const {
    children,
    ticket = {},

    onEdit,
    onViewPDF,
    onDownloadPDF,
    onVoid,
    onApprove,
    onSubmit,
    onManageAccess,
  } = props

  const status = ticket.status || ticket.meta.status,
    iconSize = 16

  const editableStatus = ![TICKET_STATUSES.VOID, TICKET_STATUSES.PUBLISHED, TICKET_STATUSES.APPROVED].includes(status),
    reviewable = status === TICKET_STATUSES.IN_REVIEW,
    voidable = status !== TICKET_STATUSES.VOID,
    submittable = status === TICKET_STATUSES.DRAFT,
    canViewPDF = ticket.meta.storedFile?.length,
    [canModify, setCanModify] = useState(false),
    [canEdit, setCanEdit] = useState(false),
    [company, setCompany] = useState(null)

  useEffect(() => {
    browserStorageService.getItem(STORAGE_KEY.ROLE)
      .then((role) => {
        setCanModify(role === 'OWNER')
      })
  }, [setCanModify])

  useEffect(() => {
    browserStorageService.getItem(STORAGE_KEY.SESSION)
      .then((session) => {
        const modifyAccess = [ticket?.refs.submittedBy, ticket?.refs.assignedTo].includes(session.userId) || canModify
        setCanEdit(modifyAccess)
        setCompany(session.company)
      })
  }, [canModify, setCanEdit, ticket?.refs])

  return (
    <Menu shadow="md" width={200}>
      <Menu.Target>{children}</Menu.Target>
      <Menu.Dropdown>
        <Menu.Label>{ticket.meta.name}</Menu.Label>

        {(canModify) ? (
          <Menu.Item
            onClick={() => onManageAccess(ticket)}
            icon={<IconLockShare size={iconSize}/>}
          >
            Manage Access
          </Menu.Item>
        ) : null}

        {canViewPDF ? (
          <>
            <Menu.Item
              onClick={() => onViewPDF(ticket)}
              icon={<IconShare2 size={iconSize} />}
            >
              Open PDF
            </Menu.Item>

            <Menu.Item
              onClick={() => onDownloadPDF(ticket)}
              icon={<IconCloudDownload size={iconSize} />}
            >
              Download PDF
            </Menu.Item>
          </>
        ) : null}

        {submittable ? (
          <Menu.Item
            onClick={() => onSubmit(ticket)}
            icon={<IconClipboardCheck size={iconSize}/>}
          >
            Submit Ticket
          </Menu.Item>
        ) : null}

        {(canEdit && editableStatus) ? (
          <Menu.Item
            onClick={() => onEdit(ticket)}
            icon={<IconPencil size={iconSize}/>}
          >
            Edit Ticket
          </Menu.Item>
        ) : null}

        {canModify ? (
          <>
            {reviewable ? (
              <Menu.Item
                color="green"
                onClick={() => onApprove(ticket)}
                icon={<IconChecks size={iconSize}/>}
              >
                Approve Ticket
              </Menu.Item>
            ) : null}
            {voidable ? (
              <Menu.Item
                color="red"
                onClick={() => onVoid(ticket)}
                icon={<IconTrash size={iconSize} />}
              >
                Void Ticket
              </Menu.Item>
            ) : null}
          </>
        ) : null}
      </Menu.Dropdown>
    </Menu>
  )
}

export const renderSpecList = (specs, index = null) => {
  const specList = specs.map(({ label, value }) => (
    <div key={randomId()} mb="md" pl="md" pr="md">
      <Text size="xs" color="dimmed">{label}</Text>
      <Text fw={500} size="sm">{value}</Text>
    </div>
  ))

  return (
    <SimpleGrid
      className={isNaN(index) ? '' : `spec-${index}`}
      cols={3}
      spacing="md"
      breakpoints={[
        { maxWidth: '52rem', cols: 1, spacing: 'sm' },
        { maxWidth: '72rem', cols: 3, spacing: 'sm' },
        { minWidth: '92rem', cols: 3, spacing: 'md' },
      ]}
      p={5}
      key={randomId()}
    >
      {specList}
    </SimpleGrid>
  )
}

export const fillSpec = (label, value) => ({
  label,
  value: value === undefined || value === null || value === '' ? '<empty>' : value,
})

export const TicketSpecsheet = (props) => {
  const { loaded, ticket, ticketMenu = null } = props,
    classes = useStyles(),
    ticketIsEmpty = (!ticket || !Object.keys(ticket).length)

  if (!loaded) return null
  else if (loaded && ticketIsEmpty) return (
    <Card className={classes.card} withBorder padding="lg" radius="md">
      <Text>404 Ticket Not Found</Text>
    </Card>
  )

  const {
    company = {},
    meta = {},
    inputs = {},
    admixtures = {},
    aggregates = {},
    project = {},
    truck = {},
    truckConfig: design,
    math = {},
    submittedBy = {},
    modifiedBy = {},
    approvedBy = {},
  } = ticket

  const {
    address,
    city,
    state,
    zipcode,
    country,
  } = company

  const companyAddress = `${address || ''} ${city || ''} ${state || ''} ${zipcode || ''} ${country || ''}`.trim()
  const companySpec = [
    fillSpec('Company Name', company.name),
    fillSpec('Company Phone', company.phone),
    fillSpec('Company Address', companyAddress),
  ]

  const truckSpec = [
    fillSpec('Truck Name', truck.name),
  ]

  const {
    water: designWater = {},
    meta: designMeta = {},
    cement: designCement = {},
    admixtures: designAdmixtures = [],
    truckConfig = {},
  } = design

  const designSpec = [
    fillSpec('Design Name', designMeta.name || ''),
    fillSpec('Design Number', designMeta.mixDesignNumber || ''),
    fillSpec('Theoretical Water Cement Ratio', designWater.cementRatio),
  ]

  const designAdmixtureSpecs = designAdmixtures.reduce((_list, admx) => {
    const { uid, name } = admx
    const { calibratedFlow = 'x.xx' } = (truckConfig.admixtures || {}).find((_admx) => _admx.uid === uid) || {}
    _list.push(fillSpec(`Admixture [${name}]`, `Calibrated Flow: ${calibratedFlow}`))
    return _list
  }, [])

  const projectSpec = [
    fillSpec('Project Name', meta.name),
    fillSpec('Project Number', project.projectNumber),
    fillSpec('Project ID', project.projectId),
    fillSpec('Project PO#', project.purchaseOrder),
    fillSpec('Project Location', `${project.location} (${project.zipcode || 'Unknown Zipcode'})`),
    fillSpec('Project Date', formatUnix(project.date, { showTime: false })),
    fillSpec('Project Start Time', `${project.startTime}`),
    fillSpec('Project End Time', `${project.endTime}`),
  ]

  const createdOn = meta.createdOn
    ? `${formatTimestamp(meta.createdOn)} by ${submittedBy?.name || ticket.refs?.submittedBy || 'N/A'}`
    : '---'

  const updatedOn = meta.updatedOn
    ? `${formatTimestamp(meta.updatedOn)} by ${modifiedBy?.name || ticket.refs?.modifiedBy || 'N/A'}`
    : '---'

  const approvedOn = meta.approvedOn
    ? `${formatUnix(meta.approvedOn)} by ${approvedBy?.name || ticket.refs?.approvedBy || 'N/A'}`
    : '---'

  const metaSpec = [
    fillSpec('UID', meta.ticketUid),
    fillSpec('Status', meta.status),
    fillSpec('Revisions', meta.revision),
    fillSpec('Created', createdOn),
    fillSpec('Modified', updatedOn),
    fillSpec('Approved', approvedOn),
  ]

  const inputSpec = [
    fillSpec('Specialty Total', `${inputs.specialtyTotal} gal.`),
    fillSpec('Water Total', `${inputs.waterTotal} gal.`),
    fillSpec('Total Counts', designCement.total),
    fillSpec('Cement Total', inputs.cementTotal),
    fillSpec('Specialty Total', inputs.specialtyTotal),
  ]

  const calculatedSpec = [
    fillSpec('Concrete Poured', `${parseFloat(math.totalConcretePoured)} yards`),
    fillSpec('Specialty (yds)', `${parseFloat(math.totalSpecialtyPerYard)} yards`),
    fillSpec('Water (yds)',`${parseFloat(math.totalWaterPerYard)} yards`),
    
    fillSpec('Water (lbs)',`${parseFloat(math.totalWaterLbs)} lbs`),
    fillSpec('Specialty (lbs)',`${parseFloat(math.totalSpecialtyLbs)} lbs`),
    fillSpec('Cement (lbs)',`${parseFloat(math.totalCement)} lbs`),
    fillSpec('Water Cement Ratio',`${parseFloat(math.waterCementRatio)}`),
  ]

  const aggregateSpec = [
    ...aggregates.map((agg) => fillSpec(`Aggregate: ${agg.name}`, `Confirmed? ${agg.confirmed ? 'Yes' : 'No'}`)),
  ]

  const admixtureSpec = [
    ...admixtures.map((admx) => fillSpec(`Admixture: ${admx.name}`, `Confirmed? ${admx.confirmed ? 'Yes' : 'No'}`)),
  ]

  return (
    <Card className={classes.card} withBorder padding="lg" radius="md">
      <Flex justify="flex-end" align="center">
        <TicketStatusBadge ticket={ticket} />
        {ticketMenu}
      </Flex>

      <Text fz="lg" fw={300} mt="lg" mb="md">Company</Text>
      {renderSpecList(companySpec)}

      <Text fz="lg" fw={300} mt="lg" mb="md">Truck</Text>
      {renderSpecList(truckSpec)}

      <Text fz="lg" fw={300} mt="lg" mb="md">Design</Text>
      {renderSpecList(designSpec)}
      {renderSpecList(designAdmixtureSpecs)}

      <Text fz="lg" fw={300} mt="lg" mb="md">Project</Text>
      {renderSpecList(projectSpec)}

      <Text fz="lg" fw={300} mt="lg" mb="md">Ticket Details</Text>
      {renderSpecList(metaSpec)}

      <Text fz="lg" fw={300} mt="lg" mb="md">Inputs</Text>
      {renderSpecList(inputSpec)}

      <Text fz="lg" fw={300} mt="lg" mb="md">Calculations</Text>
      {renderSpecList(calculatedSpec)}

      <Text fz="lg" fw={300} mt="lg" mb="md">Confirmations</Text>
      {[aggregateSpec, admixtureSpec].map((specs, index) => renderSpecList(specs, index))}

      {/* <Text c="dimmed" fz="sm" mt="md">
        {design.status === 'PUBLISHED' ? (
          `Published on ${design.createdAt}`
        ) : (
          `Draft saved on ${design.updatedAt}`
        )}
      </Text> */}
    </Card>
  )
}

export const TicketView = () => {
  const params = useParams(),
    importedTicket = useLoaderData(),
    [ticket, setTicket] = useState(null),
    [loaded, setLoaded] = useState(false),
    navigate = useNavigate(),
    [manageTarget, setManageTarget] = useState(null)

  useEffect(() => {
    if (importedTicket) {
      setLoaded(true)
      setTicket(importedTicket)
    }
  }, [importedTicket])

  const handleEdit = (ticket) => {
    navigate(`/dashboard/ticket/${ticket.meta.id}/edit`)
  }

  const handleApprove = async (ticket) => {
    if (await approveTicket(ticket)) {
      navigate(`/dashboard/tickets`)
    }
  }

  const handleSubmit = async (ticket) => {
    if (await submitTicket(ticket)) {
      navigate(`/dashboard/tickets`)
    }
  }

  const handleViewPDF = (ticket) => {
    viewTicketPDF(ticket)
  }

  const handleDownloadPDF = (ticket) => {
    downloadTicketPDF(ticket)
  }

  const handleVoid = async (ticket) => {
    if (await voidTicket(ticket)) {
      navigate(`/dashboard/tickets`)
    }
  }

  const handleManageAccess = (ticket) => {
    setManageTarget(ticket)
  }

  const handleCloseShare = () => {
    setManageTarget(null)
  }

  const manageTicketMenu = useMemo(() => {
    if (!loaded || loaded && !ticket) return null

    const methods = {
      onViewPDF:      handleViewPDF,
      onDownloadPDF:  handleDownloadPDF,
      onEdit:         handleEdit,
      onApprove:      handleApprove,
      onSubmit:       handleSubmit,
      onVoid:         handleVoid,
      onManageAccess: handleManageAccess,
    }

    return (
      <ManageDesignMenu ticket={ticket} {...methods}>
        <ActionIcon variant="transparent" color="blue" radius="xl" size="sm" aria-label="Options">
          <IconDotsVertical />
        </ActionIcon>
      </ManageDesignMenu>
    )
  }, [loaded, ticket])

  return (
    <Container my="md">
      <Title order={2} weight={500} mt="xl" mb="lg">View Ticket</Title>
      <ActionLinkToTickets />
      <ShareModal
        target={manageTarget}
        onClose={handleCloseShare}
        title="Manage Ticket Access"
        hideTicketDetails
      />

      <TicketSpecsheet loaded={loaded} ticket={ticket} ticketMenu={manageTicketMenu} />
    </Container>
  );
}
