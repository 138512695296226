import { useEffect, useRef } from 'react'
import {
  TextInput,
  NumberInput,
  Text,
  ActionIcon,
  createStyles,
  rem,
  Stack,
  Select,
} from '@mantine/core'
import { IconCheck } from '@tabler/icons-react'
import classNames from 'classnames'

const useStyles = createStyles((theme) => ({
  label: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontWeight: 400,
    fontSize: rem(14),
    lineHeight: 1,
  },
}))

export const DynamicField = (props) => {
  const {
    mt = 'md',
    form,
    name,
    label,
    placeholder,
    inlineLabel = label,
    editing,
    rightSection = null,
    rightSectionWidth = null,
    inline = false,
    // onInlineEditToggle = null,
    onUpdate = null,
    type = 'text',
    data: selectData,
    // passedProps = {},
  } = props

  const { classes } = useStyles(),
    // inputRef = useRef(),
    inputSize = 'sm',
    Input = TextInput,
    inputProps = { }
    // type === 'number'
    //   ? NumberInput
    //   : TextInput,
    // inputProps = { ...passedProps }
      // ? { ...passedProps, hideControls: true }
      // : { ...passedProps }

  // const toggleField = () => {
  //   form.validateField(name)
  //   if (form.isValid(name)) {
  //     console.log('--isvalid')
  //     onInlineEditToggle(name)
  //     onUpdate(name, form.values[name])
  //   }
  // }

  const handleBlur = () => {
    form.validateField(name)
    if (form.isValid(name)) {
      onUpdate(name, form.values[name])
    }
  }

  // useEffect(() => {
  //   if (editing && inputRef) {
  //     inputRef?.current?.focus()
  //   }
  // }, [editing])

  // const SaveActionIcon = () => (
  //   <ActionIcon color="green" onClick={toggleField}><IconCheck /></ActionIcon>
  // )

  if (type === "select") {
    const selectProps = {
      name,
      'aria-label': label,
      placeholder,
      data: selectData,
      ...form.getInputProps(name),
    }

    if (inline) {
      return (
        <Stack mt={mt} spacing="xs">
          <Select
            {...selectProps}
            onBlur={handleBlur}
          />
          <Text mt={rem(-5)} className={classes.label} color="dimmed">{inlineLabel}</Text>
        </Stack>
      )
    } else {
      return (
        <Select
          mb="sm"
          label={label}
          {...selectProps}
        />
      )
    }
  }

  if (inline) {
    return (
      <Stack mt={mt} spacing="xs">
        <Input
          className={classNames('dynamic-field__input', { 'is-editing': editing })}
          // variant={editing ? '' : 'unstyled'}
          aria-label={inlineLabel}
          name={name}
          placeholder={placeholder}
          size={inputSize}
          {...form.getInputProps(name)}
          // onFocus={toggleField}
          // onBlur={toggleField}
          onBlur={handleBlur}
          // ref={inputRef}
          // rightSection={editing ? (<SaveActionIcon />) : null}
          type={type}
          {...inputProps}
        />
        <Text mt={rem(-5)} className={classes.label} color="dimmed">{inlineLabel}</Text>
      </Stack>
    )
  }

  return (
    <Input
      mb="sm"
      label={label}
      name={name}
      placeholder={placeholder}
      size={inputSize}
      rightSection={rightSection}
      rightSectionWidth={rightSectionWidth}
      type={type}
      {...form.getInputProps(name)}
      {...inputProps}
    />
  )
  
  // return (
  //   <Stack mt={mt} spacing="xs">
  //     {!inline ? (
        
  //     ) : (
  //       <>
  //         <Input
  //           className={classNames('dynamic-field__input', { 'is-editing': editing })}
  //           variant={editing ? '' : 'unstyled'}
  //           aria-label={inlineLabel}
  //           name={name}
  //           placeholder={placeholder}
  //           size={inputSize}
  //           {...form.getInputProps(name)}
  //           onFocus={toggleField}
  //           onBlur={toggleField}
  //           ref={inputRef}
  //           rightSection={editing ? (<SaveActionIcon />) : null}
  //           type={type}
  //           {...inputProps}
  //         />
  //         <Text mt={rem(-5)} className={classes.label} color="dimmed">{inlineLabel}</Text>
  //       </>
  //     )}
  //   </Stack>
  // )
}
