import { Title } from "@mantine/core"

export const SectionHeader = (props) => {
  const {
    title = 'Heading',
    children
  } = props

  return (
    <Title order={3} weight={300} mb="xs" mt="md">
      <span>{children || title}</span>
    </Title>
  )
}
