import { Link, useNavigate } from 'react-router-dom';
import { Container, Title, Text, Button, Group, Box, Center } from '@mantine/core';
import { Illustration } from './shared/illustration';
import classes from './errors.module.scss';

export const ErrorNotFound = () => {
  const navigate = useNavigate()
  const handleClick = () => {
    navigate('/')
  }

  return (
    <Container className={classes.darkRoot}>
      <div className={classes.inner}>
        <Illustration className={classes.image} />
        <div className={classes.content}>
          <Title className={classes.title}>Nothing to see here</Title>
          <Text c="dimmed" size="lg" ta="center" className={classes.description}>
            Page you are trying to open does not exist. You may have mistyped the address, or the
            page has been moved to another URL.
          </Text>
          <Center>
            <Button size="md" onClick={handleClick}>Take me back to the home page</Button>
          </Center>
        </div>
      </div>
    </Container>
  );
}
