import { useEffect, useState } from 'react'
import { HttpStatusCode } from 'axios'
import { z } from 'zod'
import {
  Button,
  rem,
  createStyles,
  TextInput,
  SimpleGrid,
  UnstyledButton,
  Text,
  Flex,
  Loader,
  Box,
} from '@mantine/core'
import { useForm, zodResolver } from '@mantine/form'
import {
  IconAlertCircle,
} from '@tabler/icons-react'

import { noop } from '../../../utils/noop'
import browserStorageService, { STORAGE_KEY } from '../../../services/browser-storage.service'
import { usePhoneInput } from '../../../hooks/usePhoneInput'
import { addNewClient } from '../modals/share-modal/actions'

const useStyles = createStyles((theme) => ({
  button: {
    borderRadius: theme.radius.sm,
    padding: `${theme.spacing.sm} ${theme.spacing.lg}`,
    cursor: 'pointer',
    height: rem('3.25rem'),
    maxWidth: rem('13rem'),
  },
  tableActionRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  tableHeader: {
    backgroundColor: '#ffffff',
  },
  tableHeadCol: {
    position: 'sticky',
    top: 0,
    backgroundColor: '#ffffff',
  },
  tableHeadColIcon: {
    zIndex: 1,
  },
}))

const schemaAggregate = z.object({
  name: z.string().min(1, { message: 'That name looks short' }),
  email: z.string().email({ message: 'That email does not look right' }),
  phone: z.string().min(10, { message: 'That phone number does not look right' }),
})

export const NewClientForm = (props) => {
  const {
    saveLabel = 'Save and Share',
    hideTicketDetails = false,
    prefill = null,
    onSuccess = noop,
    onCancel = noop,
  } = props

  const [sessionCompany, setCompany] = useState({}),
    [errorMessage, setError] = useState(''),
    [processing, setProcessing] = useState(false),
    { classes, cx } = useStyles(),
    [enforceFormat, formatPhone] = usePhoneInput()

  // Load company data
  useEffect(() => {
    browserStorageService.getItem(STORAGE_KEY.SESSION)
      .then((session) => {
        setCompany(session.company)
      })
  }, [])

  const clientForm = useForm({
    initialValues: {
      name: '',
      email: '',
      phone: '',
      business: '',
    },

    validate: zodResolver(schemaAggregate.required()),

    transformValues: (values) => ({
      formattedPhone: formatPhone(values.phone),
    }),
  })

  // TODO phone should be DRY
  const onPhoneInput = (event) => {
    const { target: { value = '', name = '' } = {} } = event || {}
    if (name === 'client.phone') {
      clientForm.setFieldValue('phone', value.replace(/\D/g, '').substring(0, 10))
    }
  }

  const handleSubmitAdd = async () => {
    setError('')
    setProcessing(true)
    clientForm.validate()
    if (!clientForm.isValid()) {
      setError('Please double check the information above and try again')
      setProcessing(false)
      return false
    }

    try {
      const addClientResponse = await addNewClient(clientForm.values, sessionCompany)
      if (!addClientResponse.ok && addClientResponse.status === HttpStatusCode.Conflict) {
        setError("It looks like this client already exists. You'll need to adjust the phone number or email address.")
        return
      } else if (!addClientResponse.ok || !addClientResponse.client) {
        setError('We ran into an issue saving this client. Please try again later or contact support.')
        return
      }

      handleSuccessAdd(addClientResponse.client)
      return true
    } catch (err) {
      setError('Unable to process this request at this time. Please try again later or contact support.')
      return false
    } finally {
      setProcessing(false)
    }
  }

  const handleSuccessAdd = (createdClient) => {
    setProcessing(false)
    clientForm.reset()
    onSuccess(createdClient)
  }

  const handleCancelAdd = () => {
    setProcessing(false)
    clientForm.reset()
    onCancel()
  }

  useEffect(() => {
    if (!prefill) return

    Object.keys(prefill).forEach((key) => {
      if (prefill[key].length) {
        clientForm.setFieldValue(key, prefill[key])
      }
    })
  }, [prefill])

  return (
    <Box>
      <SimpleGrid
        cols={2}
        mt={!hideTicketDetails ? "lg" : ""}
        spacing="md"
        breakpoints={[
          { maxWidth: '30rem', cols: 1, spacing: 'sm' },
          { maxWidth: '72rem', cols: 2, spacing: 'sm' },
        ]}
      >
        <TextInput
          label="Full name"
          name="client.name"
          placeholder="John Smith"
          size="sm"
          withAsterisk
          {...clientForm.getInputProps('name')}
        />

        <TextInput
          className={classes.textInput}
          label="Business name"
          name="client.business"
          placeholder="Acme Co."
          size="sm"
          {...clientForm.getInputProps('business')}
        />

        <TextInput
          label="Phone number"
          type="tel"
          name="client.phone"
          placeholder="3125555555"
          size="sm"
          withAsterisk
          {...clientForm.getInputProps('phone')}
          value={clientForm.getTransformedValues().formattedPhone}
          onKeyDown={enforceFormat}
          onChange={onPhoneInput}
        />

        <TextInput
          className={classes.textInput}
          label="Email address"
          name="client.email"
          placeholder="jsmith@acme.com"
          type="email"
          size="sm"
          withAsterisk
          {...clientForm.getInputProps('email')}
        />
      </SimpleGrid>

      <Text mt="lg" mb="md" size="xs">By saving this client you are acknowledging you have permission to contact them at the phone number and/or email address you have provided and eConcretely has permission to contact them on your behalf. We will send them a unique link to access this ticket where they can review ticket details and sign if necessary.</Text>

      {errorMessage ? (
        <Flex mb="md">
          <IconAlertCircle size="1.5rem" color="red" />
          <Text fz="sm" ml="md" color="red">{errorMessage}</Text>
        </Flex>
      ) : null}

      <Flex
        mih={50}
        gap="md"
        justify="center"
        align="center"
        direction="row"
        wrap="wrap"
      >
        <UnstyledButton onClick={handleCancelAdd} disabled={processing}>
          Cancel
        </UnstyledButton>
        <Button onClick={handleSubmitAdd} disabled={processing}>
          {processing ? (
            <Loader color="blue" size="sm" />
          ) : saveLabel}
        </Button>
      </Flex>
    </Box>
  )
}
