
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useForm, zodResolver } from '@mantine/form'
import { notifications } from '@mantine/notifications'
import {
  CloseButton,
  Container,
  Select,
  SimpleGrid,
  TextInput,
  Text,
  Title,
  // Code,
  Stack,
  Tooltip,
  rem,
  // ActionIcon,
  Button,
  createStyles,
  Center,
  // NumberInput,
  Card,
  Autocomplete,
} from '@mantine/core'
import { randomId, useToggle } from '@mantine/hooks'
import { ZodError, z } from 'zod'
import { DynamicFormList } from './form/dynamic-form-list'
import { AggregateForm } from './form/aggregate-form'
import { AdmixtureForm } from './form/admixture-form'
import { getRightLabelProps } from './form/input-right-label'
import './index.scss'
// import mockValues from './mock.json'
import fetchService from '../../services/fetch.service'
import { StatusCodes } from 'http-status-codes'
import { IconCheck, IconExclamationCircle } from '@tabler/icons-react'
import { useLoaderData, useNavigate, useParams } from 'react-router-dom'
import { ActionLinkToDesigns } from './shared/action-link-to-designs'
// import viewClasses from './index.module.scss'
import browserStorageService, { STORAGE_KEY } from '../../services/browser-storage.service'

export const MAXIMUM_AGGREGATE_SAND = 1
export const MAXIMUM_AGGREGATE_STONE = 1
export const MAXIMUM_ADMIXTURES = 3
export const MAXIMUM_AGGREGATES = MAXIMUM_AGGREGATE_SAND + MAXIMUM_AGGREGATE_STONE

const useStyles = createStyles((theme) => ({
  button: {
    borderRadius: theme.radius.sm,
    padding: `${theme.spacing.sm} ${theme.spacing.lg}`,
    cursor: 'pointer',
    height: rem('3.25rem'),
    maxWidth: rem('13rem'),
  },
}));

export const ClearCementTotals = (props) => {
  const [isFocused, toggleFocus] = useToggle([false, true]);

  return (
    <Tooltip opened={isFocused} label="Clear Input" fz="sm">
      <CloseButton
        aria-label="Clear Input"
        onMouseEnter={toggleFocus}
        onMouseLeave={toggleFocus}
        onFocus={toggleFocus}
        onBlur={toggleFocus}
        onClick={props.handleClearCementTotals}
      />
    </Tooltip>
  )
}

const MixDesignSectionHeader = (props) => {
  const { title = 'Heading', children } = props

  return (
    <Title order={3} weight={300} mb="xs" mt="md" className="mix-designs-title">
      <span>{children || title}</span>
    </Title>
  )
}

export const cementTypes = [
  // PORTLAND
  { label: 'Type I', value: 'portland.type1', group: 'Portland' },
  { label: 'Type I (L)', value: 'portland.type1l', group: 'Portland' },
  { label: 'Type II', value: 'portland.type2', group: 'Portland' },
  { label: 'Type III', value: 'portland.type3', group: 'Portland' },
  { label: 'Type IV', value: 'portland.type4', group: 'Portland' },
  { label: 'Type V', value: 'portland.type5', group: 'Portland' },
  // SPECIALTIES
  { label: 'Calcium Aluminate', value: 'specialty.calciumAluminate', group: 'Speciality' },
  { label: 'Calcium Sulfoaluminate', value: 'specialty.calciumSulfoaluminate', group: 'Speciality' },
  { label: 'CTS Rapid Set', value: 'specialty.ctsRapidSet', group: 'Speciality' },
  // OTHER
  { label: 'Other (Write in)', value: 'other', group: 'Other' },
]

const errorMessages = {
  minCharacters: (label = '', minimum = 2) => `${label} should be at least ${minimum} characters. You can use A-Z, 0-9`
}

const mixMeta = z.object({
  name: z.string(),
  mixDesignNumber: z.string().min(2, { message: errorMessages.minCharacters('Mix Design Number', 2) }),
  producerNumber: z.string().min(2, { message: errorMessages.minCharacters('Producer Number', 2) }),
})

const mixCement = z.object({
  type: z.string().min(1, { message: 'You must select a cement type or choose "Other"' }),
  customType: z.string(),
  name: z.string().min(3, { message: errorMessages.minCharacters('Cement Name', 3) }),
  materialCode: z.string().min(3, { message: errorMessages.minCharacters('Cement Material Code', 3) }),
  mfrCode: z.string().min(3, { message: errorMessages.minCharacters('Cement Manufacturer Code', 3) }),
  weight: z.number().positive({ message: 'Cement weight should be greater than 0' }),
  bags: z.number().positive({ message: 'Total cement bags should be greater than 0' }),
})

const mixWater = z.object({
  total: z.coerce.number().nonnegative({ message: 'Total gallons of water should be >= 0' }),
  ratio: z.coerce.number().nonnegative({ message: 'Water:Cement ratio should be >= 0 and is usually a fraction (less than 1)'}),
}) 

const mixSpecialty = z.object({
  name: z.string().min(3, { message: errorMessages.minCharacters('Speciality Name', 3) }),
  materialCode: z.string().min(3, { message: errorMessages.minCharacters('Speciality Material Code', 3) }),
  mfrCode: z.string().min(3, { message: errorMessages.minCharacters('Speciality Manufacturer Code', 3) }),
  total: z.coerce.number().nonnegative({ message: 'Total per yard should be greater than 0' }),
})

// const schema = z.object({
//   meta: mixMeta.required(),
//   cement: mixCement.required({
//     type: true,
//     name: true,
//     materialCode: true,
//     mfrCode: true,
//     weight: true,
//     bags: true,
//   }),
//   water: mixWater.required(),
//   specialty: mixSpecialty.required(),
// })

const schema = z.object({
  meta: mixMeta.required(),
  cement: mixCement.required({
    type: true,
    name: true,
    materialCode: true,
    mfrCode: true,
    weight: true,
    bags: true,
  }),
  water: mixWater.required(),
  // specialty: mixSpecialty.optional(),
})
// .superRefine((data, ctx) => {
//   console.log('superRefine called', data.specialty);
//   if (data.specialty) {
//     if (data.specialty.name && data.specialty.name.trim() !== '') {
//       const result = mixSpecialty.safeParse(data.specialty);
//       if (!result.success) {
//         result.error.issues.forEach((issue) => {
//           ctx.addIssue({
//             code: z.ZodIssueCode.custom,
//             message: issue.message,
//             path: ['specialty', ...(issue.path || [])],
//           });
//         });
//       }
//     } else {
//       // If specialty exists but name is empty, consider it as if specialty wasn't provided
//       delete data.specialty;
//     }
//   }
// })

// .refine(
//   (data) => {
//     console.log('REFINE DATA', data)
//     // If specialty exists and has a non-empty name, it should be fully valid
//     if (data.specialty && data.specialty.name && data.specialty.name.trim() !== '') {
//       return mixSpecialty.safeParse(data.specialty).success;
//     }
//     // If specialty doesn't exist or has an empty name, it's valid
//     return true;
//   },
//   {
//     message: "Specialty must be fully filled out if a name is provided",
//     path: ["specialty"],
//   }
// )

// .superRefine((inputs, refinementContext) => {
//   console.log(`superRefine`, inputs)
//   if (inputs.cement.type === 'other' && !inputs.cement.customType.length) {
//     return refinementContext.addIssue({
//       code: z.ZodIssueCode.custom,
//       message: 'You must provide the custom cement type',
//       path: ['cement.customType'],
//       // fatal: true, // abort early
//     })
//     // return z.NEVER // abort early
//   }
// })

// .refine((inputs) => {
//   console.log(`refine`, inputs)
//   if (inputs.cement.type === 'other' && !inputs.cement.customType.length) {
//     console.log('OTHER type, return false with empty custom')
//     return false
//   }

//   return true
// })

export const calculateAddedSpecialtyRatio = (value = 0) => {
  return (0.48 * parseFloat(value)).toFixed(3)
}

export const transpileSavedDesign = (storedDesign = null) => {
  if (!storedDesign || storedDesign === undefined) return {}

  const aggregates = storedDesign.aggregates.map((a) => ({
    type: a.type.toLowerCase() || '',
    name: a.name || '',
    materialCode: a.materialCode || '',
    mfrCode: a.mfrCode || '',
    weight: a.weight || '',
    uid: a.uid || randomId(),
  }))

  const admixtures = storedDesign.admixtures.map((a) => ({
    type: a.type || '',
    name: a.name || '',
    materialCode: a.materialCode || '',
    mfrCode: a.mfrCode || '',
    dosageRate: a.dosageRate || '',
    uid: a.uid || randomId(),
  }))

  return {
    meta: {
      name: storedDesign.meta.name || '',
      mixDesignNumber: storedDesign.meta.mixDesignNumber || '',
      producerNumber: storedDesign.meta.producerNumber || '',
    },
    cement: {
      bags: storedDesign.cement.bags || '',
      weight: storedDesign.cement.total || '',
      name: storedDesign.cement.name || '',
      mfrCode: storedDesign.cement.mfrCode || storedDesign.cement.manufacturerCode || '',
      materialCode: storedDesign.cement.materialCode || '',
      type: storedDesign.cement.type || '',
      customType: storedDesign.cement.customType || '',
    },
    water: {
      total: storedDesign.water.gallons || '',
      ratio: storedDesign.water.cementRatio || '',
    },
    specialty: {
      name: storedDesign.specialty.name || '',
      materialCode: storedDesign.specialty.materialCode || '',
      mfrCode: storedDesign.specialty.mfrCode || '',
      total: storedDesign.specialty.total || '',
    },
    aggregates,
    admixtures,
  }
}

/**
 * TODO - Autosave draft
 * @returns 
 */

export const CreateMixDesignScreen = (props) => {
  const { title } = props
  const loadedDesign = useLoaderData()
  const { classes } = useStyles()
  const navigate = useNavigate()
  const mixDesignForm = useForm({
    // validateInputOnBlur: true,
    validate: zodResolver(schema),
    initialValues: {
      meta: {
        mixDesignNumber: '',
        producerNumber: '',
      },
      cement: {
        type: '',
        customType: '',
        name: '',
        materialCode: '',
        mfrCode: '',
        weight: '',
        bags: '',
      },
      water: {
        total: '',
        ratio: '',
      },
      specialty: {
        name: '',
        materialCode: '',
        mfrCode: '',
        total: '',
      },
      aggregates: [],
      admixtures: [],
      // ...loadedDesign,
      // ...mockValues,
    },
  })

  const { getInputProps } = mixDesignForm,
    isCustomCementType = mixDesignForm.values.cement.type === 'other',
    [cementWeightLocked, setCementWeightLocked] = useState(false),
    [cementBagsLocked, setCementBagsLocked] = useState(false),
    [specialtyTotalAdded, setSpecialtyTotalAdded] = useState(false),
    [aggregateError, setAggregateError] = useState(''),
    [admixtureError, setAdmixtureError] = useState(''),
    [showDebug, toggleDebug] = useToggle([false, true]),
    [submitting, setSubmitting] = useState(false),
    [usedCements, setUsedCements] = useState([]),
    numericRegex = /^([\d.-]+)$/i,
    notificationId = 'mix-design',
    params = useParams(),
    importedDesign = useMemo(() => {
      return (loadedDesign !== null && loadedDesign !== undefined) && !!params.designId
    }, [loadedDesign, params])

  useEffect(() => {
    if (importedDesign) {
      const importedDesign = transpileSavedDesign(loadedDesign)
      mixDesignForm.setValues(importedDesign)
    }

    return () => {
      console.log('unload')
      mixDesignForm.reset()
    }
  }, [importedDesign, loadedDesign])

  // load previous cements
  useEffect(() => {
    const fetchPreviousCements = async (companyId) => {
      return await fetchService.get(`/auth/companies/${companyId}/cements`)
    }

    browserStorageService.getItem(STORAGE_KEY.SESSION)
      .then((session) => {
        fetchPreviousCements(session.company.companyId || session.refs.company)
          .then(({ result }) => {
            const cements = result?.cements || []
            setUsedCements(cements)
          })
          .catch((err) => {
            console.warn('Unable to load previous cements', err)
          })
      })
  }, [])

  // set focus to custom cement type when mounted
  const customTypeRefCb = useCallback((node) => {
    node?.focus()
  }, [])

  const cementTotalRounder = (value) => {
    return parseFloat(value.toFixed(3))
  }

  const handleCementWeightChange = useCallback((evt) => {
    if (evt?.target?.value === '' || Number(evt?.target?.value) < 0) {
      mixDesignForm.setFieldValue('cement.weight', '')
      mixDesignForm.setFieldValue('cement.bags', '')
      setCementBagsLocked(false)
      return
    } else if (numericRegex.test(evt?.target?.value || '') === false) {
      return
    }
  
    const value = Number(evt.target.value)
    if ((!cementWeightLocked && value >= 0)) {
      mixDesignForm.setFieldValue('cement.weight', value)
      setCementBagsLocked(true)
      if (value > 0) mixDesignForm.setFieldValue('cement.bags', cementTotalRounder(value / 94))
      else mixDesignForm.setFieldValue('cement.bags', 0)
    }
  }, [])

  const handleCementBagsChange = useCallback((evt) => {
    if (evt?.target?.value === '' || Number(evt?.target?.value) < 0) {
      mixDesignForm.setFieldValue('cement.weight', '')
      mixDesignForm.setFieldValue('cement.bags', '')
      setCementWeightLocked(false)
      return
    } else if (numericRegex.test(evt?.target?.value || '') === false) {
      return
    }
  
    const value = Number(evt.target.value)
    if ((!cementBagsLocked && value >= 0)) {
      mixDesignForm.setFieldValue('cement.bags', value)
      setCementWeightLocked(true)
      if (value > 0) {
        mixDesignForm.setFieldValue('cement.weight', cementTotalRounder(value * 94))
      } else {
        mixDesignForm.setFieldValue('cement.weight', 0)
      }
    }
  }, [])

  const handleClearCementTotals = (evt) => {
    setCementWeightLocked(false)
    setCementBagsLocked(false)
    mixDesignForm.setFieldValue('cement.weight', '')
    mixDesignForm.setFieldValue('cement.bags', '')
  }

  const handleCementSelection = (item) => {
    const cement = item
      ? usedCements.find(({ value }) => value === item.value)
      : null
    if (!cement) return

    mixDesignForm.setFieldValue('cement.materialCode', cement.extra.materialCd)
    mixDesignForm.setFieldValue('cement.mfrCode', cement.extra.mfrCd)
  }

  const waterTotalEmpty = useMemo(() => {
    return mixDesignForm.values.water.total === ''
  }, [mixDesignForm.values.water.total])


  const validateDesignSpecialty = (inputs) => {
    const hasSpecialtyInput = Object.values(inputs).some(val => 
      typeof val === 'string' && val.trim() !== ''
    )

    if (!hasSpecialtyInput) return

    try {
      mixSpecialty.parse(inputs)
    } catch (err) {
      if (!(err instanceof ZodError)) {
        console.error('Failed parsing speciality input')
        return null
      }

      const errors = {}
      err.errors.forEach((zErr) => {
        errors[`specialty.${zErr.path.join('.')}`] = zErr.message // @TODO rename to speciality typo
      })

      return errors
    }

    return null
  }

  const validateCement = (inputs) => {
    if (inputs.type !== 'other') return null
    if (inputs.customType.trim().length) return null
  
    const errors = {}
    errors['cement.customType'] = 'You must provide the custom cement type'

    return errors
  }

  const validateMixDesign = () => {
    const reduceAggregateTypes = (counts, aggregate) => {
      if (counts[aggregate.type]) counts[aggregate.type]++
      else counts[aggregate.type] = 1
      return counts
    }

    setAggregateError('')
    setAdmixtureError('')

    let hasOtherError = false
    mixDesignForm.validate()
  
    const cementErrors = validateCement(mixDesignForm.values.cement)
    const specialityErrors = validateDesignSpecialty(mixDesignForm.values.specialty)
  
    if (cementErrors || specialityErrors) {
      hasOtherError = true
      const otherErrors = {
        ...(cementErrors ? cementErrors : {}),
        ...(specialityErrors ? specialityErrors : {}),
      }

      Object.entries(otherErrors).forEach(([field, error]) => {
        mixDesignForm.setFieldError(field, error)
      })
    }

    const { sand: aggregateSandTotal, stone: aggregateStoneTotal } = mixDesignForm.values.aggregates.reduce(reduceAggregateTypes, {})

    if (aggregateSandTotal > MAXIMUM_AGGREGATE_SAND) {
      hasOtherError = true
      setAggregateError('You can only provide a maximum of one (1) SAND Aggregate per Mix Design')
    } else if (aggregateStoneTotal > MAXIMUM_AGGREGATE_STONE) {
      hasOtherError = true
      setAggregateError('You can only provide a maximum of one (1) STONE Aggregate per Mix Design')
    }

    if (mixDesignForm.values.admixtures.length > MAXIMUM_ADMIXTURES) {
      hasOtherError = true
      setAdmixtureError('You can only provide a maximum of three (3) Admixtures per Mix Design')
    }

    return mixDesignForm.isValid() && !hasOtherError
  }

  const uploadMixDesign = async ({ status = '' }) => {
    const { values: mixDesignSpec } = mixDesignForm

    try {
      const body = { design: mixDesignSpec, status }
      const dynamicRequest = importedDesign
        ? fetchService.patch(`/auth/design/${params.designId}`, { body })
        : fetchService.post('/auth/design', { body })
      const response = await dynamicRequest

      if (response.status === StatusCodes.OK) {
        const mixDesignNumber = response?.result?.design?.meta?.mixDesignNumber || mixDesignSpec?.meta?.mixDesignNumber || '<MIX DESIGN NUMBER>'
        if (notificationId && status === 'draft') {
          notifications.update({
            id: notificationId,
            color: 'teal',
            title: 'Mix Design Saved',
            message: `Your Mix Design '${mixDesignNumber}' has been saved as a DRAFT. To configure it for your fleet you will need to publish it.`,
            icon: <IconCheck style={{ width: rem(18), height: rem(18) }} />,
            loading: false,
          })
        } else if (notificationId && status === 'published') {
          notifications.update({
            id: notificationId,
            color: 'teal',
            title: 'Mix Design Published',
            message: `Your Mix Design '${mixDesignNumber}' has been PUBLISHED. It is now available to be configured for your fleet.'`,
            icon: <IconCheck style={{ width: rem(18), height: rem(18) }} />,
            loading: false,
          })
        }

        navigate('/dashboard/designs')
      }
    } catch (err) {
      console.warn('Failed to upload mix design', err)
      setSubmitting(false)
      notifications.update({
        id: notificationId,
        color: 'red',
        title: 'Unable to upload Mix Design',
        message: 'Please try to upload your Mix Design again. If this continues, please contact support.',
        icon: <IconExclamationCircle style={{ width: rem(18), height: rem(18) }} />,
        loading: false,
        autoClose: false,
      })
    }
  }

  const saveMixDesign = () => {
    if (submitting) return

    setSubmitting(true)

    notifications.show({
      id: notificationId,
      loading: true,
      title: 'Saving Mix Design',
      autoClose: false,
      withCloseButton: false,
    })

    uploadMixDesign({ status: 'draft' })
  }

  const publishMixDesign = () => {
    if (submitting) return

    setSubmitting(true)
    const valid = validateMixDesign()
    if (!valid) {
      notifications.show({
        id: notificationId,
        title: 'Unable to publish Mix Design',
        message: 'Please correct any identified errors. You could instead save this mix design as a DRAFT to complete at a later time.',
        autoClose: false,
      })

      console.warn('Unable to submit form', mixDesignForm.errors)
      setSubmitting(false)
      return
    }

    notifications.show({
      id: notificationId,
      loading: true,
      title: 'Publishing Mix Design',
      autoClose: false,
      withCloseButton: false,
    })

    uploadMixDesign({ status: 'published' })
  }

  useEffect(() => {
    if (mixDesignForm.values.cement.type === 'other') {
      mixDesignForm.clearFieldError('cement.customType')
    }
  }, [mixDesignForm.values.cement.type])

  useEffect(() => {
    setSpecialtyTotalAdded(parseFloat(mixDesignForm.values.specialty.total) > 0)
  }, [mixDesignForm.values.specialty.total])

  return (
    <Container my="md">
      <Title order={2} weight={500} mt="xl" mb="lg">{loadedDesign ? 'Edit' : 'Create'} Mix Designs</Title>

      {loadedDesign ? <ActionLinkToDesigns /> : null}

      <Card withBorder padding="lg" radius="md">
        <MixDesignSectionHeader>Design Identification</MixDesignSectionHeader>

        <SimpleGrid
          cols={3}
          spacing="md"
          breakpoints={[
            { maxWidth: '52rem', cols: 1, spacing: 'sm' },
            { maxWidth: '72rem', cols: 3, spacing: 'sm' },
            { minWidth: '92rem', cols: 3, spacing: 'md' },
          ]}
        >
          <TextInput
            label="Custom Name"
            name="name"
            {...getInputProps('meta.name')}
          />
          <TextInput
            label="Mix Design Number"
            name="mixDesignNumber"
            {...getInputProps('meta.mixDesignNumber')}
          />
          <TextInput
            label="Producer Number"
            name="producerNumber"
            {...getInputProps('meta.producerNumber')}
          />
        </SimpleGrid>

        <MixDesignSectionHeader>Cement</MixDesignSectionHeader>
        <SimpleGrid
          cols={3}
          spacing="md"
          breakpoints={[
            { maxWidth: '52rem', cols: 1, spacing: 'sm' },
            { maxWidth: '72rem', cols: 3, spacing: 'sm' },
            { minWidth: '92rem', cols: 3, spacing: 'md' },
          ]}
        >
          <Autocomplete
            label="Name"
            name="cementName"
            placeholder={usedCements.length ? 'Type for recent cements' : ''}
            data={usedCements.length ? usedCements.map(({ value }) => value) : []}
            withAsterisk
            limit={7}
            onItemSubmit={handleCementSelection}
            {...getInputProps('cement.name')}
          />
  
          <TextInput
            label="Material Code"
            name="cementMaterialCode"
            {...getInputProps('cement.materialCode')}
          />
          <TextInput
            label="Manufacturer Code"
            name="cementMfrCode"
            {...getInputProps('cement.mfrCode')}
          />
          <Stack>
            <TextInput
              label="Total Weight"
              placeholder="Lbs per yard"
              name="cementTotalLbs"
              rightSection={<ClearCementTotals handleClearCementTotals={handleClearCementTotals} />}
              disabled={cementWeightLocked}
              {...getInputProps('cement.weight')}
              type="number"
              onChange={handleCementWeightChange}
            />
            {cementWeightLocked ? (
              <Text mt={rem(-10)} pl={rem(5)} pr={rem(5)} fz="xs" c="dimmed" ta="justify">
                Total weight calculated at 94lb per bag. To set weight, clear bags input.
              </Text>
            ) : null}
          </Stack>
          <Stack>
            <TextInput
              label="Total Bags"
              placeholder="Bags per yard"
              name="cementTotalBags"
              rightSection={<ClearCementTotals handleClearCementTotals={handleClearCementTotals} />}
              disabled={cementBagsLocked}
              {...getInputProps('cement.bags')}
              type="number"
              onChange={handleCementBagsChange}
            />
            {cementBagsLocked ? (
              <Text mt={rem(-10)} pl={rem(5)} pr={rem(5)} fz="xs" c="dimmed" ta="justify">
                Total bags calculated from 94lb per bag. To set bags, clear weight input.
              </Text>
            ) : null}
          </Stack>
          <Stack>
            <Select
              name="cementType"
              label="Type"
              placeholder="Select Type"
              searchable
              nothingFound="No options, select 'Other' and write one in"
              data={cementTypes}
              {...getInputProps('cement.type')}
            />
            {isCustomCementType ? (
              <TextInput
                mt={rem(-10)}
                label="Custom Type"
                name="cementCustomType"
                ref={customTypeRefCb}
                {...getInputProps('cement.customType')}
              />
            ) : null}
          </Stack>
          
        </SimpleGrid>

        <MixDesignSectionHeader>Water</MixDesignSectionHeader>
        <SimpleGrid
          cols={2}
          spacing="md"
          breakpoints={[
            { maxWidth: '52rem', cols: 1, spacing: 'sm' },
            { maxWidth: '72rem', cols: 2, spacing: 'sm' },
            { minWidth: '92rem', cols: 2, spacing: 'md' },
          ]}
        >
          <TextInput
            label="Total"
            placeholder="Gallons per yard"
            name="waterTotal"
            {...getInputProps('water.total')}
            type="number"
            step=".1"
            {...(waterTotalEmpty ? {} : getRightLabelProps({ label: 'gal / yd', width: 60 }))}
          />
          <Stack>
            <TextInput
              label="Theoretical Water Cement Ratio"
              name="waterCementRatio"
              type="number"
              {...getInputProps('water.ratio')}
            />
            {specialtyTotalAdded ? (
              <Text mt={rem(-10)} pl={rem(5)} pr={rem(5)} fz="xs" c="dimmed" ta="justify">
                An additional {calculateAddedSpecialtyRatio(mixDesignForm.values.specialty.total)} will be added to the ticket for the Water:Cement ratio
              </Text>
            ) : null}
          </Stack>
        </SimpleGrid>

        <MixDesignSectionHeader>Aggregates</MixDesignSectionHeader>
        {aggregateError ? (
          <Text mt={rem(10)} mb={rem(10)} fz="sm" c="red">
            {aggregateError}
          </Text>
        ) : null}
        <SimpleGrid
          cols={3}
          spacing="md"
          breakpoints={[
            { maxWidth: '52rem', cols: 1, spacing: 'sm' },
            { maxWidth: '72rem', cols: 2, spacing: 'sm' },
            { minWidth: '92rem', cols: 3, spacing: 'md' },
          ]}
        >
          <DynamicFormList
            form={mixDesignForm}
            fieldKey="aggregates"
            addNewLabel="Add Aggregate"
            dynamicFormComponent={AggregateForm}
            maxItems={MAXIMUM_AGGREGATES}
          />
        </SimpleGrid>

        <MixDesignSectionHeader>Admixtures</MixDesignSectionHeader>
        <SimpleGrid
          cols={3}
          spacing="md"
          breakpoints={[
            { maxWidth: '52rem', cols: 1, spacing: 'sm' },
            { maxWidth: '72rem', cols: 2, spacing: 'sm' },
            { minWidth: '92rem', cols: 3, spacing: 'md' },
          ]}
        >
          <DynamicFormList
            form={mixDesignForm}
            fieldKey="admixtures"
            addNewLabel="Add Admixture"
            dynamicFormComponent={AdmixtureForm}
            maxItems={MAXIMUM_ADMIXTURES}
          />
        </SimpleGrid>

        <MixDesignSectionHeader>Latex / Speciality</MixDesignSectionHeader>
        <SimpleGrid
          cols={3}
          spacing="md"
          breakpoints={[
            { maxWidth: '52rem', cols: 1, spacing: 'sm' },
            { maxWidth: '72rem', cols: 3, spacing: 'sm' },
            { minWidth: '92rem', cols: 3, spacing: 'md' },
          ]}
        >
          <TextInput
            label="Name"
            name="specialtyName"
            {...getInputProps('specialty.name')}
          />
          <TextInput
            label="Material Code"
            name="specialtyMaterialCode"
            {...getInputProps('specialty.materialCode')}
          />
          <TextInput
            label="Manufacturer Code"
            name="specialtyMfrCode"
            {...getInputProps('specialty.mfrCode')}
          />
          <Stack>
            <TextInput
              label="Total"
              name="specialtyMaterialCode"
              type="number"
              placeholder="Total per yard (42.10)"
              {...getInputProps('specialty.total')}
            />
            <Text mt={rem(-10)} pl={rem(5)} pr={rem(5)} fz="xs" c="dimmed" ta="justify">
              Providing a value here will only adjust the Water:Cement ratio when a ticket is being created
            </Text>
          </Stack>
        </SimpleGrid>

        <SimpleGrid
          mt={rem(50)}
          cols={2}
          spacing="md"
          breakpoints={[
            { maxWidth: '52rem', cols: 1, spacing: 'sm' },
            { maxWidth: '72rem', cols: 2, spacing: 'sm' },
            { minWidth: '92rem', cols: 2, spacing: 'md' },
          ]}
        >
          <Center>
            <Button className={classes.button} radius="sm" size="sm" variant="outline" onClick={saveMixDesign}>
              Save Draft
            </Button>
          </Center>

          <Center>
            <Button className={classes.button} radius="sm" size="sm" onClick={publishMixDesign}>
              Publish Mix Design
            </Button>
          </Center>
        </SimpleGrid>
      </Card>
    </Container>
  );
}
