import { Button, Loader, createStyles, rem } from "@mantine/core"

const useStyles = createStyles((theme) => ({
  button: {
    borderRadius: theme.radius.sm,
    padding: `${theme.spacing.sm} ${theme.spacing.lg}`,
    cursor: 'pointer',
    height: rem('3.25rem'),
    maxWidth: rem('13rem'),
  },
}))

export const DraftButton = (props) => {
  const { classes } = useStyles()
  const {
    onClick = () => {},
    disabled = false,
    submitting = false,
    text = null,
    children,
  } = props

  return (
    <Button
      className={classes.button}
      onClick={onClick}
      disabled={disabled}
      variant="outline"
      radius="sm"
      size="sm"
    >
      {submitting ? (
        <Loader color="blue" size="sm" />
      ) : text || children}
    </Button>
  )
}
