import { useEffect, useState } from 'react'
import {
  Button,
  Modal,
  Text,
  UnstyledButton,
  Loader,
  Flex,
} from '@mantine/core'
import {
  IconAlertCircle,
  IconFileDescription,
} from '@tabler/icons-react'

import { noop } from '../../../utils/noop'
import { UserInfoCard } from '../../../common/user-info-card'
import { deleteClientFromCompany, fetchClient } from '../../tickets/modals/share-modal/actions'

export const DeleteClientModal = (props) => {
  const {
    onClose = noop,
    onSuccess = noop,
    target = null,
    company,
  } = props

  const [isActive, setActive] = useState(false),
    [processing, setProcessing] = useState(false),
    [sharedTickets, setSharedTickets] = useState([]),
    [errorMessage, setErrorMessage] = useState('')

  const handleClose = (success = false) => {
    setActive(false)
    setProcessing(false)
    
    if (success) {
      onSuccess(target)
    }
    onClose()
  }

  const handleDelete = async () => {
    setProcessing(true)
    setErrorMessage('')

    try {
      const response = await deleteClientFromCompany(target, company)
      if (!response.ok) {
        throw new Error('Failed to revoke access')
      }

      handleClose(true)
    } catch (err) {
      setErrorMessage('We hit a snag deleting this client. Please try again or contact support.')
      setProcessing(false)
    }
  }

  useEffect(() => {
    setActive(!!target)
  }, [target])

  useEffect(() => {
    if (target) {
      fetchClient(target, company)
        .then((response) => {
          if (!response.ok) {
            setErrorMessage('We were unable to load details about this client. Please refresh this page and try again.')
            setSharedTickets([])
          } else {
            setSharedTickets(response.shared)
          }
        })
    }
  }, [target])

  return (
    <Modal opened={isActive} onClose={handleClose} title="Confirm Delete Request">
      <Text fz="sm">Are you sure you wish to delete this client? Any access they have to current tickets will immediately be removed and you will need to reinvite them again.</Text>

      <UserInfoCard
        mt="md"
        person={target}
        type="client"
        meta={[
          {
            Icon: IconFileDescription,
            label: sharedTickets && sharedTickets.length
              ? `Has access to ${sharedTickets.length} ticket${sharedTickets.length > 1 ? 's' : ''}`
              : 'No tickets shared yet'
          }
        ]}
      />

      {errorMessage ? (
        <Flex mb="md">
          <IconAlertCircle size="1.5rem" color="red" />
          <Text fz="sm" ml="md" color="red">{errorMessage}</Text>
        </Flex>
      ) : null}

      <Flex
        mt="lg"
        mih={50}
        gap="md"
        justify="center"
        align="center"
        direction="row"
        wrap="wrap"
      >
        <UnstyledButton onClick={() => handleClose()} disabled={processing}>
          Cancel
        </UnstyledButton>
        <Button onClick={handleDelete} disabled={processing} color="red">
          {processing ? (
            <Loader color="white" size="sm" />
          ) : 'CONFIRM DELETE'}
        </Button>
      </Flex>
    </Modal>
  )
}
