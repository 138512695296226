import localforage from 'localforage'

export const STORAGE_KEY = {
  ROLE: 'role',
  SESSION: 'session',
  SETTINGS: 'settings',
}

export class BrowserStorageService {
  userId
  initialized = false
  sessionStarted = false

  initialize = (userId) => {
    this.userId = String(userId)
    this.initialized = true
    this.sessionStarted = false
    this.lastUpdated = -1
  }

  _getInstanceName = () => {
    const hostname = window.location.hostname
    //   isValid = hostname.toLowerCase().match(/.*\.website\.com/gm) || true // todo: cleanup
    
    // if (!isValid) throw new Error(`Invalid origin ${hostname}`)
    return 'local' //hostname.split('.')[0]
  }

  createSession = async (user, additionalSettings) => {
    await browserStorageService.setItem(STORAGE_KEY.ROLE, user.role)
    await browserStorageService.setItem(STORAGE_KEY.SESSION, user)
    await browserStorageService.setItem(STORAGE_KEY.SETTINGS, additionalSettings)
    this.sessionStarted = true
    this.lastUpdated = Date.now()
  }

  updateSession = async (user, additionalSettings) => {
    await browserStorageService.setItem(STORAGE_KEY.ROLE, user.role)
    await browserStorageService.setItem(STORAGE_KEY.SESSION, user)
    await browserStorageService.setItem(STORAGE_KEY.SETTINGS, additionalSettings)
    this.lastUpdated = Date.now()
  }

  clean = async () => {
    await localforage.clear()
  }

  setItem = async (key, value) => {
    if (this.userId === null) throw new Error('BrowserStorageService not initialized')

    const instanceName = this._getInstanceName()
    try {
      const allInstancesDataForUser = await localforage.getItem(this.userId),
        instanceDataForUser = allInstancesDataForUser && allInstancesDataForUser[instanceName]
          ? allInstancesDataForUser[instanceName]
          : {}
      
      await localforage.setItem(this.userId, {
        ...allInstancesDataForUser,
        [instanceName]: {
          ...instanceDataForUser,
          [key]: value,
        },
      })
    } catch (err) {
      err.message = `Failed to store ${key} locally -- ${err.message}`
      throw err
    }
  }

  getItem = async (key, value) => {
    if (this.userId === null) throw new Error('BrowserStorageService not initialized')

    const instanceName = this._getInstanceName()
    try {
      const allInstancesDataForUser = await localforage.getItem(this.userId),
        instanceDataForUser = allInstancesDataForUser && allInstancesDataForUser[instanceName]
          ? allInstancesDataForUser[instanceName]
          : {},
        requestedData = instanceDataForUser && instanceDataForUser[key]

      return requestedData === null ? null : requestedData
    } catch (err) {
      err.message = `Failed to get ${key} local indexedDB -- ${err.message}`
      throw err
    }
  }

  removeItem = async (key) => {
    if (this.userId === null) throw new Error('BrowserStorageService not initialized')

    const instanceName = this._getInstanceName()
    try {
      const allInstancesDataForUser = await localforage.getItem(this.userId),
        instanceDataForUser = allInstancesDataForUser[instanceName] ?? null
      
      if (instanceDataForUser && instanceDataForUser[key] !== null) {
        delete instanceDataForUser[key]
        await localforage.setItem(this.userId, {
          ...allInstancesDataForUser,
          [instanceName]: {
            ...instanceDataForUser,
          },
        })
      }
    } catch (err) {
      err.message = `Failed to get ${key} local indexedDB -- ${err.message}`
      throw err
    }
  }
}

const browserStorageService = new BrowserStorageService()
export default browserStorageService
