import dayjs from "dayjs"

export const ROLES = {
  SUPER: 'SUPER',
  ADMIN: 'ADMIN',
  OWNER: 'OWNER',
  DRIVER: 'DRIVER',
  OPERATOR: 'OPERATOR',
  SYSTEM: 'SYSTEM',
  DEFAULT: 'USER',
}

export const MODIFY_ROLES = [
  ROLES.SUPER,
  ROLES.ADMIN,
  ROLES.OWNER,
]

export const USER_ROLE_BADGE = {
  SUPER:      { label: 'Super', color: 'pink' },
  ADMIN:      { label: 'Admin', color: 'orange' },
  OWNER:      { label: 'Owner', color: 'blue' },
  DRIVER:     { label: 'Driver', color: 'cyan' },
  OPERATOR:   { label: 'Operator', color: 'cyan' },
  SYSTEM:     { label: 'System', color: 'gray' },
  DEFAULT:    { label: 'Default', color: 'red' },
  BLOCKED:    { label: 'Blocked', color: 'red' },
}

export const MAX_AUTH_LENGTH = 12
export const MIN_AUTH_LENGTH = 6

export const userCanModify = (role) => MODIFY_ROLES.includes(role)
export const formatRole = (role) => `${role[0]}${role.substring(1).toLowerCase()}`
export const formatDate = (dateStr) => dayjs(dateStr).format('MMM D, YYYY')
