import { Badge } from '@mantine/core'
import { TICKET_STATUS_BADGE } from '../helper'

export const TicketStatusBadge = (props) => {
  const { ticket } = props,
    status = ticket.status || ticket.meta.status,
    { label: badgeLabel, color: badgeColor } = TICKET_STATUS_BADGE[status || ''] || {}

  // console.log(props.ticket, TICKET_STATUS_BADGE)
  return (
    <Badge mr="sm" variant="filled" color={badgeColor || 'black'}>{badgeLabel || '---'}</Badge>
  )
}
