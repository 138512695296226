import { Title, createStyles } from '@mantine/core'

const useStyles = createStyles((theme) => ({
  title: {
    position: 'relative',

    '&::before': {
      content: '""',
      width: '100%',
      height: '1px',
      backgroundColor: '#ece',
      top: '50%',
      position: 'absolute',
    },
  },

  'title__span': {
    backgroundColor: '#fff',
    position: 'relative',
    paddingRight: '1rem',
    display: 'inline-flex',
    alignItems: 'center',

    '> *': {
      marginLeft: theme.spacing.xs,
    },
  },
}))

export const FormHeader = (props) => {
  const { title = 'Heading', children } = props,
    { classes } = useStyles()

  return (
    <Title order={3} weight={300} mb="xs" mt="md" className={classes.title}>
      <span className={classes['title__span']}>{children || title}</span>
    </Title>
  )
}
