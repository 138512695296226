
import { useEffect, useMemo, useState } from 'react'
import { notifications } from '@mantine/notifications'
import {
  Container,
  SimpleGrid,
  Text,
  Title,
  rem,
  ActionIcon,
  createStyles,
  Card,
  Badge,
  Flex,
  Menu,
  SegmentedControl,
  Box,
} from '@mantine/core'
import { randomId, useHover } from '@mantine/hooks'
import {
  IconDotsVertical,
  IconTrash,
  IconPencil,
  IconExternalLink,
  IconX,
  IconCheck,
  IconPlus, } from '@tabler/icons-react'
import fetchService from '../../services/fetch.service'
import { useNavigate } from 'react-router-dom'
import viewClasses from './index.module.scss'
import { LoadingData } from '../../common/loading-data'

const useStyles = createStyles((theme) => ({
  card: {
    overflow: 'inherit',
  },
  button: {
    borderRadius: theme.radius.sm,
    padding: `${theme.spacing.sm} ${theme.spacing.lg}`,
    cursor: 'pointer',
    height: rem('3.25rem'),
    maxWidth: rem('13rem'),
  },
}));

const ManageDesignMenu = (props) => {
  const {
    children,
    design = {},
    onDelete,
    onView,
    onEdit,
  } = props

  const isDraft = design.status !== 'PUBLISHED',
    iconSize = 16

  return (
    <Menu shadow="md" width={200}>
      <Menu.Target>{children}</Menu.Target>
      <Menu.Dropdown>
        <Menu.Label>{design.meta.mixDesignNumber}</Menu.Label>
        <Menu.Item
          onClick={() => onView(design)}
          icon={<IconExternalLink size={iconSize} />}
        >
          View Design
        </Menu.Item>
        {isDraft ? (
          <Menu.Item
            onClick={() => onEdit(design)}
            icon={<IconPencil size={iconSize}/>}
          >
            Edit Design
          </Menu.Item>
        ) : null}
        <Menu.Item
          color="red"
          onClick={() => onDelete(design)}
          icon={<IconTrash size={iconSize} />}
        >
          Delete Mix Design
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  )
}

const CreateCard = (props) => {
  const { onClick } = props
  const { hovered, ref } = useHover()

  return (
    <Card
      withBorder
      component="button"
      onClick={onClick}
      shadow={hovered ? 'sm' : 'xs'}
      ref={ref}
      className={viewClasses.createCardBtn}
    >
      <Box className={viewClasses.createCard}>
        <IconPlus size="1.125rem" />
        <Text size="md" ml="sm">Create Design</Text>
      </Box>
    </Card>
  )
}

export const ManageMixDesignsView = () => {
  const navigate = useNavigate()
  const [loaded, setLoaded] = useState(false)
  const [designs, setDesigns] = useState([])
  const [filteredDesigns, setFilteredDesigns] = useState([])
  const { classes } = useStyles()
  const [filteredStatus, setFilteredStatus] = useState('')
  const notificationId = {
    delete: 'manage-design-delete',
  }

  const removeDesignFromView = (design) => {
    const index = designs.findIndex((d) => {
      return d.__key === design.__key
    })

    designs.splice(index, 1)
    setDesigns([...designs])
  }

  const handleView = (design) => {
    navigate(`/dashboard/design/${design.id}`)
  }

  const handleEdit = (design) => {
    navigate(`/dashboard/design/${design.id}/edit`)
  }

  const handleDelete = async (design) => {
    notifications.show({
      id: `${notificationId.delete}.${design.id}`,
      loading: true,
      title: 'Deleting Mix Design',
      autoClose: false,
      withCloseButton: false,
    })

    await fetchService.delete(`/auth/design/${design.id}`)
      .then(({ status, result }) => {
        notifications.update({
          id: `${notificationId.delete}.${design.id}`,
          color: 'teal',
          title: 'Mix Design Deleted',
          message: `Your Mix Design '${design.meta.mixDesignNumber}' has been DELETED.`,
          icon: <IconCheck style={{ width: rem(18), height: rem(18) }} />,
          loading: false,
        })

        removeDesignFromView(design)
      })
      .catch((err) => {
        console.warn('Unable to delete mix design', err)
        const errorMessage = (err?.response?.status === 404)
          ? `The Mix Design '${design.meta.mixDesignNumber}' was not found and could not be deleted.`
          : `We ran into an issue trying to delete '${design.meta.mixDesignNumber}' you can try again later or contact support.${err?.response?.data?.error?.message ? `[${err.response.data.error.message}]` : ''}`

        notifications.update({
          id: `${notificationId.delete}.${design.id}`,
          color: 'red',
          title: 'Unable to delete Mix Design',
          message: errorMessage,
          icon: <IconX style={{ width: rem(18), height: rem(18) }} />,
          loading: false,
        })
      })
      .finally(() => {
      })
  }

  const handleCreate = async () => {
    navigate(`/dashboard/design/create`)
  }

  useEffect(() => {
    const fetchDesigns = async () => {
      return await fetchService.get('/auth/designs')
    }

    fetchDesigns()
      .then(({ status, result }) => {
        const designs = result?.designs || []
        setDesigns(designs)
      })
      .catch((err) => {
        console.warn('Unable to fetch mix designs', err)
      })
      .finally(() => {
        setLoaded(true)
      })
  }, [])

  useEffect(() => {
    if (!filteredStatus) {
      setFilteredDesigns(designs)
    } else {
      setFilteredDesigns(
        designs.filter((design) => design.status === filteredStatus.toUpperCase())
      )
    }
  }, [designs, filteredStatus])

  const designCards = useMemo(() => {
    return filteredDesigns.map((design) => {
      return (
        <Card key={randomId()} className={classes.card} withBorder padding="lg" radius="md">
          <Flex justify="flex-end" align="center">
            <Badge mr="sm" color={design.status === 'PUBLISHED' ? 'green' : 'orange'}>{design.status}</Badge>
            <ManageDesignMenu design={design} onDelete={handleDelete} onView={handleView} onEdit={handleEdit}>
              <ActionIcon variant="transparent" color="blue" radius="xl" size="sm" aria-label="Options">
                <IconDotsVertical />
              </ActionIcon>
            </ManageDesignMenu>
          </Flex>

          <Text fz="md" fw={500} mt="sm" >
            <span style={{ cursor: 'pointer' }} onClick={() => handleView(design)}>
              {design.meta.name || design.meta.mixDesignNumber || 'MISSING_NAME'}
            </span>
          </Text>

          <Text c="dimmed" fz="sm" mt="md">
            {design.status === 'PUBLISHED' ? (
              `Published on ${design.createdAt}`
            ) : (
              `Draft saved on ${design.updatedAt}`
            )}
          </Text>
        </Card>
      )
    })
  }, [filteredDesigns])

  return (
    <Container p="0">
      <Title order={2} weight={500} mt="xl" mb="lg">Manage Mix Designs</Title>

      <SegmentedControl
        mb="sm"
        value={filteredStatus}
        onChange={setFilteredStatus}
        data={[
          { label: 'All', value: '' },
          { label: 'Draft', value: 'draft' },
          { label: 'Published', value: 'published' },
        ]}
      />

      {!loaded ? (
        <LoadingData />
      ) : null}

      <SimpleGrid
        cols={3}
        spacing="md"
        breakpoints={[
          { maxWidth: '52rem', cols: 1, spacing: 'sm' },
          { maxWidth: '72rem', cols: 2, spacing: 'sm' },
          { minWidth: '92rem', cols: 3, spacing: 'md' },
        ]}
      >
        {designCards}
        <CreateCard onClick={handleCreate} />
      </SimpleGrid>
    </Container>
  );
}
