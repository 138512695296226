
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { z } from 'zod'
import { useForm, zodResolver } from '@mantine/form'
import {
  Container,
  SimpleGrid,
  TextInput,
  Title,
  Text,
  rem,
  createStyles,
  Center,
  Card,
  Select,
} from '@mantine/core'

import { FormHeader } from '../../../common/form-header'
import { ErrorSection } from '../../../common/error-section'
import { SubmitButton } from '../../../common/submit-button'
import states from '../../../utils/states'
import { usePhoneInput } from '../../../hooks/usePhoneInput'
import { saveBusiness } from '../actions'

const useStyles = createStyles((theme) => ({
  card: {
    overflow: 'inherit',
  },
}))

const usStates = [
  { label: 'Illinois', value: 'IL ' },
  { label: '-------', value: ' ' },
  ...states.map(({ name, abbreviation }) => ({
    label: name,
    value: abbreviation,
  })),
]

const businessSchema = z.object({
  name: z.string({
    required_error: 'You must provide a name for your business',
    invalid_type_error: 'This name does not look right',
  }).min(3, { message: 'Your business name should be at least three characters' }),
  phone: z.string({
    required_error: 'You must provide a phone number for your business',
    invalid_type_error: 'This phone does not look right',
  }),
  address: z.string(),
  city: z.string({
    required_error: 'You must enter the city for your business',
    invalid_type_error: 'This city does not look right',
  }).min(1, { message: 'You must provide us the city for your business' }),
  state: z.string({
    required_error: 'You must select a state for your business',
    invalid_type_error: 'This state does not look right',
  }).trim().length(2, { message: 'You must select the state for your business' }),
  zipcode: z.string({
    required_error: 'You must provide a zipcode for your business',
    invalid_type_error: 'This zipcode does not look right',
  }).min(1, { message: 'You must provide the zipcode for your business' }),
})

const transpileValues = (importedSpec = null) => {
  if (!importedSpec || importedSpec === undefined) return null

  const {
    name,
    phone,
    address,
    city,
    state,
    zipcode,
    status,
  } = importedSpec

  return {
    name,
    phone,
    address,
    city,
    state,
    zipcode,
    status,
  }
}

export const ManageCompanyForm = (props) => {
  const { company } = props,
    { classes } = useStyles(),
    navigate = useNavigate(),
    [forbidden, setForbidden] = useState(false),
    [enforceFormat, formatPhone] = usePhoneInput(),
    [submitting, setSubmitting] = useState(false),
    [error, setError] = useState(null)

  const business = useForm({
    initialValues: {
      name: '',
      phone: '',
      address: '',
      city: '',
      state: '',
      zipcode: '',
      country: '',
    },

    validate: zodResolver(businessSchema.required()),

    transformValues: (values) => ({
      formattedPhone: formatPhone(values.phone),
    }),
  })

  const onPhoneInput = (event) => {
    const { target: { value = '', name = '' } = {} } = event || {}
    if (name === 'business.phone') {
      business.setFieldValue('phone', value.replace(/\D/g, '').substring(0, 10))
    }
  }

  const onZipcodeInput = (event = {}) => {
    const { target: { value = '' } = {} } = event
    business.setFieldValue('zipcode', value.replace(/\D/g, '').substring(0, 5))
  }

  // load an imported user if provided
  useEffect(() => {
    if (company) {
      business.setValues(transpileValues(company))
    } else {
      setForbidden(true)
    }
  }, [company])

  const validate = () => {
    business.validate()
    return business.isValid()
  }

  /**
   * Save business
   */
  const submitCompany = async () => {
    if (submitting) return

    if (!validate()) {
      setError('There was some invalid information found, please correct')
      console.warn('Unable to submit form', {
        errors: {
          company: business.errors,
        },
      })
      return
    }

    setError('')
    setSubmitting(true)

    if (await saveBusiness(company, business.values)) {
      navigate('.', { replace: true })
    }
    
    setSubmitting(false)
  }

  if (forbidden) {
    return (
      <Container my="md">
      <Title order={2} weight={500} mt="xl" mb="lg">Manage Company</Title>

      <Card className={classes.card} withBorder padding="lg" radius="md">
        <Text>You lack permission to manage this company</Text>
      </Card>
    </Container>
    )
  }

  return (
    <Container my="md">
      <Title order={2} weight={500} mt="xl" mb="lg">
        Manage Company
      </Title>

      {/** Company Details */}
      <Card withBorder padding="lg" radius="md" mb="lg">
        <FormHeader>Business Profile</FormHeader>

        <SimpleGrid
          cols={2}
          mt="lg"
          spacing="md"
          breakpoints={[
            { maxWidth: '30rem', cols: 1, spacing: 'sm' },
            { maxWidth: '72rem', cols: 2, spacing: 'sm' },
          ]}
        >
          <TextInput
            className={classes.textInput}
            label="Business Name"
            name="business.name"
            placeholder="Acme Mixing"
            size="sm"
            {...business.getInputProps('name')}
          />

          <TextInput
            className={classes.textInput}
            label="Business Phone"
            type="tel"
            name="business.phone"
            placeholder="3125555555"
            size="sm"
            {...business.getInputProps('phone')}
            value={business.getTransformedValues().formattedPhone}
            onKeyDown={enforceFormat}
            onChange={onPhoneInput}
          />

          <TextInput
            className={classes.textInput}
            label="Address"
            name="business.address"
            placeholder="123 E. Main St"
            size="sm"
            {...business.getInputProps('address')}
          />

          <TextInput
            className={classes.textInput}
            label="City"
            name="business.city"
            size="sm"
            withAsterisk
            {...business.getInputProps('city')}
          />
          
          <Select
            className={classes.textInput}
            label="State"
            placeholder="Select state"
            data={usStates}
            withAsterisk
            {...business.getInputProps('state')}
          />

          <TextInput
            className={classes.textInput}
            label="Zipcode"
            name="business.zipcode"
            size="sm"
            withAsterisk
            type="number"
            maxLength={5}
            {...business.getInputProps('zipcode')}
            onKeyDown={enforceFormat}
            onChange={onZipcodeInput}
          />
        </SimpleGrid>
      </Card>

      {error ? (
        <ErrorSection message={error} />
      ) : null}

      <Center>
        <SubmitButton
          onClick={submitCompany}
          text="Update Company"
          submitting={submitting}
        />
      </Center>
    </Container>
  );
}
