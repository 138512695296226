
import { useEffect, useMemo, useState } from 'react'
import { z } from 'zod'
import dayjs from 'dayjs'
import { useForm, zodResolver } from '@mantine/form'
import { notifications } from '@mantine/notifications'
import {
  Container,
  SimpleGrid,
  Text,
  Title,
  rem,
  ActionIcon,
  createStyles,
  Card,
  Badge,
  Flex,
  Menu,
  SegmentedControl,
  Button,
  Box,
  Modal,
  Grid,
  TextInput,
  Loader,
} from '@mantine/core'
import { randomId, useDisclosure, useHover } from '@mantine/hooks'
import {
  IconDotsVertical,
  IconTrash,
  IconPencil,
  IconX,
  IconCheck,
  IconPlus,
  IconExternalLink,
  IconAlertCircle,
  IconShield,
  IconShieldOff,
} from '@tabler/icons-react'
import fetchService from '../../services/fetch.service'
import { useNavigate } from 'react-router-dom'
import moduleClasses from './users.module.scss'
import { LoadingData } from '../../common/loading-data'
import browserStorageService, { STORAGE_KEY } from '../../services/browser-storage.service'
import { ROLES, formatDate, userCanModify } from './helper'
import { UserStatusBadge } from './shared/user-status-badge'
import { blockUser, unblockUser } from './actions'

const useStyles = createStyles((theme) => ({
  card: {
    overflow: 'inherit',
  },
  button: {
    borderRadius: theme.radius.sm,
    padding: `${theme.spacing.sm} ${theme.spacing.lg}`,
    cursor: 'pointer',
    height: rem('3.25rem'),
    maxWidth: rem('13rem'),
  },
  errorWrap: {
    backgroundColor: '#f2f2f2',
    padding: theme.spacing.sm,
    marginTop: theme.spacing.md,
    borderLeft: `3px solid ${theme.colors.red[5]}`
  },
}));

const ManageMenu = (props) => {
  const {
    children,
    user = {},
    onEdit,
    onView,
    onBlock,
    onUnblock,
  } = props

  const iconSize = 16,
    [canModify, setCanModify] = useState(false)

  useEffect(() => {
    browserStorageService.getItem(STORAGE_KEY.ROLE)
      .then((role) => {
        setCanModify(userCanModify(role))
      })
  }, [browserStorageService, setCanModify])

  return (
    <Menu shadow="md" width={200}>
      <Menu.Target>{children}</Menu.Target>
      <Menu.Dropdown>
        <Menu.Label>{user.name || 'No name set'}</Menu.Label>
        <Menu.Item
          onClick={() => onView(user)}
          icon={<IconExternalLink size={iconSize} />}
        >
          View User
        </Menu.Item>

        {canModify ? (
          <>
            <Menu.Item
              onClick={() => onEdit(user)}
              icon={<IconPencil size={iconSize}/>}
            >
              Edit User
            </Menu.Item>
            {user?.flags?.blocked ? (
              <Menu.Item
                onClick={() => onUnblock(user)}
                icon={<IconShieldOff size={iconSize} />}
              >
                Unblock User
              </Menu.Item>
            ) : (
              <Menu.Item
                onClick={() => onBlock(user)}
                icon={<IconShield size={iconSize} />}
              >
                Block User
              </Menu.Item>
            )}
          </>
        ) : null}
      </Menu.Dropdown>
    </Menu>
  )
}

const CreateCard = (props) => {
  const { onClick } = props
  const { hovered, ref } = useHover()

  return (
    <Card
      withBorder
      component="button"
      onClick={onClick}
      shadow={hovered ? 'sm' : 'xs'}
      ref={ref}
      className={moduleClasses.createCardBtn}
    >
      <div className={moduleClasses.createCard}>
        <IconPlus size="1.125rem" />
        <Text size="md" ml="sm">Invite User</Text>
      </div>
    </Card>
  )
}

const inviteUserSchema = z.object({
  name: z.string().min(3, { message: 'Name should be at least 3 characters' }),
  email: z.string().min(1, { message: 'You have to provide an email' })
    .email('That email does not look correct.')
})

const fetchUsers = async () => {
  return await fetchService.get('/auth/users')
}

export const UsersView = () => {
  const navigate = useNavigate()
  const [loaded, setLoaded] = useState(false)
  const [users, setUsers] = useState([])
  const [addingUser, setAddingUser] = useState(false)
  const [filteredUsers, setFilteredUsers] = useState([])
  const [filteredStatus, setFilteredStatus] = useState('')
  const { classes } = useStyles()
  const [opened, { open, close }] = useDisclosure(false)
  const [inviteError, setInviteError] = useState(null)
  const [forbidden, setForbidden] = useState(false)

  const inviteProfile = useForm({
    validate: zodResolver(inviteUserSchema.required()),
    initialValues: {
      name: '',
      email: '',
    }
  })

  const handleView = (user) => {
    navigate(`/dashboard/user/${user.userId || user.id}`)
  }

  const handleEdit = (user) => {
    navigate(`/dashboard/user/${user.userId || user.id}/edit`)
  }

  const handleBlock = async (user) => {
    if (await blockUser(user)) {
      refreshUsers()
    }
  }

  const handleUnblock = async (user) => {
    if (await unblockUser(user)) {
      refreshUsers()
    }
  }

  const handleAdd = () => {
    inviteProfile.reset()
    open()
  }

  const handleSaveNewUser = async () => {
    const { values: user  } = inviteProfile

    inviteProfile.validate()
    if (user.email === '') {
      inviteProfile.setFieldError('email', 'You have to provide an email')
      return
    } else if (!inviteProfile.isValid()) {
      return
    }

    setAddingUser(true)
    setInviteError(null)

    try {
      const response = await fetchService.post('/auth/users', { body: { user } })

      if (!!response.result.user.userId) {
        notifications.show({
          id: 'users.invite',
          title: 'Invite sent',
          autoClose: true,
          withCloseButton: true,
        })

        refreshUsers()
        close()
        setAddingUser(false)
        setInviteError(null)
        inviteProfile.reset()
      } else {
        console.warn('User invite status unknown, throwing error', response)
        throw new Error('unknown response')
      }
    } catch (err) {
      setAddingUser(false)

      if (err?.response.status === 409) {
        // conflict/duplicate exists
        inviteProfile.setFieldError('email', 'This user already has an eConcretely account.')
        return
      }

      setInviteError('We were unable to fulfill your request at this time. Please try again later.')
      console.error('Invite failure', err)   
    }
  }

  const refreshUsers = () => {
    console.log('refreshUsers')
    setLoaded(false)

    fetchUsers()
      .then(({ status, result }) => {
        const users = result?.users || []
        setUsers(users)
      })
      .catch((err) => {
        console.warn('Unable to fetch users', err)
      })
      .finally(() => {
        setLoaded(true)
      })
  }
 

  useEffect(() => {
    fetchUsers()
      .then(({ status, result }) => {
        const users = result?.users || []
        setUsers(users)
      })
      .catch((err) => {
        console.warn('Unable to fetch users', err)
        // if (err.response.status === 403)
        setForbidden(true)
      })
      .finally(() => {
        setLoaded(true)
      })
  }, [fetchUsers])

  useEffect(() => {
    if (!filteredStatus) {
      setFilteredUsers(users)
    } else {
      setFilteredUsers(
        users.filter((user) => user.role === filteredStatus.toUpperCase())
      )
    }
  }, [users, filteredStatus])

  

  const userCards = useMemo(() => {
    return filteredUsers.map((user, index) => {

      const ActionMenu = (props) => {
        const methods = {
          onBlock:        handleBlock,
          onUnblock:      handleUnblock,
          onEdit:         handleEdit,
          onView:         handleView,
        }

        return (
          <ManageMenu user={props.user} {...methods}>
            <ActionIcon variant="transparent" color="blue" radius="xl" size="sm" aria-label="Options">
              <IconDotsVertical />
            </ActionIcon>
          </ManageMenu>
        )
      }

      return (
        <Card key={index} className={classes.card} withBorder padding="sm" radius="md">
          <Flex justify="space-between" align="center" mb="sm">
            <UserStatusBadge user={user} />
            {user.flags.blocked ? (
              <Badge mr="sm" variant="filled" color="red">Blocked</Badge>
            ) : null}
    
            <ActionMenu user={user} />
          </Flex>
  
          <Text fz="md" fw={500} mb="sm">
            <span style={{ cursor: 'pointer' }} onClick={() => handleView(user)}>
              {user.name || 'No name provided'}
            </span>
          </Text>

          <Box mt="sm">
            <Text size="xs" color="dimmed">Date Added</Text>
            <Text fw={500} size="sm">{formatDate(user.createdAt)}</Text>
          </Box>
        </Card>
      )
    })
  }, [filteredUsers])

  if (forbidden) {
    return (
      <Container p="0">
      <Title order={2} weight={500} mt="xl" mb="lg">Manage Users</Title>

      <Card className={classes.card} withBorder padding="lg" radius="md">
        <Text>You lack permission to view users</Text>
      </Card>
    </Container>
    )
  }

  return (
    <Container p="0">
      <Modal opened={opened} onClose={close} title="Invite User" centered closeOnClickOutside={false}>
        <Text>Invite a new user to your company here. They'll receive an email where they can finish registering.</Text>
        <Grid mt="md">
          <Grid.Col sm={6}>
            <TextInput
              label="Full name"
              {...inviteProfile.getInputProps('name')}
            />
          </Grid.Col>
          <Grid.Col sm={6}>
          <TextInput
              label="Email"
              {...inviteProfile.getInputProps('email')}
            />
          </Grid.Col>
        </Grid>

        {inviteError ? (
          <Box className={classes.errorWrap}>
            <Flex mb="md">
              <IconAlertCircle size="1.5rem" color="red" />
              <Text ml="md" color="red">{inviteError}</Text>
            </Flex>
          </Box>
        ) : null}

        <Flex justify="flex-end" mt="md">
          <Button
            onClick={handleSaveNewUser}
            disabled={addingUser}
          >
            {addingUser ? (
              <Loader color="blue" size="sm" />
            ) : 'Send invite'}
          </Button>
        </Flex>
      </Modal>

      <Title order={2} weight={500} mt="xl" mb="lg">Manage Users</Title>

      {/* <SegmentedControl
        mb="sm"
        value={filteredStatus}
        onChange={setFilteredStatus}
        data={[
          { label: 'All', value: '' },
          { label: 'Draft', value: 'draft' },
          { label: 'Pending Approval', value: 'pending' },
          { label: 'Published', value: 'published' },
        ]}
      /> */}

      {!loaded ? (
        <LoadingData />
      ) : null}

      <SimpleGrid
        cols={3}
        spacing="md"
        breakpoints={[
          { maxWidth: '52rem', cols: 1, spacing: 'sm' },
          { maxWidth: '72rem', cols: 2, spacing: 'sm' },
          { minWidth: '92rem', cols: 3, spacing: 'md' },
        ]}
      >
        {userCards}
        <CreateCard onClick={handleAdd} />
      </SimpleGrid>
    </Container>
  );
}
