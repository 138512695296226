import { rem } from '@mantine/core'
import { notifications } from '@mantine/notifications'
import { StatusCodes } from 'http-status-codes'
import { IconCheck, IconExclamationCircle } from '@tabler/icons-react'
import fetchService from '../../services/fetch.service'
import sessionService from '../../services/session.service'

export const updateAccount = async (user, specs, notificationId) => {
  const userId = user.id || user.userId,
      nId = notificationId ? notificationId : `action.settings.${userId}.save`

  notifications.show({
    id: nId,
    loading: true,
    title: 'Updating Account Settings',
    autoClose: false,
    withCloseButton: false,
  })

  try {
    const body = { ...specs },
      response = await fetchService.patch(`/auth/user/${userId}`, { body })

    if (response.status === StatusCodes.OK) {
      notifications.update({
        id: nId,
        color: 'teal',
        title: 'Updated Account Settings',
        icon: <IconCheck style={{ width: rem(18), height: rem(18) }} />,
        loading: false,
      })

      return true
    }
  } catch (err) {
    console.error('Unable to save settings', err)

    notifications.update({
      id: nId,
      color: 'red',
      title: 'Unable to save settings',
      message: `We encountered an error saving your settings. Please try again or contact support.`,
      icon: <IconExclamationCircle style={{ width: rem(18), height: rem(18) }} />,
      loading: false,
      autoClose: false,
    })
  }

  return false
}

export const updateAccountAuth = async (user, authSpecs, notificationId) => {
  const userId = user.id || user.userId,
      nId = notificationId ? notificationId : `action.auth.${userId}.save`

  notifications.show({
    id: nId,
    loading: true,
    title: 'Updating Login Auth Code',
    autoClose: false,
    withCloseButton: false,
  })

  try {
    const body = { ...authSpecs },
      response = await fetchService.patch(`/auth/user/${userId}`, { body })

    if (response.status === StatusCodes.OK) {
      notifications.update({
        id: nId,
        color: 'teal',
        title: 'Updated Login Auth Code',
        icon: <IconCheck style={{ width: rem(18), height: rem(18) }} />,
        loading: false,
      })

      return true
    }
  } catch (err) {
    console.error('Unable to update auth code', err)

    if (err?.response?.status === 403) {
      const message = err?.response?.data?.error?.context?.message || 'There was an error updating your login auth code'

      notifications.update({
        id: nId,
        color: 'red',
        title: 'Unable to update auth code',
        message: `${message} – If you believe this to be an error, please try again or contact support.`,
        icon: <IconExclamationCircle style={{ width: rem(18), height: rem(18) }} />,
        loading: false,
        autoClose: false,
      })

      return false
    }

    notifications.update({
      id: nId,
      color: 'red',
      title: 'Unable to update auth code',
      message: `We encountered an error saving your settings. Please try again or contact support.`,
      icon: <IconExclamationCircle style={{ width: rem(18), height: rem(18) }} />,
      loading: false,
      autoClose: false,
    })
  }

  return false
}

export const updatePreference = async (user, preferenceId) => {
  const userId = user.id || user.userId,
    nId = `action.auth.${userId}.updatePreference`

  notifications.show({
    id: nId,
    loading: true,
    title: 'Updating Preference',
    autoClose: false,
    withCloseButton: false,
  })

  try {
    const body = { preferenceId },
      response = await fetchService.patch(`/auth/user/${userId}/preference`, { body })

    if (response.status === StatusCodes.OK) {
      await sessionService.loadCurrentUser()

      notifications.update({
        id: nId,
        color: 'teal',
        title: 'Preference updated',
        icon: <IconCheck style={{ width: rem(18), height: rem(18) }} />,
        loading: false,
      })

      return true
    }
  } catch (err) {
    console.error('Unable to save preference', err)

    notifications.update({
      id: nId,
      color: 'red',
      title: 'Unable to update preference',
      message: `We encountered an error saving your preference. Please try again or contact support.`,
      icon: <IconExclamationCircle style={{ width: rem(18), height: rem(18) }} />,
      loading: false,
      autoClose: false,
    })
  }

  return false
}
