import { serialize as objectToFormData } from 'object-to-formdata'
import { StatusCodes } from 'http-status-codes'
import axios from 'axios'
import { CsrfStore } from '../stores/csrf.store'

const CSRF_TOKEN_HEADER = 'csrf-token'

const createUrl = (path, query = null) => {
  const origin = process.env.NODE_ENV === 'production'
    ? 'https://api.econcrete.ly'
    : 'http://localhost:3001',
    apiPath = `${path}`.replace(/([^:]\/)\/+/g, '$1'), // remove duplicate slashes
    url = new URL(apiPath, origin)

  // console.log({ origin, apiPath, url })

  if (query) url.search = serializeQuery(query)
  return url.toString()
}

const createHeaders = async ({ customHeaders, hasAttachments, skipCsrf, csrfStore } = {}) => {
  const headers = new Headers()

  // if (companyId) headers.set(COMPANY_HEADER, companyId)

  // content-type should be application/json if no attachments (files)
  if (!hasAttachments) {
    headers.set('content-type', 'application/json')
  }
  
  if (customHeaders && Object.keys(customHeaders).includes(CSRF_TOKEN_HEADER)) {
    skipCsrf = true
  }

  try {
    if (!skipCsrf && csrfStore) {
      // console.log('NO SKIP, HAVE CSRF STsORE')
      const csrfToken = await csrfStore.getToken()
      if (csrfToken) {
        // console.log('csrfToken', csrfToken)
        headers.set(CSRF_TOKEN_HEADER, csrfToken)
      }
    }
  } catch (err) { }

  // if additional headers are provided, append them
  // customHeaders meant to override predefined headers
  if (customHeaders) {
    for (const key in customHeaders) {
      if (Object.keys(customHeaders).includes(key)) {
        headers.set(key, customHeaders[key])
      }
    }
  }

  // headers.set('origin', window.location.origin)

  const parsedHeaders = {}
  for (const [key, value] of headers.entries()) {
    parsedHeaders[key] = value
  }

  return parsedHeaders
}

const serializeQuery = (query) => {
  const searchParams = new URLSearchParams()
  Object.keys(query)
    .filter((key) => query[key] !== undefined)
    .forEach((key) => {
      if (query[key] instanceof Object) {
        searchParams.append(key, JSON.stringify(query[key]))
      } else {
        searchParams.append(key, query[key])
      }
    })
  
  return searchParams.toString()
}

const parseResponse = async (response) => {
  const contentType = response.headers.get('content-type')

  if (!contentType) throw new Error('Failed to parse response: No content-type provided')
  if (contentType.includes('json')) return await response.json()
  if (contentType.includes('text')) return await response.text()
  if (!!contentType.match(/application|image|pdf|document/ig)) return await response.arrayBuffer()

  throw new Error(`Failed to parse response -- content-type [${contentType} is unsupported`)
}

const isEmpty = (value) => {
  return  value === undefined ||
          value === null ||
          (typeof value === "object" && Object.keys(value).length === 0) ||
          (typeof value === "string" && value.trim().length === 0)
}

// const lbAxios = axios.create({
//   headers: {
//     lbcode: window.sessionStorage.getItem('lbAuthToken'),
//   },
//   baseURL: 'http://localhost:3000/',
//   withCredentials: true,
// })

// lbAxios.interceptors.request.use((config) => {
//   config.headers['csrf-token'] = ''
//   if (false) {
//     config.headers['x-lb-id'] = '123'
//   }

//   return config
// })

export class FetchService {
  csrfStore
  _onRequest
  _onUnauthorized

  constructor() {
    this.csrfStore = new CsrfStore(this)
    // console.log('SET STORE')
  }

  _handleFetch = async (options) => {
    let url, response

    try {
      const {
        body,
        credentials,
        file,
        path,
        query,
        headers = {},
        method,
        skipCsrf,
        parseResponse: customParseResponse,
        timeout = 5000,
        withCredentials = true,
        redirectOnUnauth = true,
      } = options

      const payload = file ? objectToFormData({ file, ...body}) : body,
        url = createUrl(path, query),
        config = { method, timeout, url }

      // append a filesize if file being uploaded
      if (file) {
        payload.append('client_filesize', file.size.toString())
      }

      

      if (this._onRequest) await this._onRequest({ method, url, data: payload })
      // if (payload) {
      //   if (payload instanceof FormData) init.body = payload
      //   else if (!isEmpty(payload)) init.body = JSON.stringify(payload)
      // }

      // init.credentials = credentials || 'same-origin'
      const axiosHeaders = await createHeaders({
        customHeaders: headers,
        hasAttachments: !!file,
        skipCsrf,
        csrfStore: this.csrfStore
      })

      const axiosConfig = {
        ...config,
        headers: axiosHeaders,
        data: payload,
        withCredentials,
      }

      const lbAxios = axios.create({ timeout })
      lbAxios.interceptors.response.use(
        (res) => {
          // console.log('intercept response[1]', res)
          // console.log('>>>>', {
          //   status: res.status,
          //   result: res.result,
          // })
          return res
        }, (err) => {
          // for anything outside of 2xx
          // console.log('intercept response[2]', err)
          // console.log(err.status)
          if (err?.response?.status === StatusCodes.UNAUTHORIZED && redirectOnUnauth) {
            console.warn('Authentication distrupted ... Return to login')
            window.location.replace('/logout')
          }

          return Promise.reject(err)
        })

      // console.log('config', axiosConfig)

      const response = await lbAxios.request(axiosConfig)

      // response = await fetch(url, init)

      // 401
      if (response.status === StatusCodes.UNAUTHORIZED) {
        if (this._onUnauthorized) this._onUnauthorized()
      }

      if (response.status === StatusCodes.NO_CONTENT) {
        return null
      }

      const parsedResponse = (response && response.data) ? response.data : {}//await (customParseResponse ? customParseResponse(response) : parseResponse(response))
      if (parsedResponse._csrf) {
        this.csrfStore.csrfToken = parsedResponse._csrf
        delete parsedResponse._csrf
      }

      return parsedResponse
    } catch (err) {
      console.error(`[FetchService]
      Request URL: ${url || options.path}
      Method: ${options.method}
      Status: ${response && response.status ? response.status : 'No response provided'}
      ${err.toString()}\n`)

      throw err
    }
  }

  interceptUnauthorized = (cb) => {
    this._onUnauthorized = cb
  }

  interceptRequest = (cb) => {
    this._onRequest = cb
  }

  get = (path, options = {}) => {
    return this._handleFetch({ ...options, method: 'GET', path })
  }

  post = (path, options = {}) => {
    return this._handleFetch({ ...options, method: 'POST', path })
  }

  put = (path, options = {}) => {
    return this._handleFetch({ ...options, method: 'PUT', path })
  }

  patch = (path, options = {}) => {
    return this._handleFetch({ ...options, method: 'PATCH', path })
  }

  delete = (path, options = {}) => {
    return this._handleFetch({ ...options, method: 'DELETE', path })
  }
}

const fetchService = new FetchService()
export default fetchService
