import { useEffect, useMemo, useState } from 'react'
import { HttpStatusCode } from 'axios'
import {
  Modal,
  rem,
  createStyles,
  Autocomplete,
  Anchor,
  Text,
  ScrollArea,
  Table,
  Menu,
  ActionIcon,
  Center,
} from '@mantine/core'
import { notifications } from '@mantine/notifications'
import {
  IconCheck,
  IconDotsVertical,
  IconExclamationCircle,
  IconSettings,
  IconTrash
} from '@tabler/icons-react'

import { noop } from '../../../../utils/noop'
import browserStorageService, { STORAGE_KEY } from '../../../../services/browser-storage.service'
import { fillSpec, renderSpecList } from '../../view'
import { TicketStatusBadge } from '../../shared/ticket-status-badge'
import { NewClientForm } from '../../forms/new-client-form'
import {
  fetchClientsForCompany,
  fetchSharedForTicket,
  revokeClientTicketAccess,
  shareTicketWithClient,
} from './actions'

const useStyles = createStyles((theme) => ({
  button: {
    borderRadius: theme.radius.sm,
    padding: `${theme.spacing.sm} ${theme.spacing.lg}`,
    cursor: 'pointer',
    height: rem('3.25rem'),
    maxWidth: rem('13rem'),
  },
  tableActionRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  tableHeader: {
    backgroundColor: '#ffffff',
  },
  tableHeadCol: {
    position: 'sticky',
    top: 0,
    backgroundColor: '#ffffff',
  },
  tableHeadColIcon: {
    zIndex: 1,
  },
}))

const ManageMenu = (props) => {
  const {
    children,
    onRemoveAccess,
    client,
  } = props

  const iconSize = 16

  return (
    <Menu shadow="md" width={200}>
      <Menu.Target>{children}</Menu.Target>
      <Menu.Dropdown>
        <Menu.Item
          color="red"
          onClick={() => onRemoveAccess(client)}
          icon={<IconTrash size={iconSize} />}
        >
          Remove Access
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  )
}

export const ShareModal = (props) => {
  const {
    onClose = noop,
    target = null,
    title = 'Share Ticket',
    hideTicketDetails = false,
  } = props

  const [isActive, setActive] = useState(false),
    [sessionCompany, setCompany] = useState({}),
    [errorMessage, setError] = useState(''),
    [clients, setClients] = useState([]),
    [sharedClients, setSharedClients] = useState([]),
    [processing, setProcessing] = useState(false),
    { classes, cx } = useStyles(),
    [searchQuery, setQuery] = useState(''),
    [showNewClientForm, setNewClientForm] = useState(false)

  // Set active ticket
  useEffect(() => {
    setActive(!!target)
  }, [target])

  // Fetch clients for company and clients who already have access
  useEffect(() => {
    if (target) {
      fetchSharedForTicket(target)
        .then((response) => {
          if (response.ok) {
            setSharedClients(response.shared)
          }
        })

      browserStorageService.getItem(STORAGE_KEY.SESSION)
        .then((session) => {
          setCompany(session.company)

          fetchClientsForCompany(session.company)
            .then((response) => {
              if (response.ok) {
                setClients(response.clients)
              }
            })
        })
    }
  }, [target])

  const handleClose = () => {
    setActive(false)
    setNewClientForm(false)
    setQuery('')
    onClose()
  }

  const clientsWithAccess = useMemo(() => {
    if (!sharedClients || !sharedClients.length) return []
    return sharedClients.map((access) => access.client.phone)
  }, [sharedClients])

  const clientList = useMemo(() => {
    if (!clients || !clients.length) return []
    return clients
      .filter((client) => !clientsWithAccess.includes(client.phone))
      .map((client) => ({
        value: `${client.name} (${client.email})`, // label
        email: client.email || '',
        phone: client.phone || '',
        name: client.name || '',
        business: client.business || '',
      }))
  }, [clients, clientsWithAccess])

  const handleSearch = (e) => {
    setQuery(e)
  }

  const handleAddNewClient = () => {
    setNewClientForm(true)
  }

  const ticketSpecs = useMemo(() => {
    return [
      fillSpec('Name', target?.name || target?.meta.name),
      fillSpec('Status', (<TicketStatusBadge ticket={target} />))
    ]
  }, [target])

  const handleSelectClient = async (item) => {
    setQuery('')

    // item = { business, email, name, phone, value}
    const client = clients.find((_client) => _client.email === item.email)
    const nId = 'ticket::share'

    notifications.show({
      id: nId,
      loading: true,
      title: `Sharing ticket with ${client.name}`,
      autoClose: false,
      withCloseButton: false,
    })

    const shared = await shareTicketWithClient(client, target)
    if (shared.ok) {
      notifications.update({
        id: nId,
        color: 'teal',
        title: `Shared ticket with ${client.name}`,
        message: `They'll receive information about accessing this ticket shortly.`,
        icon: <IconCheck style={{ width: rem(18), height: rem(18) }} />,
        loading: false,
      })

      const sharedRefresh = await fetchSharedForTicket(target)
      if (sharedRefresh.ok) {
        setSharedClients(sharedRefresh.shared)
      }
    } else if (shared.status === HttpStatusCode.Conflict) {
      notifications.update({
        id: nId,
        color: 'red',
        title: 'Unable to share this ticket',
        message: `It looks like this ticket has already been shared with ${client.name}. If they are having difficulty accessing this ticket, please remove their access and add them again.`,
        icon: <IconExclamationCircle style={{ width: rem(18), height: rem(18) }} />,
        loading: false,
        autoClose: false,
      })
    } else {
      notifications.update({
        id: nId,
        color: 'red',
        title: 'Unable to share this ticket',
        message: `We encountered an error sharing your ticket with ${client.name}. Please try again or contact support.`,
        icon: <IconExclamationCircle style={{ width: rem(18), height: rem(18) }} />,
        loading: false,
        autoClose: false,
      })
    }
  }

  const handleRemoveAccess = async (client) => {
    const nId = 'ticket::revoke'

    notifications.show({
      id: nId,
      loading: true,
      title: `Revoking '${client.name}' access to this ticket`,
      autoClose: false,
      withCloseButton: false,
    })

    const revoked = await revokeClientTicketAccess(client, target)
    if (revoked.ok) {
      notifications.update({
        id: nId,
        color: 'teal',
        title: 'Ticket access has been revoked',
        message: `${client.name} will no longer have access to this ticket. You can share this ticket with them again in the future.`,
        icon: <IconCheck style={{ width: rem(18), height: rem(18) }} />,
        loading: false,
      })

      const sharedRefresh = await fetchSharedForTicket(target)
      if (sharedRefresh.ok) {
        setSharedClients(sharedRefresh.shared)
      }
    } else {
      notifications.update({
        id: nId,
        color: 'red',
        title: 'Unable to revoke access for this ticket',
        message: `We encountered an error revoking '${client.name}' access to this ticket. Please try again or contact support.`,
        icon: <IconExclamationCircle style={{ width: rem(18), height: rem(18) }} />,
        loading: false,
        autoClose: false,
      })
    }
  }

  const addClientSuccess = async (client) => {
    if (!client) return

    setNewClientForm(false)
    setQuery('')
  
    // refresh clients list
    fetchClientsForCompany(sessionCompany)
      .then((response) => {
        console.log('refresh client list')
        if (response.ok) {
          setClients(response.clients)
        }
      })

    const shared = await shareTicketWithClient(client, target)
    if (!shared.ok) {
      const notification = { title: '', message: '' }
      if (shared.status === HttpStatusCode.Conflict) {
        notification.title = `Unable to share ticket with ${client.name}`
        notification.message = 'We hit a snag deleting this client. Please try again or contact support.'
      }

      notification.title = `Unable to share ticket with ${client.name}`
      notification.message = 'This client already has access to this ticket.'

      notifications.show({
        id: 'client::share',
        color: 'red',
        title: notification.title,
        message: notification.message,
        icon: <IconExclamationCircle style={{ width: rem(18), height: rem(18) }} />,
        loading: false,
        autoClose: false,
      })

      return false
    } else {
      notifications.show({
        id: 'client::share',
        color: 'teal',
        title: `Shared ticket with ${client.name}`,
        message: `${client.name} has been invited to view this ticket.`,
        icon: <IconCheck style={{ width: rem(18), height: rem(18) }} />,
        loading: false,
      })

      // refresh shared clients
      const allSharedResponse = await fetchSharedForTicket(target)
      if (allSharedResponse.ok) {
        setSharedClients(allSharedResponse.shared)
      }

      return true
    }
  }

  const addClientCancel = () => {
    setNewClientForm(false)
    setQuery('')
  }

  const header = (
    <tr>
      <th className={classes.tableHeadCol}>Name</th>
      <th className={classes.tableHeadCol}>Business</th>
      <th className={cx(classes.tableHeadCol, classes.tableHeadColIcon)}><Center><IconSettings /></Center></th>
    </tr>
  )

  const tableRows = useMemo(() => {
    const ActionMenu = ({ client } = props) => {
      const methods = {
        onRemoveAccess: () => handleRemoveAccess(client),
      }

      return (
        <ManageMenu {...methods}>
          <ActionIcon variant="transparent" color="blue" radius="xl" size="sm" aria-label="Options">
            <IconDotsVertical />
          </ActionIcon>
        </ManageMenu>
      )
    }

    return sharedClients.map((access) => {
      return (
        <tr key={access.clientTicketId}>
          <td>{access.client.name}</td>
          <td>{access.client.business}</td>
          <td><Center><ActionMenu client={access.client} /></Center></td>
        </tr>
      )
    })
  }, [sharedClients])

  const emptyLabels = {
    text: !searchQuery.length && !sharedClients.length
      ? 'No clients added yet.'
      : !searchQuery.length && sharedClients.length
        ? 'No more clients to add.'
        : 'Client not found.',
    ctaLabel: !searchQuery.length
      ? 'Add new client'
      : `Add '${searchQuery}'`,
  }

  const prefillNewClient = useMemo(() => {
    if (!searchQuery || !searchQuery.length) return {}

    if (searchQuery.match(/^[0-9]+$/)) {
      return {
        phone: searchQuery,
      }
    } else if (searchQuery.includes('@')) {
      return {
        email: searchQuery,
      }
    }
    
    return {
      name: searchQuery,
    }
  }, [searchQuery])

  if (!target) return null

  return (
    <Modal opened={isActive} onClose={handleClose} title={title}>

      {!hideTicketDetails ? renderSpecList(ticketSpecs) : null}

      {showNewClientForm ? (
        <NewClientForm
          prefill={prefillNewClient}
          onSuccess={addClientSuccess}
          onCancel={addClientCancel}
        />
      ) : (
        <>
          <Autocomplete
            mt={!hideTicketDetails ? "lg" : ""}
            description="Search with name, phone number, email, or business name"
            value={searchQuery}
            onItemSubmit={handleSelectClient}
            onChange={handleSearch}
            label="Share ticket with a Client"
            placeholder="Type name, phone number, or email address"
            // Your custom item component with data properties
            // itemComponent={({ value, color, email, name }) => <div />}
            data={clientList}
            filter={(value, item) =>
              item.name.toLowerCase().includes(value.toLowerCase().trim()) ||
              item.email.toLowerCase().includes(value.toLowerCase().trim()) ||
              item.phone.toLowerCase().includes(value.toLowerCase().trim()) ||
              item.business.toLowerCase().includes(value.toLowerCase().trim())
            }
            nothingFound={(
              <>
                <span>{emptyLabels['text']}</span>{' '}
                <Anchor href="#" onClick={handleAddNewClient}>{emptyLabels['ctaLabel']}</Anchor>?
              </>
            )}
          />

          <Text fz="lg" fw={300} mt="lg" mb="sm">Clients who have access</Text>
          {!sharedClients.length ? (
            <Text fz="sm">No clients yet</Text>
          ) : (
            <ScrollArea h={200}>
              <Table highlightOnHover withColumnBorders withBorder striped>
                <thead className={classes.tableHeader}>{header}</thead>
                <tbody>
                    {tableRows}
                </tbody>
              </Table>
            </ScrollArea>
          )}
        </>
      )}
    </Modal>
  )
}
