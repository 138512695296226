import {
  Paper,
  createStyles,
  TextInput,
  PasswordInput,
  Checkbox,
  Button,
  Title,
  Text,
  Anchor,
  rem,
  MediaQuery,
  Center,
  Container,
  Stepper,
  Group,
  Box,
  SimpleGrid,
  Stack,
  Code,
  Select,
  Flex,
  Loader,
} from '@mantine/core'
import { useEffect, useState } from 'react'
import fingerprintService, { DEVICE_FINGERPRINT } from '../../services/fingerprint.service'
import { Link, redirect, useLoaderData, useNavigate, useLocation, useSearchParams } from 'react-router-dom'
import highway from '../../static/highway.jpg'
import { IconAlertCircle, IconCircleCheck, IconMailCheck, IconShieldCheck, IconUserCheck } from '@tabler/icons-react';
import { useForm, zodResolver } from '@mantine/form';
import { z } from 'zod';
import fetchService from '../../services/fetch.service'
import { notifications } from '@mantine/notifications'

const MAX_AUTH_LENGTH = 12
const MIN_AUTH_LENGTH = 6

const useStyles = createStyles((theme) => ({
  wrapper: {
    backgroundSize: 'cover',
    backgroundImage: `url(${highway})`, //'url(../static/highway.jpg)',
    maxWidth: 'initial',
    minHeight: '100vh',
  },

  formDesktop: {
    backgroundColor: theme.fn.rgba('#1d1224', 0.9),
    borderRight: `${rem(1)} solid #363636`,
    height: '100vh',
    maxWidth: rem(450),
    paddingTop: rem(80),
    color: '#f8f9f7',
  },

  wrapperMobile: {
    display: 'flex',
    height: '100%',
    backgroundSize: 'cover',
    backgroundImage: `url(${highway})`, //'url(../static/highway.jpg)',
  },

  formMobile: {
    backgroundColor: theme.fn.rgba('#1d1224', 0.9), //'#1d1224',
    border: `${rem(1)} solid ${theme.fn.darken('#f9af42', 0.5)}`,
    color: '#f8f9f7',
  },

  form: {
    width: rem(700),
    paddingTop: rem(80),
    backgroundColor: theme.fn.rgba('#1d1224', 0.9), //'#1d1224',
    border: `${rem(1)} solid ${theme.fn.darken('#f9af42', 0.5)}`,
    color: '#f8f9f7',


    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      maxWidth: "80%",
    },

    // Simplify media query writing with theme functions
    [theme.fn.smallerThan('sm')]: {
      // backgroundColor: theme.colors.yellow[6],
      padding: rem(10),
    },
  },

  title: {
    // color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    color: '#f8f9f7',
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },

  textInput: {
    color: '#f8f9f7',

    label: {
      color: '#f8f9f7',
    },

    input: {
      '::placeholder': {
        color: theme.colors.gray[6],
      },
    },
  },

  link: {
    color: '#f9af42',
    position: 'relative',

    '&:hover': {
      textDecoration: 'none',
    },
  },

  errorWrap: {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    padding: theme.spacing.sm,
    marginTop: theme.spacing.md,
    borderLeft: `3px solid ${theme.colors.red[5]}`
  },
}));


const formSchema = z.object({
  email: z.string().min(1, { message: "You have to provide an email" })
    .email("That email does not look correct.")
})

export const TokenRequestView = () => {
  const { classes, cx } = useStyles(),
    navigate = useNavigate(),
    { state: redirectState } = useLocation(),
    tokenDetail = useLoaderData()

  const [fingerprint, setFingerprint] = useState('')
  const [submitting, setSubmitting] = useState(false)
  const [error, setError] = useState('')
  const [showRequestNewToken, setShowRequestNewToken] = useState(false)
  const [showLoginLink, setShowLoginLink] = useState(false)
  const [searchParams] = useSearchParams()

  const form = useForm({
    initialValues: {
      email: '',
    },

    validate: zodResolver(formSchema.required()),
  })

  const validate = () => {
    form.validate()
    return form.isValid()
  }

  const handleSubmit = async () => {
    if (!validate()) return

    const { values: { email } } = form
    console.log('submit', { email })
    setSubmitting(true)

    try {
      await fetchService.post('/public/requestToken', {
        body: { email }
      })
    } finally {
      notifications.show({
        id: 'reset.authChanged',
        title: 'Request Received',
        message: `If we have an account for '${email}' we will send a Reset Passcode email.`,
        autoClose: true,
        withCloseButton: true,
      })

      navigate('/')
    }
  }

  useEffect(() => {
    const { email } = redirectState
    if (email) {
      form.setFieldValue('email', email)
    }
  }, [ redirectState ])

  return (
    <Container className={classes.wrapper}>
      <Center id="center" mx="auto">
        <Paper className={classes.form} radius={0} p={30} mt="lg" mb="lg">
          <Title order={2} className={classes.title} ta="center" mb={50}>
            Forgot Account Passcode
          </Title>

          {!showRequestNewToken ? (
            <Box>
              <Text align="center">
                Having difficulty logging in? Enter your email address below and if we find a matching eConcretely account, we'll send a unique Reset Passcode email to you.
              </Text>

              <Center mt="md">
                <Stack>
                  <TextInput
                    type="email"
                    className={classes.textInput}
                    label="Email address"
                    name="email"
                    placeholder="jsmith@gmail.com"
                    size="sm"
                    withAsterisk
                    {...form.getInputProps('email')}
                  />
                </Stack>
              </Center>

              <Center mt="xl" mb="md">
                <Button
                  onClick={handleSubmit}
                  disabled={submitting}
                >
                  {submitting ? (
                    <Loader color="blue" size="sm" />
                  ) : 'Submit'}
                </Button>
              </Center>
            </Box>
          ) : null}
        </Paper>
      </Center>
    </Container>
  );
}
