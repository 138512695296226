import {
  Paper,
  createStyles,
  TextInput,
  PasswordInput,
  Checkbox,
  Button,
  Title,
  Text,
  Anchor,
  rem,
  MediaQuery,
  Center,
  Container,
  Stepper,
  Group,
  Box,
  SimpleGrid,
  Stack,
  Code,
  Select,
  Flex,
  Loader,
} from '@mantine/core'
import { useEffect, useState } from 'react'
import fingerprintService, { DEVICE_FINGERPRINT } from '../../services/fingerprint.service'
import { Link, redirect, useLoaderData, useNavigate, useSearchParams } from 'react-router-dom'
import highway from '../../static/highway.jpg'
import { IconAlertCircle, IconCircleCheck, IconMailCheck, IconShieldCheck, IconUserCheck } from '@tabler/icons-react';
import { useForm, zodResolver } from '@mantine/form';
import { z } from 'zod';
import fetchService from '../../services/fetch.service'
import { notifications } from '@mantine/notifications'

const MAX_AUTH_LENGTH = 12
const MIN_AUTH_LENGTH = 6

const useStyles = createStyles((theme) => ({
  wrapper: {
    backgroundSize: 'cover',
    backgroundImage: `url(${highway})`, //'url(../static/highway.jpg)',
    maxWidth: 'initial',
    minHeight: '100vh',
  },

  formDesktop: {
    backgroundColor: theme.fn.rgba('#1d1224', 0.9),
    borderRight: `${rem(1)} solid #363636`,
    height: '100vh',
    maxWidth: rem(450),
    paddingTop: rem(80),
    color: '#f8f9f7',
  },

  wrapperMobile: {
    display: 'flex',
    height: '100%',
    backgroundSize: 'cover',
    backgroundImage: `url(${highway})`, //'url(../static/highway.jpg)',
  },

  formMobile: {
    backgroundColor: theme.fn.rgba('#1d1224', 0.9), //'#1d1224',
    border: `${rem(1)} solid ${theme.fn.darken('#f9af42', 0.5)}`,
    color: '#f8f9f7',
  },

  form: {
    width: rem(700),
    paddingTop: rem(80),
    backgroundColor: theme.fn.rgba('#1d1224', 0.9), //'#1d1224',
    border: `${rem(1)} solid ${theme.fn.darken('#f9af42', 0.5)}`,
    color: '#f8f9f7',


    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      maxWidth: "80%",
    },

    // Simplify media query writing with theme functions
    [theme.fn.smallerThan('sm')]: {
      // backgroundColor: theme.colors.yellow[6],
      padding: rem(10),
    },
  },

  title: {
    // color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    color: '#f8f9f7',
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },

  textInput: {
    color: '#f8f9f7',

    label: {
      color: '#f8f9f7',
    },

    input: {
      '::placeholder': {
        color: theme.colors.gray[6],
      },
    },
  },

  link: {
    color: '#f9af42',
    position: 'relative',

    '&:hover': {
      textDecoration: 'none',
    },
  },

  errorWrap: {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    padding: theme.spacing.sm,
    marginTop: theme.spacing.md,
    borderLeft: `3px solid ${theme.colors.red[5]}`
  },
}));


const formSchema = z.object({
  authCode: z.string().min(MIN_AUTH_LENGTH, { message: `Passcode should be at least ${MIN_AUTH_LENGTH} digits` }).max(12, { message: `Passcode should be less than ${MAX_AUTH_LENGTH} digits` }),
})

export const TokenResetView = () => {
  const { classes, cx } = useStyles(),
    navigate = useNavigate(),
    tokenDetail = useLoaderData()

  const [fingerprint, setFingerprint] = useState('')
  const [submitting, setSubmitting] = useState(false)
  const [error, setError] = useState('')
  const [showRequestNewToken, setShowRequestNewToken] = useState(false)
  const [showLoginLink, setShowLoginLink] = useState(false)
  const [searchParams] = useSearchParams()

  const form = useForm({
    initialValues: {
      authCode: '',
    },

    validate: zodResolver(formSchema.required()),
  })

  const onAuthInput = (event) => {
    const { target: { value = '' } = {} } = event || {}
    if (!/^[0-9]+$/.test(value) && value !== '') return
    if (value.length > MAX_AUTH_LENGTH) return

    form.setFieldValue('authCode', value)
  }

  const handleServerError = (serverError) => {
    const genericErrorMessage = 'An unexpected error occurred and we were unable to process your registration. Please try again. If the issue persists please reach out to us.'

    if (!serverError.response ||
      !serverError.response.data ||
      !serverError.response.data.error) {
      setError(genericErrorMessage)
    }

    const error = serverError.response.data.error
    if (error.code === 400 && error.message) setError(error.message)
    if (error.code === 409 && error.message) {
      setError(error.message)
    }
    if (error.code === 500 && error.message) {
      setError(`${genericErrorMessage} [${error.message}]`)
    }

    setShowRequestNewToken(true)
  }

  const validate = () => {
    form.validate()
    return form.isValid()
  }

  const handleSubmit = async () => {
    if (!validate()) return

    const { values: { authCode } } = form
    const token = searchParams.get('token')
    setSubmitting(true)

    try {
      const response = await fetchService.post('/public/submitToken', {
        body: { authCode, token, reason: 'RESET' }
      })

      if (!response.status === 204 || !response.status === 200) throw new Error('Unknown response')
      notifications.show({
        id: 'reset.authChanged',
        title: 'Your passcode has been updated',
        message: 'Please try logging in with your updated Passcode to access eConcretely.',
        autoClose: true,
        withCloseButton: true,
      })

      navigate('/')
    } catch (err) {
      setSubmitting(false)
      console.warn('Unable to update passcode', err)
      setError('We were unable to process your request at this time. Please refresh the page and try again.')
    }
  }

  const handleRequestNewToken = async (evt) => {
    evt.preventDefault()
    const token = searchParams.get('token')
    // setShowRequestNewToken(false)

    try {
      const response = await fetchService.post('/public/refreshToken', {
        body: { token, reason: 'RESET' }
      })

      if (!response.status === 204 || !response.status === 200) throw new Error('Unknown response')
      notifications.show({
        id: 'invite.refreshToken',
        title: 'Please check your email',
        message: 'We sent you an email containing a link to reset your passcode. Please check your email.',
        autoClose: true,
        withCloseButton: true,
      })

      navigate('/')
    } catch (err) {
      console.log(err)
      setError('This is awkward, but we were unable to process your request at this time. Please try again later')
      // setShowRequestNewToken(false)
    }
  }

  // load fingerprint
  useEffect(() => {
    const loadFingerprint = async () => {
      return await fingerprintService.getFingerprint()
    }

    loadFingerprint()
      .then(setFingerprint)
      .catch((err) => {
        console.warn('Unable to load fingerprint', err)
      })
  }, [])

  useEffect(() => {
    if (!tokenDetail) 
    console.log('loaded', tokenDetail, searchParams)
    try {
      const { result } = tokenDetail
      if (!result.status) throw new Error(result.code || 'unknown')
    } catch (err) {
      if (err.message === '404') {
        setError(`We're having difficulty finding your request. Please try again by clicking "Forgot Passcode" from the login screen.`)
        setShowLoginLink(true)
        return
      } else if (err.message === '406') {
        setError(`We were able to find your request, but unfortunately that token is now expired. Please request a new one.`)
      } else {
        console.warn(err)
        setError('We were unable to load the request at this time. Please start a new request.')
      }

      setShowRequestNewToken(true)
    }
  }, [tokenDetail])

  if (!tokenDetail) return (
    <Container className={classes.wrapper}>
      <Center id="center" mx="auto">
        <Paper className={classes.form} radius={0} p={30} mt="lg" mb="lg">
          <Center>
            <Loader color="blue" size="lg" />
          </Center>
        </Paper>
      </Center>
    </Container>
  )

  return (
    <Container className={classes.wrapper}>
      <Center id="center" mx="auto">
        <Paper className={classes.form} radius={0} p={30} mt="lg" mb="lg">
          <Title order={2} className={classes.title} ta="center" mb={50}>
            Set your eConcretely Passcode
          </Title>

          {!showRequestNewToken ? (
            <Box>
              <Text align="center">
                Please enter a passcode below between {MIN_AUTH_LENGTH} and {MAX_AUTH_LENGTH} numbers.
              </Text>

              <Center mt="md">
                <Stack maw={300}>
                  <PasswordInput
                    className={classes.textInput}
                    label="Passcode"
                    name="code"
                    placeholder="Login Passcode (007007)"
                    size="sm"
                    withAsterisk
                    {...form.getInputProps('authCode')}
                    onChange={onAuthInput}
                  />
                  {!form.errors.authCode ? (
                    <Text mt={rem(-10)} pl={rem(5)} pr={rem(5)} fz="xs" c="dimmed" ta="justify" fw={500}>
                      Numbers only. {MIN_AUTH_LENGTH}-{MAX_AUTH_LENGTH} digits for logging in.
                    </Text>
                  ) : null}
                </Stack>
              </Center>

              <Center mt="xl" mb="md">
                <Button
                  onClick={handleSubmit}
                  disabled={submitting}
                >
                  {submitting ? (
                    <Loader color="blue" size="sm" />
                  ) : 'Submit'}
                </Button>
              </Center>
            </Box>
          ) : null}

          {error ? (
            <Box className={classes.errorWrap}>
              <Flex mb="md">
                <IconAlertCircle size="1.5rem" color="red" />
                <Text ml="md" color="red">{error}</Text>
              </Flex>
              {showRequestNewToken ? (
                <>
                  <Link className={classes.link} onClick={handleRequestNewToken}>
                    <Text component="span">Initiate new request</Text>
                  </Link>
                </>
              ) : null}
              {showLoginLink ? (
                <>
                  <Link className={classes.link} to="/">
                    <Text component="span">Go to login</Text>
                  </Link>
                </>
              ) : null}
            </Box>
          ) : null}
        </Paper>
      </Center>
    </Container>
  );
}
