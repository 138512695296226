import { clsx } from '@mantine/core'
import './style.scss'

export const IntervalPicker = ({ onClick, interval }) => {
  const gliderStylePosition = interval === 'month'
    ? '2px'
    : '8.12rem'

  const gliderStyle = {
    '--glider-offset': gliderStylePosition,
  }

  const intervalMonthClassnames = clsx(
    'interval-picker__tab__item',
    {
      'interval-picker__tab__item--is-selected': interval === 'month',
    }
  )

  const intervalYearlyClassnames = clsx({
    ['interval-picker__tab__item']: true,
    ['interval-picker__tab__item--is-selected']: interval === 'year'
  })

  return (
    <div className="interval-picker">
      <div className="interval-picker__container">
        <div className="interval-picker__tab">
          <button
            className={intervalMonthClassnames}
            onClick={() => onClick ? onClick('month') : null}
          >
            <span className="interval-picker__tab__item__label">Quarterly</span>
          </button>
        </div>

        <div className="interval-picker__tab">
          <button
            className={intervalYearlyClassnames}
            onClick={() => onClick ? onClick('year') : null}
          >
            <span className="interval-picker__tab__item__label">Yearly</span>
          </button>
        </div>

        <span style={gliderStyle} className="interval-picker__glider"></span>
      </div>
    </div>
  )
}
