import { useMemo } from 'react'
import { Avatar, Box, Group, Text } from '@mantine/core'
import { IconMail, IconPhoneCall } from '@tabler/icons-react'
import { initialsFromName } from '../utils/string'
import classes from './user-info-card.module.css'

const transpile = (person, type) => {
  if (!person || !type) return {}
  const { name, phone, email, createdAt } = person,
    sharedProps = {
      phone,
      name,
      email,
      created: createdAt,
    }

  if (type === 'user') {
    return {
      id: person.userId,
      ...sharedProps,
      role: person.role,
      avatar: person.profileImage,
      blocked: person.flags.blocked,
      verified: person.flags.verified,
    }
  } else if (type === 'client') {
    return {
      id: person.clientId,
      ...sharedProps,
      business: person.business,
    }
  }

  return {}
}

export const UserInfoCard = (props) => {
  const {
    person, // Can be User or Client
    type, // user or client
    meta = null,
    ...rest
  } = props

  const user = useMemo(() => transpile(person, type), [person, type]),
    metaFields = useMemo(() => {
      if (!meta || !meta.length) return null

      return meta.map(({ Icon: MetaIcon, label }, i) => (
        <Group key={i} wrap="nowrap" gap={10} mt={3}>
          <MetaIcon stroke={1.5} size="1rem" className={classes.icon} />
          <Text fz="xs" c="dimmed">
            {label}
          </Text>
        </Group>
      ))
    })

  return (
    <Box {...rest}>
      <Group wrap="nowrap">
        {user.avatar ? (
          <Avatar
            src="https://raw.githubusercontent.com/mantinedev/mantine/master/.demo/avatars/avatar-2.png"
            size={94}
            radius="md"
          />
        ) : (
          <Avatar color="cyan" size={94} radius="md" alt={user.name}>{initialsFromName(user.name)}</Avatar>
        )}
        <div>
          <Text fz="xs" tt="uppercase" fw={700} c="dimmed">
            {user.business || user.role}
          </Text>

          <Text fz="lg" fw={500} className={classes.name}>
            {user.name}
          </Text>

          <Group wrap="nowrap" gap={10} mt={3}>
            <IconMail stroke={1.5} size="1rem" className={classes.icon} />
            <Text fz="xs" c="dimmed">
              {user.email}
            </Text>
          </Group>

          <Group wrap="nowrap" gap={10} mt={5}>
            <IconPhoneCall stroke={1.5} size="1rem" className={classes.icon} />
            <Text fz="xs" c="dimmed">
              {user.phone}
            </Text>
          </Group>

          {metaFields}
        </div>
      </Group>
    </Box>
  )
}
