import { Title as MantineTitle } from '@mantine/core'
import classes from './title.module.scss'

export const Title = (props) => {
  const { children, ...rest } = props

  return (
    <MantineTitle className={classes.wrapper} {...rest}>
      {children}
    </MantineTitle>
  )
}
