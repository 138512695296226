import { Badge } from '@mantine/core'
import { USER_ROLE_BADGE } from '../helper'

export const UserStatusBadge = (props) => {
  const { user } = props,
    role = user.role,
    { label: badgeLabel, color: badgeColor } = USER_ROLE_BADGE[role || ''] || {}

  return (
    <Badge mr="sm" variant="filled" color={badgeColor || 'black'}>{badgeLabel || '---'}</Badge>
  )
}
