import {
  Paper,
  createStyles,
  TextInput,
  Button,
  Title,
  Text,
  rem,
  Center,
  Container,
  Group,
  Box,
  SimpleGrid,
  Stack,
  Select,
  Flex,
  Loader,
  Textarea,
} from '@mantine/core'
import { IconAlertCircle } from '@tabler/icons-react'
import { useEffect, useState } from 'react'
import { StatusCodes } from 'http-status-codes'
import { Link, useLoaderData, useNavigate } from 'react-router-dom'
import { useForm, zodResolver } from '@mantine/form'
import { z } from 'zod'

import fetchService from '../../services/fetch.service'
import browserStorageService, { STORAGE_KEY } from '../../services/browser-storage.service'
import { TicketSpecsheet } from '../tickets/view'

const useStyles = createStyles((theme) => ({
  wrapper: {
    // minHeight: rem(900),
    // backgroundSize: 'cover',
    // backgroundImage: `url(${highway})`, //'url(../static/highway.jpg)',
    backgroundColor: '#eae6db',
    maxWidth: 'initial',
    minHeight: '100vh',

    // [theme.fn.smallerThan('sm')]: {
    //   // backgroundColor: theme.colors.yellow[6],
    //   height: '100%',
    // },
  },

  container: {
  },

  form: {
    width: rem(700),
    paddingTop: rem(80),
    backgroundColor: '#eeeeee',
    border: `${rem(1)} solid #aaa`,
    // color: '#f8f9f7',

    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      maxWidth: "80%",
    },

    // Simplify media query writing with theme functions
    [theme.fn.smallerThan('sm')]: {
      // backgroundColor: theme.colors.yellow[6],
      padding: rem(10),
    },
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },

  textInput: {
  },

  link: {
    // color: '#1d1224',
    position: 'relative',

    '&:hover': {
      textDecoration: 'none',
    },
  },

  errorWrap: {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    padding: theme.spacing.sm,
    marginTop: theme.spacing.md,
    borderLeft: `3px solid ${theme.colors.red[5]}`
  },
}))

export const ShareView = () => {
  const { classes } = useStyles(),
    [guest, setGuest] = useState(null),
    [ticket, setTicket] = useState(null),
    [loaded, setLoaded] = useState(false),
    [error, setError] = useState(false),
    sharedDetails = useLoaderData()

  useEffect(() => {
    if (!sharedDetails) return
    if (!sharedDetails.status || !sharedDetails.result) {
      setError(true)
      return
    }

    const { guest, ticket } = sharedDetails.result
    if (!guest || !ticket) {
      setError(true)
      return
    }

    setGuest(guest)
    setTicket(ticket)
    setLoaded(true)
  }, [sharedDetails])

  return (
    <Container className={classes.wrapper}>
      <Center id="center" mx="auto" data-aos="fade-up">
        <Paper className={classes.form} radius={0} p={30} mt="lg" mb="lg">
          <Title order={2} className={classes.title} ta="center" mb={50}>
            {error ? 'Unable to load ticket' : !ticket ? 'Loading Ticket...' : ticket.meta.name}
          </Title>

          <Container className={classes.container}>
            {error ? (
              <Text>The ticket you requested to view is either incorrect or your access has been revoked. If you believe this to be an error, please reach out to your project manager.</Text>
            ) : (
              <TicketSpecsheet loaded={loaded} ticket={ticket} />
            )}
          </Container>
        </Paper>
      </Center>
    </Container>
  );
}
