import { Center, Loader } from '@mantine/core'

export const LoadingData = (props) => {

  return (
    <Center mb="lg">
      <Loader size="lg" variant="dots" />
    </Center>
  )
}
