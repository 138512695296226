import { useEffect, useState } from 'react'
import {
  Container,
  Title,
  rem,
  ActionIcon,
  createStyles,
  Menu,
  ScrollArea,
  Box,
  Table,
  Center,
  Button,
  Text,
  NavLink,
} from '@mantine/core'
import {
  IconDotsVertical,
  IconTrash,
  IconPlus,
  IconSettings,
  IconLockShare,
  IconUserEdit,
  IconCheck,
} from '@tabler/icons-react'
import { notifications } from '@mantine/notifications'

import { noop } from '../../../utils/noop'
import { fetchClientsForCompany } from '../../tickets/modals/share-modal/actions'
import { ManageClientModal } from '../modals/manage-client-modal'
import { DeleteClientModal } from '../modals/delete-client-modal'
import { AddClientModal } from '../modals/add-client-modal'
import { EditClientModal } from '../modals/edit-client-modal'
import { Link } from 'react-router-dom'

const useStyles = createStyles((theme) => ({
  card: {
    overflow: 'inherit',
  },
  button: {
    borderRadius: theme.radius.sm,
    padding: `${theme.spacing.sm} ${theme.spacing.lg}`,
    cursor: 'pointer',
    height: rem('3.25rem'),
    maxWidth: rem('13rem'),
  },
  tableActionRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  tableHeader: {
    backgroundColor: '#ffffff',
  },
  tableHeadCol: {
    position: 'sticky',
    top: 0,
    backgroundColor: '#ffffff',
  },
  tableHeadColIcon: {
    zIndex: 1,
  },
}))

const ManageMenu = (props) => {
  const {
    client = {},
    children,
    onEdit = noop,
    onManageAccess = noop,
    onDeleteClient = noop,
  } = props

  const iconSize = 16

  if (!client) return null

  return (
    <Menu shadow="md" width={200}>
      <Menu.Target>{children}</Menu.Target>
      <Menu.Dropdown>
        <Menu.Label>{client.name}</Menu.Label>
        <Menu.Item
          onClick={onEdit}
          icon={<IconUserEdit size={iconSize} />}
        >
          Edit Client
        </Menu.Item>

        <Menu.Item
          onClick={onManageAccess}
          icon={<IconLockShare size={iconSize} />}
        >
          Manage Ticket Access
        </Menu.Item>

        <Menu.Item
          onClick={onDeleteClient}
          icon={<IconTrash size={iconSize} />}
        >
          Delete Client
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  )
}

export const ManageClientsForm = (props) => {
  const { company } = props,
    { classes, cx } = useStyles(),
    [ clients, setClients ] = useState(null),
    [editTarget, setEditTarget] = useState(null),
    [manageTarget, setManageTarget] = useState(null),
    [deleteTarget, setDeleteTarget] = useState(null),
    [showAddClientModal, setShowAddClientModal] = useState(false)


  const hydrateCompanyClients = () => {
    fetchClientsForCompany(company)
      .then((response) => {
        if (!response.ok) {
          setClients(null)
        } else {
          setClients(response.clients)
        }
      })
  }

  useEffect(() => {
    if (!company) return
    hydrateCompanyClients()
  }, [company])

  const tableRows = (clients || []).map((client, index) => {
    const ActionMenu = (props) => {
      const methods = {
        onEdit: () => setEditTarget(client),
        onManageAccess: () => setManageTarget(client),
        onDeleteClient: () => setDeleteTarget(client),
      }

      return (
        <ManageMenu client={client} {...methods}>
          <ActionIcon variant="transparent" color="blue" radius="xl" size="sm" aria-label="Options">
            <IconDotsVertical />
          </ActionIcon>
        </ManageMenu>
      )
    }

    return (
      <tr key={client.clientId}>
        <td>{client.name}</td>
        <td>{client.business || ''}</td>
        <td>{client.email || client.phone}</td>
        <td><Center><ActionMenu /></Center></td>
      </tr>
    )
  })

  const onAddClientSuccess = (client) => {
    setShowAddClientModal(false)
    notifications.show({
      id: 'client::add::success',
      color: 'teal',
      title: 'Client added',
      message: `Successfully added ${client.name} to your business profile. You can now share tickets with them and manage their access through your Company Settings.`,
      icon: <IconCheck style={{ width: rem(18), height: rem(18) }} />,
      loading: false,
      autoClose: false,
    })

    hydrateCompanyClients()
  }

  const handleDeleteSuccess = (client) => {
    hydrateCompanyClients()
    notifications.show({
      id: 'client::delete::success',
      color: 'teal',
      title: 'Client deleted',
      message: `${client.name} has been removed from your business profile and their access to previous tickets have been revoked. You can always invite them again in the future but will need to reshare tickets again.`,
      icon: <IconCheck style={{ width: rem(18), height: rem(18) }} />,
      loading: false,
      autoClose: false,
    })
  }

  const handleCloseDelete = () => {
    setDeleteTarget(null)
  }

  const handleRevokeSuccess = (client, ticket) => {
    notifications.show({
      id: 'client::revoke::success',
      color: 'teal',
      title: 'Client access revoked',
      message: `Client no longer has access to '${ticket.meta.name}'.`,
      icon: <IconCheck style={{ width: rem(18), height: rem(18) }} />,
      loading: false,
    })
  }

  const handleRevokeError = (client, ticket) => {
    notifications.show({
      id: 'client::revoke::error',
      color: 'teal',
      title: `Unable to revoke access to '${ticket.meta.name}'`,
      message: 'We hit a snag revoking access. Please try again or contact support.',
      icon: <IconCheck style={{ width: rem(18), height: rem(18) }} />,
      loading: false,
    })
  }

  const handleManageClose = () => {
    setManageTarget(null)
  }

  const onAddClientCancel = () => {
    setShowAddClientModal(false)
  }

  const handleEditSaved = (updatedClient) => {
    notifications.show({
      id: 'client::edit::success',
      color: 'teal',
      title: `Updated ${updatedClient.name}`,
      icon: <IconCheck style={{ width: rem(18), height: rem(18) }} />,
      loading: false,
    })

    hydrateCompanyClients()
  }

  const handleCloseEdit = () => {
    setEditTarget(null)
  }

  return (
    <Container p="0">
      <Title order={2} weight={500} mt="xl" mb="lg">Manage Clients</Title>
      <EditClientModal
        target={editTarget}
        company={company}
        onClose={handleCloseEdit}
        onSaved={handleEditSaved}
      />
      <ManageClientModal
        target={manageTarget}
        company={company}
        onRevokeSuccess={handleRevokeSuccess}
        onRevokeError={handleRevokeError}
        onClose={handleManageClose}
      />
      <DeleteClientModal
        target={deleteTarget}
        company={company}
        onSuccess={handleDeleteSuccess}
        onClose={handleCloseDelete}
      />
      <AddClientModal
        opened={showAddClientModal}
        onSuccess={onAddClientSuccess}
        onCancel={onAddClientCancel}
      />

      <Box mb="sm" className={classes.tableActionRow}>
        <Button
          onClick={() => setShowAddClientModal(!showAddClientModal)}
          leftIcon={<IconPlus size="1.125rem" />}
          pr={12}
        >
          Add client
        </Button>
      </Box>

      <ScrollArea mb="xl" h={400}>
        <Table highlightOnHover withColumnBorders withBorder striped>
          <thead className={classes.tableHeader}>
            <tr>
              <th className={classes.tableHeadCol}>Name</th>
              <th className={classes.tableHeadCol}>Business</th>
              <th className={classes.tableHeadCol}>Email</th>
              <th className={cx(classes.tableHeadCol, classes.tableHeadColIcon)}>
                <Center><IconSettings /></Center>
              </th>
            </tr>
          </thead>
          {!clients || !clients.length ? (
            <Text mb="md" pl="sm" mt="md">
              No clients stored yet, <Link onClick={() => setShowAddClientModal(!showAddClientModal)}>add a new client</Link>
            </Text>
          ) : (
            <tbody>
              {tableRows}
            </tbody>
          )}
        </Table>
      </ScrollArea>

      
    </Container>
  )
}
