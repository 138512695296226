/**
 * Return the initials from a given name
 * Ex
 *  'John Smith' => 'JS'
 *  'Literally what even' => 'LWE'
 * 
 * @param {*} name 
 * @returns 
 */
export const initialsFromName = (name = '') => {
  return String(name).split(' ').map(word => word.toUpperCase()[0]).join('')
}
