import { Grid, Skeleton, Container, Card, Title, Loader, Center, Box, Text, Button, Modal } from '@mantine/core';
import { useEffect, useMemo, useState } from 'react';
import fetchService from '../../services/fetch.service';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { ErrorSection } from '../../common/error-section';
import { ProductTable } from '../../common/product-table';
import browserStorageService, { STORAGE_KEY } from '../../services/browser-storage.service';

const child = <Skeleton height={140} radius="md" animate={false} />;

const LoadingData = () => (
  <Center mt="md">
    <Loader color="blue" size="sm" />
  </Center>
)

const SubscriptionCard = (props) => {
  const {
    loading,
    subscription,
    invoices,
  } = props

  const [creatingCheckout, setCreatingCheckout] = useState(false),
    [products, setProducts] = useState([]),
    [user, setUser] = useState(null),
    [showProducts, setShowProducts] = useState(false)

  const portalLink = process.env.NODE_ENV === 'production'
    ? 'https://billing.stripe.com/p/login/dR61708ddegBb3GaEE'
    : 'https://billing.stripe.com/p/login/test_dR67sR1mK4mzgSY6oo'

  const isTrialActive = useMemo(() => {
    if (!subscription) return false
    const trialPeriodEnds = dayjs.unix(subscription.trialStartUnix),
      nowDate = dayjs()

    return trialPeriodEnds.isAfter(nowDate)
  }, [subscription])

  const latestInvoice = useMemo(() => {
    if (!invoices || !Array.isArray(invoices)) return null
    return invoices[0]
  }, [invoices])

  useEffect(() => {
    const loadProducts = async () => {
      const { result } = await fetchService.get('/public/products')
      console.log(result.products)
      return result.products
    }

    if (loading && subscription) return

    loadProducts()
      .then(setProducts)
      .catch((err) => {
        console.warn('Unable to load products', err)
      })
  }, [loading, subscription])

  useEffect(() => {
    browserStorageService.getItem(STORAGE_KEY.SESSION)
      .then((session) => {
        console.log('session', session)
        setUser(session)
        // setCanEdit([ticket?.refs.submittedBy].includes(/session.userId) || canModify)
      })
  }, [setUser])

  if (loading) return (
    <LoadingData />
  )

  if (subscription && subscription.error) return (
    <>
      <ErrorSection withIcon={false} message="We had trouble loading your subscription details." />
      <Text size="sm" mb="sm">You can manage your subscription via Stripe using the link below.</Text>
      <Text fw={500} size="sm">
        <Link to={portalLink} target="_blank">Stripe Customer Portal</Link>
      </Text>
    </>
  )

  const createSubscription = () => {
    // setCreatingCheckout(true)
    setShowProducts(true)
    // setTimeout(() => {
    //   setCreatingCheckout(false)
    // }, 5000)
  }

  return (
    <>
      <Modal opened={showProducts} onClose={() => { setShowProducts(false) }} title="Select Subscription">
        <Text mb="lg">
          Our available service subscriptions are available below.
          Save 30% by subscribing to our annual subscription. Each subscription includes a 30 day trial.
        </Text>

        <ProductTable
          products={products}
          user={user}
        />
      </Modal>

      {isTrialActive ? (
        <Box>
          <Text size="xs" color="dimmed">Trial Ends</Text>
          <Text fw={500} size="sm" color="blue">{subscription.trialEnd}</Text>
        </Box>
      ) : null}

      {subscription ? (
        <>
          <Box mt="sm">
            <Text size="xs" color="dimmed">Plan</Text>
            <Text fw={500} size="sm">{subscription.product.name}</Text>
          </Box>

          <Box mt="sm">
            <Text size="xs" color="dimmed">Manage</Text>
            <Text fw={500} size="sm">
              <Link to={portalLink} target="_blank">Stripe Customer Portal</Link>
            </Text>
          </Box>

          <Box mt="sm">
            <Text size="xs" color="dimmed">Renews</Text>
            <Text fw={500} size="sm">{subscription.periodEnd}</Text>
          </Box>
        </>
      ) : (
        <>
          <Box mt="sm" mb="lg">
            <Text size="xs" color="dimmed">Plan</Text>
            <Text fw={500} size="sm">No active subscription</Text>
          </Box>

          <Button radius="sm" size="sm" onClick={createSubscription} disabled={showProducts}>
            {showProducts ? (<Loader color="blue" size="sm" />) : 'Create Subscription'}
          </Button>
        </>
      )}

      {latestInvoice ? (
        <>
          <Title order={5} mt="lg">Latest Invoice</Title>
          <Box mt="sm">
            <Text size="xs" color="dimmed">Invoice PDF</Text>
            <Text fw={500} size="sm">
              <Link to={latestInvoice.downloadInvoice} target="_blank">Download</Link>
            </Text>
            <Text fw={500} size="sm">
              <Link to={latestInvoice.hostedInvoice} target="_blank">View on Stripe</Link>
            </Text>
          </Box>
          {latestInvoice.nextChargeAttempt ? (
            <>
              <Box mt="sm">
                <Text size="xs" color="dimmed">Amount Due</Text>
                <Text fw={500} size="sm">{latestInvoice.amountDue}</Text>
              </Box>
              <Box mt="sm">
                <Text size="xs" color="dimmed">Next Payment Attempt</Text>
                <Text fw={500} size="sm">{latestInvoice.nextChargeAttempt}</Text>
              </Box>
            </>
          ) : null}
        </>
      ) : null}
    </>
  )
}

const CompanyCard = (props) => {
  const {
    company,
  } = props

  if (!company) return (
    <LoadingData />
  )

  const {
    address = '<no address>',
    city = '<no city>',
    state = '<no state>',
    zipcode = '<no zipcode>',
  } = company

  return (
    <>
      <Box mt="sm">
        <Text size="xs" color="dimmed">Name</Text>
        <Text fw={500} size="sm">{company.name}</Text>
      </Box>

      <Box mt="sm">
        <Text size="xs" color="dimmed">Status</Text>
        <Text fw={500} size="sm" color={company.status ? 'blue' : 'red'}>
          {company.status ? 'ACTIVE' : 'INACTIVE'}
        </Text>
      </Box>

      <Box mt="sm">
        <Text size="xs" color="dimmed">Address</Text>
        <Text fw={500} size="sm">
          {address}
          <br />
          {city}, {state} {zipcode}
        </Text>
      </Box>
    </>
  )
}

const DashboardHomeView = () => {

  const [subscription, setSubscription] = useState(null),
    [loadingSubscription, setLoadingSubscription] = useState(true),
    [invoices, setInvoices] = useState(null),
    [company, setCompany] = useState(null)

  useEffect(() => {
    const fetchSubscription = async () => {
      const { result } = await fetchService.get('/auth/subscription')
      return result
    }

    fetchSubscription()
      .then(({ subscription, invoices }) => {
        console.log({ subscription, invoices })
        setSubscription(subscription)
        setInvoices(invoices)
      })
      .catch((err) => {
        console.warn('bad err', err)
        setSubscription({ error: 'Unable to load subscription details' })
      })
      .finally(() => {
        setLoadingSubscription(false)
      })
  }, [])

  useEffect(() => {
    const fetchCompany = async () => {
      const { result } = await fetchService.post('/auth/me/company')
      return result
    }

    fetchCompany()
      .then(({ company }) => {
        console.log(company)
        setCompany(company)
      })
      .catch((err) => {
        console.warn('bad err', err)
      })
  }, [])

  return (
    <Container my="md">
      <Title order={2}>Dashboard</Title>

      <Grid mt="lg">
        <Grid.Col xs={4}>
          <Card>
            <Title order={4}>Subscription</Title>
            <SubscriptionCard subscription={subscription} invoices={invoices} loading={loadingSubscription} />
          </Card>
        </Grid.Col>
        <Grid.Col xs={8}>
          <Card>
            <Title order={4}>Company</Title>
            <CompanyCard company={company} />
          </Card>
        </Grid.Col>
      </Grid>
    </Container>
  );
}

export { DashboardHomeView }
