
import { useEffect, useState } from 'react'
import { useLoaderData, useNavigate } from 'react-router-dom'
import { ManageCompanyForm } from './form/manage-company'
import { ManageClientsForm } from './form/manage-clients'


export const transpile = (importedSpec = null) => {
  console.log('import', importedSpec)
  if (!importedSpec || importedSpec === undefined) return null

  const {
    name,
    phone,
    address,
    city,
    state,
    zipcode,
    status,
  } = importedSpec

  return {
    name,
    phone,
    address,
    city,
    state,
    zipcode,
    status,
  }
}

export const ManageCompanyView = () => {
  const importedCompany = useLoaderData()

  return (
    <>
      <ManageCompanyForm company={importedCompany} />
      <ManageClientsForm company={importedCompany} />
    </>
  )
}
