import { Box, Flex, Text, createStyles } from "@mantine/core";
import { IconAlertCircle } from "@tabler/icons-react";

const useStyles = createStyles((theme) => ({
  errorWrap: {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    padding: theme.spacing.sm,
    marginTop: theme.spacing.md,
    borderLeft: `3px solid ${theme.colors.red[5]}`
  },
}));

export const ErrorSection = (props) => {
  const { classes } = useStyles()
  const {
    children,
    message,
    size = '1.5rem',
    withIcon = true,
  } = props

  return (
    <Box className={classes.errorWrap} mb="md">
      <Flex>
        {withIcon ? <IconAlertCircle size={size} color="red" /> : null}
        {message ? (
          <Text fz="sm" ml={withIcon ? 'md' : '0'} color="red">{message}</Text>
        ) : children}
      </Flex>
    </Box>
  )
}
