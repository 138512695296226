import { Box, Text, Title as MantineTitle, Table } from '@mantine/core'
import { HomeHero } from './components/hero'
import { Container } from './components/container'
import { Title } from './components/title'
import { FooterLinks } from './footer'

import classes from './view.module.scss'
import { Link } from 'react-router-dom'

const PageTitle = () => (
  <>eConcretely Privacy Policy</>
)

const PageSubtitle = () => (
  <>Effective Date: March 2024</>
)

export const PrivacyPolicyView = (props) => {
  return (
    <Box className={classes.homeView}>
      <HomeHero
        Title={PageTitle}
        Subtitle={PageSubtitle}
      />
      
      <Container>
        <Text component="p" size="sm">
          At Initrun LLC, we take your privacy seriously. Please read this Privacy Policy to learn how we treat your personal data. <strong>By using or accessing our Services in any manner, you acknowledge that you accept the practices and policies outlined below, and you hereby consent that we will collect, use and share your information as described in this Privacy Policy.</strong>
          <br/>
          Remember that your use of Initrun LLC Services is at all times subject to our <Link to="/terms-of-service">Terms of Service</Link>, which incorporates this Privacy Policy. Any terms we use in this Policy without defining them have the definitions given to them in the <Link to="/terms-of-service">Terms of Service</Link>.
          <br/>
          As we continually work to improve our Services, we may need to change this Privacy Policy from time to time. Upon such changes, we will alert you to any such changes by placing a notice on the eConcrete.ly website, by sending you an email and/or by some other means. Please note that if you’ve opted not to receive legal notice emails from us (or you haven’t provided us with your email address), those legal notices will still govern your use of the Services, and you are still responsible for reading and understanding them. If you use the Services after any changes to the Privacy Policy have been posted, that means you agree to all of the changes.
        </Text>

        <Container p={0}>
          <Title order={3}>What this Privacy Policy Covers</Title>
          <Text component="p">
            This Privacy Policy covers how we treat Personal Data that we gather when you access or use our Services. "Personal Data" means any information that identifies or relates to a particular individual and also includes information referred to as "personally identifiable information" or "personal information" or "sensitive personal information" under applicable data privacy laws, rules or regulations. This Privacy Policy does not cover the practices of companies we don’t own or control or people we don’t manage.
          </Text>

          <Title order={3}>Personal Data</Title>
          <Title order={4}>Categories of Personal Data We Collect</Title>
          <Text component="p">
            This chart details the categories of Personal Data that we collect and have collected over the past 12 months:
          </Text>

          <Table>
            <thead>
              <tr>
                <th>Category of Personal Data</th>
                <th>Examples of Personal Data We Collect</th>
                <th>Categories of Third Parties With Whom We Share this Personal Data:</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Profile or Contact Data</td>
                <td>
                  <ul>
                    <li>First and last name</li>
                    <li>Email</li>
                    <li>Phone Number</li>
                    <li>Unique identifiers such as account name and passwords.</li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>Service Providers</li>
                    <li>Analytics Partners</li>
                    <li>Business Partners</li>
                    <li>Parties You Authorize, Access or Authenticate</li>
                  </ul>
                </td>
              </tr>

              <tr>
                <td>Payment Data</td>
                <td>
                  <ul>
                    <li>Financial account information</li>
                    <li>Payment type</li>
                    <li>Last 4 digits of payment card</li>
                    <li>Billing address, phone number, and email.</li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>Service Providers (specifically our payment processing partner, currently Stripe, Inc</li>
                  </ul>
                </td>
              </tr>

              <tr>
                <td>Device/IP Data</td>
                <td>
                  <ul>
                    <li>IP address</li>
                    <li>Device ID</li>
                    <li>Type of device/operating system/browser used to access the Services</li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>Service Providers</li>
                    <li>Analytics Partners</li>
                    <li>Business Partners</li>
                    <li>Parties You Authorize, Access or Authenticate</li>
                  </ul>
                </td>
              </tr>

              <tr>
                <td>Web Analytics</td>
                <td>
                  <ul>
                    <li>Web page interactions</li>
                    <li>Referring webpage/source through which you accessed the Services</li>
                    <li>Non-identifiable request IDs</li>
                    <li>Statistics associated with the interaction between device or browser and the Services</li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>Service Providers</li>
                    <li>Analytics Partners</li>
                    <li>Business Partners</li>
                    <li>Parties You Authorize, Access or Authenticate</li>
                  </ul>
                </td>
              </tr>

              <tr>
                <td>Geolocation Data</td>
                <td>
                  <ul>
                    <li>IP-address-based location information</li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>Service Providers</li>
                  </ul>
                </td>
              </tr>

              <tr>
                <td>Photos, Videos and Recordings</td>
                <td>
                  <ul>
                    <li>Photos, videos or recordings that you submit to the Services</li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>Service Providers</li>
                    <li>Analytics Partners</li>
                    <li>Business Partners</li>
                    <li>Parties You Authorize, Access or Authenticate</li>
                  </ul>
                </td>
              </tr>

              <tr>
                <td>Categories of Data Considered "Sensitive" Under the California Privacy Rights Act and the Virginia Consumer Data Protection Act</td>
                <td>
                  <ul>
                    <li>Personal identification numbers, including social security, driver’s license, passport, or state ID card numbers</li>
                    <li>A person’s account log-in, financial account, debit card, or credit card number in combination with any required security or access code, password, or credentials allowing access to an account</li>
                    <li>Precise geolocation data</li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>Service Providers</li>
                    <li>Analytics Partners</li>
                    <li>Business Partners</li>
                    <li>Parties You Authorize, Access or Authenticate</li>
                  </ul>
                </td>
              </tr>

              <tr>
                <td>Other Identifying Information that You Voluntarily Choose to Provide</td>
                <td>
                  <ul>
                    <li>Identifying information in emails, letters, texts, or other communications you send us</li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>Service Providers</li>
                    <li>Analytics Partners</li>
                    <li>Business Partners</li>
                    <li>Parties You Authorize, Access or Authenticate</li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </Table>

          <Title order={4}>Categories of Sources of Personal Data</Title>
          <Text component="p">
            We collect Personal Data about you from the following categories of sources:
          </Text>
          <ul>
            <li>
              <strong>You</strong>
              <ul>
                <li>
                  When you provide such information directly to us
                  <ul>
                    <li>When you create an account or use our interactive tools and Services.</li>
                    <li>When you voluntarily provide information in free-form text boxes through the Services or through responses to surveys or questionnaires.</li>
                    <li>When you send us an email or otherwise contact us.</li>
                  </ul>
                </li>

                <li>
                  When you use the Services and such information is collected automatically
                  <ul>
                    <li>Through Cookies (defined in the "Tracking Tools and Opt-Out" section below).</li>
                    <li>If you download our mobile application or use a location-enabled browser, we may receive information about your location and mobile device, as applicable.</li>
                    <li>If you download and install certain applications and software we make available, we may receive and collect information transmitted from your computing device for the purpose of providing you the relevant Services, such as information regarding when you are logged on and available to receive updates or alert notices.</li>
                  </ul>
                </li>
              </ul>
            </li>

            <li>
              <strong>Third Parties</strong>
              <ul>
                <li>
                  Vendors
                  <ul>
                    <li>We may use analytics providers to analyze how you interact and engage with the Services, or third parties may help us provide you with customer support.</li>
                    <li>We may use vendors to obtain information to generate leads and create user profiles.</li>
                  </ul>
                </li>

                <li>
                  Social Networks
                  <ul>
                    <li>If you provide your social network account credentials to us or otherwise sign in to the Services through a third-party site or service, some content and/or information in those accounts may be transmitted into your account with us.</li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>

          <Title order={4}>Our Commercial or Business Purposes for Collecting or Disclosing Personal Data</Title>
          <ul>
            <li>
              <strong>Providing, Customizing and Improving the Services</strong>
              <ul>
                <li>Creating and managing your account or other user profiles.</li>
                <li>Processing orders or other transactions; billing.</li>
                <li>Providing you with the products, services or information you request.</li>
                <li>Meeting or fulfilling the reason you provided the information to us.</li>
                <li>Providing support and assistance for the Services.</li>
                <li>Improving the Services, including testing, research, internal analytics and product development.</li>
                <li>Personalizing the Services, website content and communications based on your preferences.</li>
                <li>Doing fraud protection, security and debugging.</li>
                <li>Carrying out other business purposes stated when collecting your Personal Data or as otherwise set forth in applicable data privacy laws, such as the California Consumer Privacy Act, as amended by the California Privacy Rights Act of 2020 (the "CPRA").</li>
              </ul>
            </li>
            <li>
              <strong>Marketing the Services</strong>
              <ul>
                <li>Marketing and selling the Services.</li>
              </ul>
            </li>
            <li>
              <strong>Corresponding with You</strong>
              <ul>
                <li>Responding to correspondence that we receive from you, contacting you when necessary or requested, and sending you information about eConcretely, Initrun LLC or the Services.</li>
                <li>Sending emails and other communications according to your preferences or that display content that we think will interest you.</li>
              </ul>
            </li>
            <li>
              <strong>Meeting Legal Requirements and Enforcing Legal Terms</strong>
              <ul>
                <li>Fulfilling our legal obligations under applicable law, regulation, court order or other legal process, such as preventing, detecting and investigating security incidents and potentially illegal or prohibited activities.</li>
                <li>Protecting the rights, property or safety of you, eConcretely, Initrun LLC or another party.</li>
                <li>Enforcing any agreements with you.</li>
                <li>Responding to claims that any posting or other content violates third-party rights.</li>
                <li>Resolving disputes.</li>
              </ul>
            </li>
          </ul>
          <Text component="p">
            We will not collect additional categories of Personal Data or use the Personal Data we collected for materially different, unrelated or incompatible purposes without providing you notice. If you are a California resident, please note that we only use or disclose your sensitive personal information for the purposes set forth in section 7027(m) of the CPRA regulations and we do not collect or process sensitive personal information with the purpose of inferring any characteristics about California residents.
          </Text>

          <Title order={4}>How We Disclose Your Personal Data</Title>
          <Text component="p">
            We disclose your Personal Data to the categories of service providers and other parties listed in this section. Depending on state laws that may be applicable to you, some of these disclosures may constitute a "sale" of your Personal Data. For more information, please refer to the state-specific sections below.
          </Text>
          <ul>
            <li>
              <strong>Service Providers.</strong> These parties help us provide the Services or perform business functions on our behalf. They include:
              <ul>
                <li>Hosting, technology and communication providers.</li>
                <li>Security and fraud prevention vendors.</li>
                <li>Support and customer service vendors.</li>
                <li>Product fulfillment and delivery providers.</li>
                <li>Payment processors</li>
              </ul>
            </li>
            <li>
              <strong>Analytics Partners.</strong> These parties provide analytics on web traffic or usage of the Services. They include:
              <ul>
                <li>Companies that track how users found or were referred to the Services.</li>
                <li>Companies that track how users interact with the Services.</li>
              </ul>
            </li>
            <li>
              <strong>Business Partners.</strong> These parties partner with us in offering various services. They include:
              <ul>
                <li>Businesses that you have a relationship with.</li>
                <li>Companies that we partner with to offer joint promotional offers or opportunities.</li>
              </ul>
            </li>
            <li>
              <strong>Parties You Authorize, Access or Authenticate.</strong>
              <ul>
                <li>Third parties you access through the services.</li>
                <li>Social media services.</li>
                <li>Other users.</li>
              </ul>
            </li>
          </ul>

          <Title order={4}>Legal Obligations</Title>
          <Text component="p">
            We may share any Personal Data that we collect with third parties in conjunction with any of the activities set forth under "Meeting Legal Requirements and Enforcing Legal Terms" in the "Our Commercial or Business Purposes for Collecting Personal Data" section above.
          </Text>

          <Title order={4}>Business Transfers</Title>
          <Text component="p">
            All of your Personal Data that we collect may be transferred to a third party if we undergo a merger, acquisition, bankruptcy or other transaction in which that third party assumes control of our business (in whole or in part). Should one of these events occur, we will make reasonable efforts to notify you before your information becomes subject to different privacy and security policies and practices.
          </Text>

          <Title order={4}>Data that is Not Personal Data</Title>
          <Text component="p">
            We may create aggregated, de-identified or anonymized data from the Personal Data we collect, including by removing information that makes the data personally identifiable to a particular user. We may use such aggregated, de-identified or anonymized data and share it with third parties for our lawful business purposes, including to analyze, build and improve the Services and promote our business, provided that we will not share such data in a manner that could identify you.
          </Text>

          <Title order={3}>Tracking Tools and Opt-Out</Title>
          <Text component="p">
            The Services use cookies and similar technologies such as pixel tags, web beacons, clear GIFs and JavaScript (collectively, "Cookies") to enable our servers to recognize your web browser, tell us how and when you visit and use our Services, analyze trends, learn about our user base and operate and improve our Services. Cookies are small pieces of data– usually text files – placed on your computer, tablet, phone or similar device when you use that device to access our Services. We may also supplement the information we collect from you with information received from third parties, including third parties that have placed their own Cookies on your device(s). [Please note that because of our use of Cookies, the Services do not support "Do Not Track" requests sent from a browser at this time.]
            <br/>
            We use the following types of Cookies:
          </Text>
          <ul>
            <li><strong>Essential Cookies.</strong> Essential Cookies are required for providing you with features or services that you have requested. For example, certain Cookies enable you to log into secure areas of our Services. Disabling these Cookies may make certain features and services unavailable.</li>
            <li><strong>Functional Cookies.</strong> Functional Cookies are used to record your choices and settings regarding our Services, maintain your preferences over time and recognize you when you return to our Services. These Cookies help us to personalize our content for you, greet you by name and remember your preferences (for example, your choice of language or region).</li>
            <li><strong>Performance/Analytical Cookies.</strong> Performance/Analytical Cookies allow us to understand how visitors use our Services. They do this by collecting information about the number of visitors to the Services, what pages visitors view on our Services and how long visitors are viewing pages on the Services. Performance/Analytical Cookies also help us measure the performance of our advertising campaigns in order to help us improve our campaigns and the Services’ content for those who engage with our advertising.</li>
          </ul>
          <Text component="p">
            You can decide whether or not to accept Cookies through your internet browser’s settings. Most browsers have an option for turning off the Cookie feature, which will prevent your browser from accepting new Cookies, as well as (depending on the sophistication of your browser software) allow you to decide on acceptance of each new Cookie in a variety of ways. You can also delete all Cookies that are already on your device. If you do this, however, you may have to manually adjust some preferences every time you visit our website and some of the Services and functionalities may not work.
          </Text>
          <Text component="p">
            To find out more information about Cookies generally, including information about how to manage and delete Cookies, please visit <Link to="https://www.allaboutcookies.org/">https://www.allaboutcookies.org/</Link> or <Link to="https://ico.org.uk/for-the-public/online/cookies/">https://ico.org.uk/for-the-public/online/cookies/</Link> if you are located in the European Union.
          </Text>

          <Title order={3}>Data Security</Title>
          <Text component="p">
            We seek to protect your Personal Data from unauthorized access, use and disclosure using appropriate physical, technical, organizational and administrative security measures based on the type of Personal Data and how we are processing that data. You should also help protect your data by appropriately selecting and protecting your password and/or other sign-on mechanism; limiting access to your computer or device and browser; and signing off after you have finished accessing your account. Although we work to protect the security of your account and other data that we hold in our records, please be aware that no method of transmitting data over the internet or storing data is completely secure.
          </Text>

          <Title order={3}>Data Retention</Title>
          <Text component="p">
            We retain Personal Data about you for as long as necessary to provide you with our Services or to perform our business or commercial purposes for collecting your Personal Data. When establishing a retention period for specific categories of data, we consider who we collected the data from, our need for the Personal Data, why we collected the Personal Data, and the sensitivity of the Personal Data. In some cases we retain Personal Data for longer, if doing so is necessary to comply with our legal obligations, resolve disputes or collect fees owed, or is otherwise permitted or required by applicable law, rule or regulation. We may further retain information in an anonymous or aggregated form where that information would not identify you personally.
            <br/>
            For example:
          </Text>
          <ul>
            <li>We retain your profile information and credentials for as long as you have an account with us.</li>
            <li>We retain your payment data for as long as we need to process your purchase or subscription.</li>
            <li>We retain your device/IP data for as long as we need it to ensure that our systems are working appropriately, effectively and efficiently.</li>
          </ul>
          
          <Title order={3}>Personal Data of Children</Title>
          <Text component="p">
            As noted in the Terms of Service, we do not knowingly collect or solicit Personal Data about children under 16 years of age; if you are a child under the age of 16, please do not attempt to register for or otherwise use the Services or send us any Personal Data. If we learn we have collected Personal Data from a child under 16 years of age, we will delete that information as quickly as possible. If you believe that a child under 16 years of age may have provided Personal Data to us, please contact us at <Link to="mailto:support@econcrete.ly">support@econcrete.ly</Link>.
          </Text>

          <Title order={3}>Other State Law Privacy Rights</Title>
          <Title order={4}>California Resident Rights</Title>
          <Text component="p">
            Under California Civil Code Sections 1798.83-1798.84, California residents are entitled to contact us to prevent disclosure of Personal Data to third parties for such third parties’ direct marketing purposes; in order to submit such a request, please contact us at <Link to="mailto:support@econcrete.ly">support@econcrete.ly</Link>.

            Your browser may offer you a "Do Not Track" option, which allows you to signal to operators of websites and web applications and services that you do not wish such operators to track certain of your online activities over time and across different websites. Our Services do not support Do Not Track requests at this time. To find out more about "Do Not Track," you can visit <Link to="www.allaboutdnt.com">www.allaboutdnt.com</Link>.
          </Text>
          <Title order={4}>Nevada Resident Rights</Title>
          <Text component="p">
            If you are a resident of Nevada, you have the right to opt-out of the sale of certain Personal Data to third parties who intend to license or sell that Personal Data. You can exercise this right by contacting us at <Link to="mailto:support@econcrete.ly">support@econcrete.ly</Link> with the subject line "Nevada Do Not Sell Request" and providing us with your name and the email address associated with your account. Please note that we do not currently sell your Personal Data as sales are defined in Nevada Revised Statutes Chapter 603A.
          </Text>

          <Title order={3}>Contact Information</Title>
          <Text component="p">
            If you have any questions or comments about this Privacy Policy, the ways in which we collect and use your Personal Data or your choices and rights regarding such collection and use, please do not hesitate to contact us at:
          </Text>
          <ul>
            <li><Link to="mailto:support@econcrete.ly">support@econcrete.ly</Link></li>
          </ul>
        </Container>
      </Container>

      <FooterLinks />
    </Box>
  )
}
