
import fetchService from './fetch.service'
import browserStorageService from './browser-storage.service'

export class SessionService {

  constructor() {
  }

  loadCurrentUser = async () => {
    try {
      const { result } = await fetchService.post('/auth/me'),
        { user, preferences } = result

      if (!browserStorageService.initialized) {
        browserStorageService.initialize(user.userId)
        await browserStorageService.createSession(user, { preferences })
      } else {
        await browserStorageService.updateSession(user, { preferences })
      }
    
      // TODO implement
      // if (user.flags.blocked) {
      //   navigate('/dashboard/logout')
      // }

      return { user, preferences }
    } catch (err) {
      console.warn('Unable to load current user session', err)
      return null
    }
  }
}

const sessionService = new SessionService()
export default sessionService
