import { useMemo, useState, useEffect } from 'react'
import { Select, Title, createStyles } from '@mantine/core'
import browserStorageService, { STORAGE_KEY } from '../../../services/browser-storage.service'
import { ROLES, formatRole, userCanModify } from '../helper'

const useStyles = createStyles((theme) => ({
  title: {
    position: 'relative',

    '&::before': {
      content: '""',
      width: '100%',
      height: '1px',
      backgroundColor: '#ece',
      top: '50%',
      position: 'absolute',
    },
  },

  'title__span': {
    backgroundColor: '#fff',
    position: 'relative',
    paddingRight: '1rem',
  },
}))

export const SelectUserRole = (props) => {
  const { classes } = useStyles(),
    [canModify, setCanModify] = useState(false),
    [isSuper, setSuper] = useState(false),
    [isAdmin, setAdmin] = useState(false),
    [isOwner, setOwner] = useState(false),
    { dropdownPosition = undefined, ...formProps } = props || {}

  useEffect(() => {
    browserStorageService.getItem(STORAGE_KEY.ROLE)
      .then((role) => {
        setCanModify(userCanModify(role))
        if (role === ROLES.SUPER) setSuper(true)
        else if (role === ROLES.ADMIN) setAdmin(true)
        else if (role === ROLES.OWNER) setOwner(true)
      })
  }, [browserStorageService.initialized, setCanModify, setSuper, setAdmin, setOwner])

  const RoleOptions = useMemo(() => {
    console.log('ROLEOPTIONS', {init: browserStorageService.initialized, canModify, isSuper, isAdmin, isOwner})
    const roles = [ROLES.OPERATOR, ROLES.DRIVER] // TODO incorporate driver role
    if (isOwner) roles.push(ROLES.OWNER)
    else if (isAdmin) {
      roles.push(ROLES.OWNER)
      roles.push(ROLES.ADMIN)
    } else if (isSuper) {
      roles.push(ROLES.OWNER)
      roles.push(ROLES.ADMIN)
      roles.push(ROLES.SUPER)
    }

    return Object.values(roles).map((roleKey) => ({
      label: formatRole(roleKey),
      value: roleKey,
    }))
  }, [browserStorageService.initialized, canModify, isSuper, isAdmin, isOwner])

  return (
    <Select
      label="Role"
      placeholder="Select Role"
      name="role"
      data={RoleOptions}
      dropdownPosition={dropdownPosition}
      mb="sm"
      {...formProps}
    />
  )
}
