import {
  Paper,
  createStyles,
  TextInput,
  Button,
  Title,
  Text,
  rem,
  Center,
  Container,
  Group,
  Box,
  SimpleGrid,
  Stack,
  Select,
  Flex,
  Loader,
  Textarea,
} from '@mantine/core'
import { IconAlertCircle } from '@tabler/icons-react'
import { useEffect, useState } from 'react'
import { StatusCodes } from 'http-status-codes'
import { Link, useNavigate } from 'react-router-dom'
import { useForm, zodResolver } from '@mantine/form'
import { z } from 'zod'

import fetchService from '../../services/fetch.service'
import browserStorageService, { STORAGE_KEY } from '../../services/browser-storage.service'

const useStyles = createStyles((theme) => ({
  wrapper: {
    // minHeight: rem(900),
    // backgroundSize: 'cover',
    // backgroundImage: `url(${highway})`, //'url(../static/highway.jpg)',
    backgroundColor: '#eae6db',
    maxWidth: 'initial',
    minHeight: '100vh',

    // [theme.fn.smallerThan('sm')]: {
    //   // backgroundColor: theme.colors.yellow[6],
    //   height: '100%',
    // },
  },

  container: {
  },

  form: {
    width: rem(700),
    paddingTop: rem(80),
    backgroundColor: '#eeeeee',
    border: `${rem(1)} solid #aaa`,
    // color: '#f8f9f7',

    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      maxWidth: "80%",
    },

    // Simplify media query writing with theme functions
    [theme.fn.smallerThan('sm')]: {
      // backgroundColor: theme.colors.yellow[6],
      padding: rem(10),
    },
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },

  textInput: {
  },

  link: {
    // color: '#1d1224',
    position: 'relative',

    '&:hover': {
      textDecoration: 'none',
    },
  },

  errorWrap: {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    padding: theme.spacing.sm,
    marginTop: theme.spacing.md,
    borderLeft: `3px solid ${theme.colors.red[5]}`
  },

}));

const personalSchema = z.object({
  name: z.string().min(3, { message: 'Your name should be more than 3 characters' }),
  email: z.string().email({ message: 'Your email does not look right' }),
  phone: z.string().min(10, { message: 'Your phone number does not look right' }),
  reason: z.string().min(3, { message: 'You must select a reason for contacting' }),
})

export const ContactView = () => {
  const { classes, cx } = useStyles(),
    navigate = useNavigate(),
    [user, setUser] = useState(null)

  const [submitting, setSubmitting] = useState(false),
    [completed, setCompleted] = useState(false),
    [error, setError] = useState('')

  const isNumericInput = (event) => {
    const key = event.keyCode
    return ((key >= 48 && key <= 57) || // Allow number line
        (key >= 96 && key <= 105) // Allow number pad
    )
  }

  const isModifierKey = (event) => {
    const key = event.keyCode
    return (event.shiftKey === true || key === 35 || key === 36) || // Allow Shift, Home, End
      (key === 8 || key === 9 || key === 13 || key === 46) || // Allow Backspace, Tab, Enter, Delete
      (key > 36 && key < 41) || // Allow left, up, right, down
      (
        // Allow Ctrl/Command + A,C,V,X,Z
        (event.ctrlKey === true || event.metaKey === true) &&
        (key === 65 || key === 67 || key === 86 || key === 88 || key === 90)
      )
  }

  const enforceFormat = (event) => {
    const isNumeric = isNumericInput(event),
      isModifier = isModifierKey(event)

    // Input must be of a valid number format or a modifier key, and not longer than ten digits
    if (!isNumeric && !isModifier) event.preventDefault()
  }

  const formatPhone = (value) => {
    const input = value.replace(/\D/g, '').substring(0, 10), // First ten digits of input only
      areaCode = input.substring(0, 3),
      middle = input.substring(3, 6),
      last = input.substring(6, 10)

    if (input.length > 6) value = `(${areaCode}) ${middle} ${last}`
    else if (input.length > 3) value = `(${areaCode}) ${middle}`
    else if (input.length > 0) value = `(${areaCode}`
    return value
  }

  const personal = useForm({
    initialValues: {
      name: '',
      email: '',
      phone: '',
      reason: '',
      message: '',
      businessName: '',
    },

    validate: zodResolver(personalSchema.required()),

    transformValues: (values) => ({
      formattedPhone: formatPhone(values.phone),
    }),
  })

  const onPhoneInput = (event) => {
    const { target: { value = '', name = '' } = {} } = event || {}
    if (name === 'personal.phone') {
      personal.setFieldValue('phone', value.replace(/\D/g, '').substring(0, 10))
    }
  }

  const handleServerError = (serverError) => {
    const genericErrorMessage = 'An unexpected error occurred and we were unable to process your request. Please try again.'

    if (!serverError ||
      !serverError.response ||
      !serverError.response.data ||
      !serverError.response.data.error) {
      setError(genericErrorMessage)
    }

    const error = serverError.response.data.error
    if (error.code === StatusCodes.UNPROCESSABLE_ENTITY) setError('Some information you provided is invalid or missing, please review and try again.')
    if (error.code === StatusCodes.BAD_REQUEST && error.message) setError(error.message)
    if (error.code === StatusCodes.INTERNAL_SERVER_ERROR && error.message) {
      setError(`${genericErrorMessage} [${error.message}]`)
    }
  }

  const handleBack = () => {
    window.history && window.history.back()
    // navigate('/')
  }

  const handleSubmit = async () => {
    personal.validate()
    if (!personal.isValid()) {
      setError('Please fix the errors before trying to submit again')
      return
    }

    const { values } = personal

    setSubmitting(true)
    setError('')

    try {
      const response = await fetchService.post('/public/touch', {
        body: { contact: values }
      })

      if (response.status === StatusCodes.OK || response.status === StatusCodes.NO_CONTENT) {
        setCompleted(true)
      } else {
        throw new Error(`Invalid response [${response.status}]`)
      }
    } catch (err) {
      setSubmitting(false)
      handleServerError(err)
    }
  }

  useEffect(() => {
    browserStorageService.getItem(STORAGE_KEY.SESSION)
      .then((session) => {
        setUser(session)
      })
  }, [setUser])

  useEffect(() => {
    if (!user) return
    personal.setFieldValue('name', user.name)
    personal.setFieldValue('email', user.email)
    const phone = user.phone && user.phone.includes('+1') ? user.phone.substring(2) : ''
    personal.setFieldValue('phone', phone)
    personal.setFieldValue('businessName', user.company && user.company.name)
  }, [user])

  return (
    <Container className={classes.wrapper}>
      <Center id="center" mx="auto" data-aos="fade-up">
        <Paper className={classes.form} radius={0} p={30} mt="lg" mb="lg">
          <Title order={2} className={classes.title} ta="center" mb={50}>
            Contact eConcretely
          </Title>

          <Container className={classes.container}>
            <SimpleGrid
              cols={2}
              mt="lg"
              spacing="md"
              breakpoints={[
                { maxWidth: '30rem', cols: 1, spacing: 'sm' },
                { maxWidth: '72rem', cols: 2, spacing: 'sm' },
              ]}
            >
              <TextInput
                className={classes.textInput}
                label="Your name"
                name="personal.name"
                placeholder="John Smith"
                disabled={completed}
                size="sm"
                withAsterisk
                {...personal.getInputProps('name')}
              />

              <TextInput
                className={classes.textInput}
                label="Business name"
                name="personal.businessName"
                placeholder="Acme Co"
                size="sm"
                disabled={completed}
                {...personal.getInputProps('buisnessName')}
              />

              <TextInput
                className={classes.textInput}
                label="Phone number"
                type="tel"
                name="personal.phone"
                placeholder="3125555555"
                size="sm"
                withAsterisk
                disabled={completed}
                {...personal.getInputProps('phone')}
                value={personal.getTransformedValues().formattedPhone}
                onKeyDown={enforceFormat}
                onChange={onPhoneInput}
              />

              <TextInput
                className={classes.textInput}
                label="Email address"
                name="personal.email"
                placeholder="jsmith@acme.com"
                type="email"
                size="sm"
                withAsterisk
                disabled={completed}
                {...personal.getInputProps('email')}
              />

              <Select
                className={classes.textInput}
                label="Reason for contacting"
                placeholder="Select reason"
                data={[
                  { label: 'General inquiry', value: 'general' },
                  { label: 'Schedule demo', value: 'demo' },
                  { label: 'Product support', value: 'support' },
                  { label: 'Other', value: 'other' },
                ]}
                withAsterisk
                disabled={completed}
                {...personal.getInputProps('reason')}
              />

              <Textarea
                className={classes.textInput}
                label="Message"
                disabled={completed}
                {...personal.getInputProps('message')}
              />
            </SimpleGrid>
          </Container>

          {error ? (
            <Box className={classes.errorWrap}>
              <Flex mb="md">
                <IconAlertCircle size="1.5rem" color="red" />
                <Text ml="md" color="red">{error}</Text>
              </Flex>
            </Box>
          ) : null}

          {!completed ? (
            <Group position="center" mt="xl" mb="md">
              <Button
                variant="outline"
                onClick={handleBack}
              >
                  Go back
              </Button>
              <Button
                onClick={handleSubmit}
                disabled={submitting || completed}
              >
                {submitting ? (
                  <Loader color="blue" size="sm" />
                ) : 'Submit'}
              </Button>
            </Group>
          ) : (
            <Box mt="lg">
              <Stack>
                <Text>
                  Your request has been receieved. We'll be in touch soon!
                </Text>
                <Text>
                  Return to &nbsp;
                  <Link className={classes.link} to="/">
                    <Text component="span">econcrete.ly</Text>
                  </Link>
                </Text>
              </Stack>
            </Box>
          )}
        </Paper>
      </Center>
    </Container>
  );
}
