/**
 * Phone input helper
 * @returns 
 */
export const usePhoneInput = () => {
  const isNumericInput = (event) => {
    const key = event.keyCode
    return ((key >= 48 && key <= 57) || // Allow number line
        (key >= 96 && key <= 105) // Allow number pad
    )
  }

  const isModifierKey = (event) => {
    const key = event.keyCode
    return (event.shiftKey === true || key === 35 || key === 36) || // Allow Shift, Home, End
      (key === 8 || key === 9 || key === 13 || key === 46) || // Allow Backspace, Tab, Enter, Delete
      (key > 36 && key < 41) || // Allow left, up, right, down
      (
        // Allow Ctrl/Command + A,C,V,X,Z
        (event.ctrlKey === true || event.metaKey === true) &&
        (key === 65 || key === 67 || key === 86 || key === 88 || key === 90)
      )
  }

  const enforceFormat = (event) => {
    const isNumeric = isNumericInput(event),
      isModifier = isModifierKey(event)

    // Input must be of a valid number format or a modifier key, and not longer than ten digits
    if (!isNumeric && !isModifier) event.preventDefault()
  }

  const formatPhone = (value) => {
    if (!value || !value.length) return value
    const input = value.replace(/\D/g, '').substring(0, 10), // First ten digits of input only
      areaCode = input.substring(0, 3),
      middle = input.substring(3, 6),
      last = input.substring(6, 10)

    if (input.length > 6) value = `(${areaCode}) ${middle} ${last}`
    else if (input.length > 3) value = `(${areaCode}) ${middle}`
    else if (input.length > 0) value = `(${areaCode}`
    return value
  }

  return [
    enforceFormat,
    formatPhone,
  ]
}
