import { Text, Container, ActionIcon, Group, rem, Image, Box } from '@mantine/core'
import { IconBrandTwitter, IconBrandYoutube, IconBrandInstagram } from '@tabler/icons-react'
// import { MantineLogo } from '@mantinex/mantine-logo'
import { ReactComponent as Logo } from '../../static/econcretely-icon.svg'
import classes from './footer.module.scss'
import { Link } from 'react-router-dom'
import { Subscribe } from './components/subscribe'

const data = [
  {
    title: 'About',
    links: [
      { label: 'Terms of Service', link: '/terms-of-service' },
      { label: 'Privacy Policy', link: '/privacy-policy' },
      { label: 'Support', link: '/contact' },
    ],
  },
  // {
  //   title: 'Project',
  //   links: [
  //     { label: 'Contribute', link: '#' },
  //     { label: 'Media assets', link: '#' },
  //     { label: 'Changelog', link: '#' },
  //     { label: 'Releases', link: '#' },
  //   ],
  // },
  // {
  //   title: 'Community',
  //   links: [
  //     { label: 'Join Discord', link: '#' },
  //     { label: 'Follow on Twitter', link: '#' },
  //     { label: 'Email newsletter', link: '#' },
  //     { label: 'GitHub discussions', link: '#' },
  //   ],
  // },
]

const socials = [
  {
    icon: IconBrandTwitter,
    link: 'https://twitter.com/econcretely',
  },
  // {
  //   icon: IconBrandYoutube,
  //   link: '#',
  // },
  // {
  //   icon: IconBrandInstagram,
  //   link: '#',
  // },
]

export const FooterLinks = () => {
  const groups = data.map((group) => {
    const links = group.links.map((link, index) => (
      <Text
        key={index}
        className={classes.link}
        component="a"
        href={link.link}
        // onClick={(event) => event.preventDefault()}
        target="_blank"
      >
        {link.label}
      </Text>
    ))

    return (
      <div key={group.title}>
        <Text className={classes.title}>{group.title}</Text>
        <div className={classes.groupLinks}>
          {links}
        </div>
      </div>
    )
  })

  const socialGroup = socials.map((social, index) => {
    const { icon: Icon, link } = social

    return (
      <ActionIcon component={Link} to={link} target="_blank" size="lg" color="gray" variant="subtle" key={index}>
        <Icon style={{ width: rem(18), height: rem(18) }} stroke={1.5} />
      </ActionIcon>
    )
  })

  return (
    <footer className={classes.footer}>
      <Container className={classes.inner}>
        <div className={classes.logo}>
          <Box mb="lg"><Logo width={rem(45)} /></Box>
          <Text size="sm" c="dimmed" className={classes.description}>
            Transforming Concrete Operations: Elevate Efficiency, Precision, and Collaboration with eConcretely – Your Partner in Streamlined Excellence.
          </Text>
        </div>
        <div className={classes.subscribe}>
          <Subscribe />
        </div>
      </Container>

      <Container className={classes.inner}>
        <div className={classes.groups}>{groups}</div>
      </Container>

      <Container className={classes.afterFooter}>
        <Box maw={440}>
          <Text  c="dimmed" size="sm">
            © 2024 econcrete.ly - All rights reserved. A service provided by <Link to="https://init.run" className={classes.highlightLink}>init.run</Link>
          </Text>
        </Box>

        <Group gap={0} className={classes.social} justify="flex-end" wrap="nowrap">
          {socialGroup}
        </Group>
      </Container>
    </footer>
  );
}
