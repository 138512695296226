import { HttpStatusCode } from 'axios'
import fetchService from '../../../../services/fetch.service'

/**
 * Fetch clients who have access to this ticket
 * 
 */
export const fetchSharedForTicket = async (ticket) => {
  const ticketId = ticket.id || ticket.meta.id

  try {
    const response = await fetchService.get(`/auth/ticket/${ticketId}/shared`)
    if (response.status !== HttpStatusCode.Ok || !response.result.shared) {
      throw new Error(`Unable to fetch clients ticket has been shared with [${response.status}]`)
    }

    return {
      status: 200,
      ok: true,
      shared: response.result.shared,
    }
  } catch (err) {
    console.error(err)
    return {
      status: err?.response?.status || 500,
      ok: false,
    }
  }
}

/**
 * Fetch all clients that are associated with the company
 * 
 * @param {*} company 
 * @returns 
 */
export const fetchClientsForCompany = async (company) => {
  const companyId = company.id || company.companyId

  try {
    const response = await fetchService.get(`/auth/companies/${companyId}/clients`)
    if (response.status !== HttpStatusCode.Ok || !response.result.clients) {
      throw new Error(`Unable to fetch clients for company [${response.status}]`)
    }

    return {
      status: 200,
      ok: true,
      clients: response.result.clients,
    }
  } catch (err) {
    console.error(err)
    return {
      status: err?.response?.status || 500,
      ok: false,
    }
  }
}

/**
 * Create a new client and associate to the company
 * 
 * @param {*} client 
 * @param {*} company 
 * @returns 
 */
export const addNewClient = async (client, company) => {
  const companyId = company.id || company.companyId

  try {
    const body = {
      client,
      company,
    }

    const response = await fetchService.post(`/auth/companies/${companyId}/clients`, { body })
    if (response.status !== HttpStatusCode.Ok || !response.result.client) {
      throw new Error(`Unable to add new client to company [${response.status}]`)
    }

    return {
      status: 200,
      ok: true,
      client: response.result.client,
    }
  } catch (err) {
    console.error(err)
    return {
      status: err?.response?.status || 500,
      ok: false,
    }
  }
}

/**
 * Update an existing client
 * 
 * @param {*} client 
 * @param {*} company 
 * @returns 
 */
export const updateClient = async (client, company) => {
  const companyId = company.id || company.companyId,
    clientId = client.id || client.clientId

  try {
    const body = {
      client,
      company,
    }

    const response = await fetchService.patch(`/auth/companies/${companyId}/client/${clientId}`, { body })
    if (response.status !== HttpStatusCode.Ok || !response.result.updated) {
      throw new Error(`Unable to update client [${response.status}]`)
    }

    return {
      status: 200,
      ok: true,
      updated: response.result.updated,
    }
  } catch (err) {
    console.error(err)
    return {
      status: err?.response?.status || 500,
      ok: false,
    }
  }
}

/**
  * Fetch an existing client
  * 
  * @param {*} client 
  * @param {*} company 
  * @returns 
  */
export const fetchClient = async (client, company) => {
 const companyId = company.id || company.companyId,
   clientId = client.id || client.clientId

 try {
   const response = await fetchService.get(`/auth/companies/${companyId}/client/${clientId}`)
   if (response.status !== HttpStatusCode.Ok || !response.result.client) {
     throw new Error(`Unable to fetch client [${response.status}]`)
   }

   return {
     status: 200,
     ok: true,
     client: response.result.client,
     shared: response.result.client.shared,
   }
 } catch (err) {
   console.error(err)
   return {
     status: err?.response?.status || 500,
     ok: false,
   }
 }
}

/**
 * Search for a client within a company
 * 
 * @param {*} client 
 * @param {*} company 
 * @returns 
 */
export const findClientInCompany = async (client, company) => {
  const companyId = company.id || company.companyId

  try {
    const body = {
      client,
      company,
    }

    const response = await fetchService.post(`/auth/companies/${companyId}/clients/search`, { body })
    if (response.status !== HttpStatusCode.Ok || !response.result.client) {
      throw new Error(`Unable to search for client within company [${response.status}]`)
    }

    console.log(response)
    return {
      status: 200,
      ok: true,
      client: response.result.client,
    }
  } catch (err) {
    console.error(err)
    return {
      status: err?.response?.status || 500,
      ok: false,
    }
  }
}

/**
 * Share access to a ticket with a client
 * 
 * @param {*} client 
 * @param {*} ticket 
 * @returns 
 */
export const shareTicketWithClient = async (client, ticket) => {
  const ticketId = ticket.id || ticket.ticketId || ticket.meta.id

  try {
    const body = {
      client,
      ticket,
    }

    const response = await fetchService.post(`/auth/ticket/${ticketId}/share`, { body })
    if (response.status !== HttpStatusCode.Ok || !response.result.shared) {
      throw new Error(`Unable to share access to ticket with client [${response.status}]`)
    }
    console.log('shareResponse', response)
    console.log('SHARED with client', response.result.shared)

    return {
      status: 200,
      ok: true,
      shared: response.result.shared,
    }
  } catch (err) {
    console.error(err)
    return {
      status: err?.response?.status || 500,
      ok: false,
    }
  }
}

export const revokeClientTicketAccess = async (client, ticket) => {
  const ticketId = ticket.id || ticket.ticketId || ticket.meta.id

  try {
    const body = {
      client,
      ticket,
    }

    const response = await fetchService.delete(`/auth/ticket/${ticketId}/share`, { body })
    if (response.status !== HttpStatusCode.Ok || !response.result.revokedAccess) {
      throw new Error(`Unable to share access to ticket with client [${response.status}]`)
    }

    return {
      status: 200,
      ok: true,
      revoked: response.result.revokedAccess,
    }
  } catch (err) {
    console.error(err)
    return {
      status: err?.response?.status || 500,
      ok: false,
    }
  }
}

export const deleteClientFromCompany = async (client, company) => {
  const companyId = company.id || company.companyId,
    clientId = client.id || client.clientId

  try {
    const response = await fetchService.delete(`/auth/companies/${companyId}/client/${clientId}`)
    if (response.status !== HttpStatusCode.Ok || !response.result.deleted) {
      throw new Error(`Unable to delete client [${response.status}]`)
    }

    return {
      status: 200,
      ok: true,
      deleted: response.result.deleted,
    }
  } catch (err) {
    console.error(err)
    return {
      status: err?.response?.status || 500,
      ok: false,
    }
  }
}

