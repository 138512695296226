import { Text, createStyles, rem } from '@mantine/core'

const useStyles = createStyles((theme) => ({
  text: {
    color: theme.colors.gray[6],
    display: 'flex',
  },
}))

export const InputSectionText = (props) => {
  const { text = 'per ipsum' } = props,
    { classes } = useStyles()

  return (
    <Text className={classes.text} size="sm" color="dimmed">{text}</Text>
  )
}

export const getInputSectionText = (text, width = 82) => {
  return {
    rightSection: (<InputSectionText text={text} />),
    rightSectionWidth: rem(width),
  }
}
