import { Box } from '@mantine/core'
import { Highlight, HomeHero } from './components/hero'
import classes from './view.module.scss'
import { Features } from './components/features'
import { Zigzag } from './components/zigzag'
import { FooterLinks } from './footer'
import { OnboardingView } from './components/onboarding-view'

const PageTitle = () => (
  <>
    Revolutionizing Concrete Management with<br/>
    <Highlight>Innovation</Highlight> and <Highlight>Precision</Highlight>.
  </>
)

const PageSubtitle = () => (
  <>
    Simplify concrete management with streamlined processes for e-ticketing, fleet management, mix design expertise, and beyond. Innovative solutions for a smoother, more efficient business operation.
  </>
)

export const LandingPageView = (props) => {
  return (
    <Box className={classes.homeView}>
      <HomeHero
        Title={PageTitle}
        Subtitle={PageSubtitle}
        showFooterLinks={true}
      />
      <Features />
      <Zigzag />
      <OnboardingView />
      <FooterLinks />
    </Box>
  )
}
