import { useEffect, useRef, useState } from 'react'
import { Title as MantineTitle, Text, Container, Button, Overlay } from '@mantine/core'
import cx from 'clsx'
import { Link } from 'react-router-dom'
import classes from './hero.module.scss'

export const Highlight = ({ children }) => {
  return (
    <span className={classes.highlight}>{children}</span>
  )
}

const DefaultTitle = () => (
  <>Default Title</>
)

export function HomeHero(props) {
  const videoBgRef = useRef(null),
    [videoBgPlaying, setVideoBgPlaying] = useState(false),
    {
      Title = DefaultTitle,
      Subtitle = null,
      useVideoBackground = false,
      showFooterLinks = false,
    } = props

  useEffect(() => {
    const { current = null } = videoBgRef || {}
    if (current && !videoBgPlaying) {
      current.play()
      setVideoBgPlaying(true)
    }
    
  }, [videoBgRef, videoBgPlaying, setVideoBgPlaying])

  return (
    <div className={classes.wrapper}>
      <Overlay color="#000" opacity={0.65} zIndex={1} />

      {useVideoBackground ? (
        <video className={classes.concreteVideoBg} autoPlay disablePictureInPicture loop poster="https://econcrete.nyc3.cdn.digitaloceanspaces.com/concrete-stillframe.png" ref={videoBgRef} muted>
          <source src="https://econcrete.nyc3.cdn.digitaloceanspaces.com/concrete2.1.mp4" type="video/mp4" />
        </video>
      ) : null}

      <Button className={classes.heroLogin} component={Link} to="/login" size="sm" variant="outline">
        Customer Login
      </Button>

      <div className={classes.inner}>
        <MantineTitle className={classes.title} data-aos="fade-up">
          <Title />
        </MantineTitle>

        {Subtitle ? (
          <Container size={640} data-aos="fade-up" data-aos-delay="200">
            <Text size="lg" className={classes.description}>
              <Subtitle />
            </Text>
          </Container>
        ) : null}

        {showFooterLinks ? (
          <div className={classes.controls}>
            <Button className={classes.control} variant="white" size="lg" data-aos="fade-up" data-aos-delay="600" component={Link} to="/register" mr="md">
              Get Started
            </Button>
            <Button className={cx(classes.control, classes.secondaryControl)} size="lg" data-aos="fade-up" data-aos-delay="600" component={Link} to="/contact">
              Schedule a demo
            </Button>
          </div>
        ) : null}
      </div>
    </div>
  )
}
