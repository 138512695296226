import { createStyles, rem, Box, Text, TextInput, Button, Flex, Loader } from '@mantine/core'
import { useForm, zodResolver } from '@mantine/form'
import { useState } from 'react'
import { z } from 'zod'
import fetchService from '../../../services/fetch.service';

const formSchema = z.object({
  email: z.string().email({ message: 'Your email does not look right' }),
})

const useStyles = createStyles((theme) => {
  return {
    subscribeWrapper: {
      paddingBottom: rem('1rem'),
      textAlign: 'justify',
    },
    button: {
      borderRadius: theme.radius.sm,
      padding: `${theme.spacing.sm} ${theme.spacing.lg}`,
      cursor: 'pointer',
      height: rem('3.25rem'),
      maxWidth: rem('13rem'),
    },
    textInput: {
      color: '#f8f9f7',
      flexGrow: 1,
      marginRight: theme.spacing.md,
      marginTop: theme.spacing.sm,

      label: {
        color: theme.fn.dimmed(),
        marginBottom: rem('0.2rem'),
      },

      ['.mantine-TextInput-wrapper']: {
        marginBottom: 0,
      },

      ['.mantine-TextInput-error']: {
        position: 'absolute',
        marginTop: theme.spacing.xs,
      },
    },
  }
})

export const Subscribe = (props) => {
  const { classes } = useStyles()
  const form = useForm({
    initialValues: {
      email: '',
    },
    validate: zodResolver(formSchema.required()),
  })
  const [submitting, setSubmitting] = useState(false),
    [complete, setComplete] = useState(false)

  const { getInputProps } = form
  const subscribe = async () => {
    form.validate()
    if (!form.isValid()) return

    setSubmitting(true)
    const { values } = form
    await subscribeToNewsletter(values)
    setComplete(true)
    // const subscribed = await subscribeToNewsletter(values)
    // if (subscribed) {
    //   setComplete(true)
    // } else {
    //   setSubmitting(false)
    //   form.setFieldError('email', 'We were unable to add you to our newsletter!')
    // }
  }

  const subscribeToNewsletter = async (values) => {
    try {
      const contact = {
        email: values.email,
      }

      const response = await fetchService.post('/public/newsletter', {
        body: { contact }
      })

      if (response.status !== 200 || response.status !== 204) return false
      return true
    } catch (err) {
      console.warn('Failed to subscribe to newsletter')
      return false
    }
  }

  return (
    <Box maw={440} className={classes.subscribeWrapper}>
      <Text color="dimmed" fz="sm">Join our newsletter for insider access to e-ticketing tips, fleet optimization strategies, industry insights, and more.</Text>

      {!complete ? (
        <Flex
          align="end"
        >
          <TextInput
            className={classes.textInput}
            label="Email"
            name="email"
            placeholder="john@google.com"
            type="email"
            {...getInputProps('email')}
          />
          <Button radius="sm" size="sm" onClick={() => subscribe()}>
            {submitting ? (
              <Loader color="white" size="sm" />
            ) : 'Subscribe'}
          </Button>
        </Flex>
      ) : (
        <Text color="white" fz="sm" mt="lg" data-aos="fade-up">Thanks for joining our newsletter!</Text>
      )}
    </Box>
  )
}
