import {
  Paper,
  createStyles,
  TextInput,
  PasswordInput,
  Checkbox,
  Button,
  Title,
  Text,
  Anchor,
  rem,
  MediaQuery,
  Center,
  Container,
  Box,
  Flex,
  Loader,
} from '@mantine/core'
import { useEffect, useState } from 'react'
import { useForm, zodResolver } from '@mantine/form';
import { z } from 'zod';
import { IconAlertCircle } from '@tabler/icons-react';

import fingerprintService, { DEVICE_FINGERPRINT } from '../services/fingerprint.service'
import { Link, redirect, useNavigate } from 'react-router-dom'
import browserStorageService from '../services/browser-storage.service';
import fetchService from '../services/fetch.service';
import highway from '../static/highway.jpg'

const useStyles = createStyles((theme) => ({
  wrapper: {
    // minHeight: rem(900),
    backgroundSize: 'cover',
    backgroundImage: `url(${highway})`, //'url(../static/highway.jpg)',
    height: '100vh',
  },

  formDesktop: {
    backgroundColor: theme.fn.rgba('#1d1224', 0.9),
    borderRight: `${rem(1)} solid #363636`,
    // borderRight: `${rem(1)} solid ${
    //   theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[3]
    // }`,
    // minHeight: rem(900),
    height: '100vh',
    maxWidth: rem(450),
    paddingTop: rem(80),
    color: '#f8f9f7',
    
    // [theme.fn.smallerThan('sm')]: {
    //   maxWidth: '100%',
    // },
  },

  wrapperMobile: {
    display: 'flex',
    height: '100vh',
    backgroundSize: 'cover',
    backgroundImage: `url(${highway})`, //'url(../static/highway.jpg)',
  },

  formMobile: {
    backgroundColor: theme.fn.rgba('#1d1224', 0.9), //'#1d1224',
    border: `${rem(1)} solid ${theme.fn.darken('#f9af42', 0.5)}`,
    color: '#f8f9f7',
  },

  title: {
    // color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    color: '#f8f9f7',
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },

  textInput: {
    color: '#f8f9f7',

    label: {
      color: '#f8f9f7',
    },
  },

  link: {
    color: '#f9af42',
    position: 'relative',
    textDecoration: 'none',
  },

  errorWrap: {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    padding: theme.spacing.sm,
    marginTop: theme.spacing.md,
    borderLeft: `3px solid ${theme.colors.red[5]}`
  },
}));

const LoginForm = (props) => {
  const {
    classes,
    className,
    form,
    onClickLogin,
    error,
    submitting,
  } = props

  return (
    <Paper className={className} radius={0} p={30}>
      <Title order={2} className={classes.title} ta="center" mt="md" mb={50}>
        eConcretely Login
      </Title>

      {error ? (
        <Box className={classes.errorWrap} mb="md">
          <Flex>
            <IconAlertCircle size="4rem" color="red" />
            <Text ml="md" color="red">{error}</Text>
          </Flex>
        </Box>
      ) : null}

      <TextInput
        className={classes.textInput}
        label="Email address"
        name="email"
        placeholder="hello@gmail.com"
        size="md"
        type="email"
        {...form.getInputProps('email')}
      />

      <PasswordInput
        className={classes.textInput}
        label="Passcode"
        name="passcode"
        placeholder="Your passcode"
        mt="md"
        size="md"
        {...form.getInputProps('authCode')}
      />

      <Checkbox style={{ display: 'none' }} className={classes.textInput} label="Keep me logged in" mt="xl" size="md" />

      <Button
        onClick={onClickLogin}
        fullWidth
        mt="xl"
        size="md"
        mb="xl"
      >
        {submitting ? (
          <Loader color="white" size="sm" />
        ) : 'Login'}
      </Button>

      <Text ta="left" mt="md" size="sm">
        <Text component="span" mr="sm">Forgot your passcode?</Text>
        <Link className={classes.link} ml="sm" state={{ email: form.values.email || '' }} to="/forgot-auth">
          <Text component="span">Request Reset</Text>
        </Link>
      </Text>

      <Text ta="left" mt="md" size="sm">
        <Text component="span" mr="sm">Don't have an account?</Text>
        <Link className={classes.link} ml="sm" to="/register">
          <Text component="span">Register</Text>
        </Link>
      </Text>

      <Text ta="left" mt="md" size="sm">
        <Text component="span" mr="sm">Having issues?</Text>
        <Link className={classes.link} ml="sm" to="mailto:info@econcrete.ly">
          <Text component="span">Contact info[@]econcrete.ly</Text>
        </Link>
      </Text>
    </Paper>
  )
}

const formSchema = z.object({
  email: z.string().min(1, { message: 'You have to provide an email' })
    .email('That email does not look correct.'),
  authCode: z.string().min(1, { message: 'You have to provide a passcode' }),
})

export const LoginView = () => {
  const { classes } = useStyles(),
    navigate = useNavigate(),
    [fingerprint, setFingerprint] = useState(''),
    [error, setError] = useState(null),
    [submitting, setSubmitting] = useState(false)

  const login = useForm({
    validate: zodResolver(formSchema.required()),
    initialValues: {
      email: '',
      authCode: '',
    },
  })

  const validate = () => {
    login.validate()
    return login.isValid()
  }

  const handleLogin = async (evt) => {
    if (submitting) return
    if (!validate()) return

    const { values: { email, authCode } } = login
    setSubmitting(true)

    try {
      const response = await fetchService.post('/public/login', {
        redirectOnUnauth: false,
        body: { email, authCode, fingerprint }
      })

      const userId = response.user.user_id
      if (!browserStorageService.initialized) {
        browserStorageService.initialize(userId)
      }

      await browserStorageService.setItem('role', response.user.role)
      await browserStorageService.setItem('session', response.user)

      navigate('/dashboard')
    } catch (err) {
      console.log(err?.response?.status)
      console.log(err.response)
      if (err?.response?.status === 403) {
        setError('Your account is currently locked. Please contact the owner of your organization. If you are the owner, please contact support.')
      } else {
        console.warn(err)
        setError('The email address or passcode you provided was incorrect.')
      }

      setSubmitting(false)
    }
  }

  useEffect(() => {
    const loadFingerprint = async () => {
      return await fingerprintService.getFingerprint()
    }

    loadFingerprint()
      .then(setFingerprint)
      .catch((err) => {
        console.warn('Unable to load fingerprint', err)
      })
  }, [])

  return (
    <>
      <MediaQuery smallerThan="sm" styles={{ display: 'none' }}>
        <div id="wrapper" className={classes.wrapper}>
          <LoginForm
            className={classes.formDesktop}
            classes={classes}
            form={login}
            onClickLogin={handleLogin}
            error={error}
            submitting={submitting}
          />
        </div>
      </MediaQuery>

      <MediaQuery largerThan="sm" styles={{ display: 'none' }}>
        <Container className={classes.wrapperMobile}>
          <Center id="center" maw={400} mx="auto">
            <LoginForm
              className={classes.formMobile}
              classes={classes}
              form={login}
              onClickLogin={handleLogin}
              error={error}
              submitting={submitting}
            />
          </Center>
        </Container>
      </MediaQuery>
    </>
  );
}
