import React, { useEffect } from 'react'
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom"
import ReactGA from 'react-ga4'
import AOS from 'aos'
import browserStorageService, { STORAGE_KEY } from './services/browser-storage.service'
import { LoginView } from './views/login'
import { DashboardView } from './views/dashboard'
import { DashboardHomeView } from './views/dashboard/home.dashboard'
import { ManageMixDesignsView } from './views/mix-design'
import NotFoundErrorView from './views/errors/not-found.error'
import { CreateMixDesignScreen } from './views/mix-design/create'
import { MixDesignView } from './views/mix-design/view'
import fetchService from './services/fetch.service'
import { FleetView } from './views/fleet'
import { ManageTruckView } from './views/fleet/manage'
import { ManageTicketView } from './views/tickets/manage'
import { TicketsView } from './views/tickets'
import { UsersView } from './views/users'
import { RegisterView } from './views/register'
import { ErrorNotFound } from './views/errors/404'
import { TokenInviteView } from './views/tokens/invite'
import { Notifications } from '@mantine/notifications'
import { TokenResetView } from './views/tokens/reset'
import { TokenRequestView } from './views/tokens/forgot'
import { TicketView } from './views/tickets/view'
import { UserView } from './views/users/view'
import { ManageUserView } from './views/users/manage'
import { ManageCompanyView } from './views/company/manage'
import { ManageAccountView } from './views/settings/manage'
import { LogoutView } from './views/logout'
import { LandingPageView } from './views/home/view'
import { TermsOfServiceView } from './views/home/terms-of-service'
import { PrivacyPolicyView } from './views/home/privacy-policy'
import { ContactView } from './views/contact'
import './index.css'
import 'aos/dist/aos.css'
import { ShareView } from './views/share'
import { StatusCodes } from 'http-status-codes'

const router = createBrowserRouter([
  {
    path: '/',
    element: <LandingPageView />,
    errorElement: <ErrorNotFound />,
  },
  {
    path: '/terms-of-service',
    element: <TermsOfServiceView />,
    errorElement: <ErrorNotFound />,
  },
  {
    path: '/privacy-policy',
    element: <PrivacyPolicyView />,
    errorElement: <ErrorNotFound />,
  },
  {
    path: '/contact',
    element: <ContactView />,
    errorElement: <ErrorNotFound />,
  },
  {
    path: '/logout',
    element: <LogoutView />,
    loader: async () => {
      try {
        await fetchService.post('/public/logout')
        await browserStorageService.clean()
      } catch (err) {
        console.warn(`Unable to logout`, err.message)
      }

      return null
    },
  },
  {
    path: '/login',
    element: <LoginView />,
    errorElement: <ErrorNotFound />,
    loader: async ({_r, _p}) => {
      const session = await browserStorageService.getItem(STORAGE_KEY.SESSION)
      if (session) window.location.href = '/dashboard'
      else return null
    }
  },
  {
    path: '/register',
    element: <RegisterView />,
  },
  {
    path: '/invite',
    element: <TokenInviteView />,
    loader: async ({request, params}) => {
      const token = new URL(request.url).searchParams.get('token')

      try {
        const body = { token, reason: 'INVITE' }
        const response = await fetchService.post('/public/validateToken', { body })
        return response
      } catch (err) {
        console.warn(`Unable to load Token ${token}`, err.message)
        return null
      }
    }
  },
  {
    path: '/reset-auth',
    element: <TokenResetView />,
    loader: async ({request, params}) => {
      const token = new URL(request.url).searchParams.get('token')

      try {
        const body = { token, reason: 'RESET' }
        const response = await fetchService.post('/public/validateToken', { body })
        return response
      } catch (err) {
        console.warn(`Unable to load Token ${token}`, err.message)
        return null
      }
    },
  },
  {
    path: '/forgot-auth',
    element: <TokenRequestView />,
  },
  {
    path: '/share/:shareId',
    element: <ShareView />,
    loader: async ({request, params}) => {
      const { shareId } = params

      try {
        const response = await fetchService.get(`/public/ticket/${shareId}`)
        return {
          status: 'ok',
          result: {
            ticket: response.result.ticket,
            guest: response.result.guest,
          },
        }
      } catch (err) {
        // handle some server response
        if (err && err.response && err.response.status) {
          return {
            status: false,
            result: {
              code: err.response.status,
            },
          }
        }

        console.warn(`Failed to load requested ticket ${shareId}`, err && err.message)
      }

      return {
        status: false,
      }
    }
  },
  {
    path: '/dashboard',
    element: <DashboardView />,
    errorElement: <NotFoundErrorView />,
    loader: async ({request, params}) => {
      try {
        const { result: { user } } = await fetchService.post('/auth/me')
        if (!browserStorageService.initialized && user) {
          browserStorageService.initialize(user.userId)
          await browserStorageService.createSession(user)
        }

        return user
      } catch (err) {
        console.warn('Unable to load user account', err.message)
        return null
      }
    },
    children: [
      {
        path: '',
        element: <DashboardHomeView />,
        errorElement: <NotFoundErrorView />,
      },
      
      {
        path: 'company',
        element: <ManageCompanyView />,
        errorElement: <NotFoundErrorView />,
        loader: async ({request, params}) => {
          try {
            const session = await browserStorageService.getItem('session')
            const { company: { companyId } } = session
            const response = await fetchService.get(`/auth/companies/${companyId}`)
            return response.result && response.result.company
          } catch (err) {
            console.warn('Unable to load Company', err.message)
            return null
          }
        },
      },
      {
        path: 'settings',
        element: <ManageAccountView />,
        errorElement: <NotFoundErrorView />,
      },
      {
        path: 'fleet',
        element: <FleetView />,
        errorElement: <NotFoundErrorView />,
      },
      {
        path: 'truck/create',
        element: <ManageTruckView />
      },
      {
        path: 'truck/:truckId/edit',
        element: <ManageTruckView />,
        loader: async ({request, params}) => {
          try {
            const response = await fetchService.get(`/auth/truck/${params.truckId}`)
            return response.result
          } catch (err) {
            console.warn(`Unable to load Truck ${params.truckId}`, err.message)
            return null
          }
        }
      },
      {
        path: 'tickets',
        element: <TicketsView />,
        errorElement: <NotFoundErrorView />,
      },
      {
        path: 'ticket/create',
        element: <ManageTicketView />,
        errorElement: <NotFoundErrorView />,
      },
      {
        path: 'ticket/:ticketId',
        element: <TicketView />,
        errorElement: <NotFoundErrorView />,
        loader: async ({request, params}) => {
          try {
            const response = await fetchService.get(`/auth/ticket/${params.ticketId}`)
            return response.result.ticket
          } catch (err) {
            console.warn(`Unable to load Ticket ${params.ticketId}`, err.message)
            return null
          }
        }
      },
      {
        path: 'ticket/:ticketId/edit',
        element: <ManageTicketView />,
        errorElement: <NotFoundErrorView />,
        loader: async ({request, params}) => {
          try {
            const response = await fetchService.get(`/auth/ticket/${params.ticketId}`)
            return response.result.ticket
          } catch (err) {
            console.warn(`Unable to load Ticket ${params.ticketId}`, err.message)
            return null
          }
        }
      },
      {
        path: 'users',
        element: <UsersView />,
        errorElement: <NotFoundErrorView />,
      },
      {
        path: 'user/:userId',
        element: <UserView />,
        errorElement: <NotFoundErrorView />,
        loader: async ({request, params}) => {
          try {
            const response = await fetchService.get(`/auth/users/${params.userId}`)
            return response.result.user
          } catch (err) {
            console.warn(`Unable to load User ${params.userId}`, err.message)
            if (err.response) {
              return {
                errorCode: err.response.status || 500,
              }
            }

            return null
          }
        },
      },
      {
        path: 'user/:userId/edit',
        element: <ManageUserView />,
        errorElement: <NotFoundErrorView />,
        loader: async ({request, params}) => {
          try {
            const response = await fetchService.get(`/auth/users/${params.userId}`)
            return response.result.user
          } catch (err) {
            console.warn(`Unable to load User ${params.userId}`, err.message)
            if (err.response) {
              return {
                errorCode: err.response.status || 500,
              }
            }

            return null
          }
        }
      },
      {
        path: 'designs',
        element: <ManageMixDesignsView />,
        errorElement: <NotFoundErrorView />,
      },
      {
        path: 'design/create',
        element: <CreateMixDesignScreen />,
        errorElement: <NotFoundErrorView />,
      },
      {
        path: 'design/:designId',
        element: <MixDesignView />,
        errorElement: <NotFoundErrorView />,
        // with this data loaded before rendering
        // loader: async ({ request, params }) => {
        //   return fetch(
        //     `/fake/api/teams/${params.teamId}.json`,
        //     { signal: request.signal }
        //   )
        // },
      },
      {
        path: 'design/:designId/edit',
        // element: <EditMixDesignView />,
        element: <CreateMixDesignScreen />,
        errorElement: <NotFoundErrorView />,
        loader: async ({request, params}) => {
          try {
            const response = await fetchService.get(`/auth/design/${params.designId}`)
            return response.result.design
          } catch (err) {
            console.warn(`Unable to load Mix Design ${params.designId}`, err.message)
            return null
          }
        }
        // const loadDesignSpec = async () => {
        //   return await fetchService.get(`/auth/design/${params.designId}`)
        // }
      },
      {
        path: '*',
        element: <NotFoundErrorView />,
        errorElement: <NotFoundErrorView />,
      },
    ],
  },
  // {
  //   path: "/v1",
  //   element: <Home />,
  //   errorElement: <NotFoundErrorView />,
  //   loader: loadMockContacts,
  //   children: [
  //     {
  //       path: "test-requests",
  //       element: <TestRequests />,
  //       errorElement: <NotFoundErrorView />,
  //     },
  //     {
  //       path: "test-tickets",
  //       element: <TestTickets />,
  //       errorElement: <NotFoundErrorView />,
  //     },
  //   ]
  // },
  // {
  //   path: "contacts/:contactId",
  //   element: <Contact />,
  // },
]);

const App = () => {
  useEffect(() => {
    const debug = process.env.NODE_ENV === 'production'
      ? false
      : true

    if (process.env.NODE_ENV === 'production') {
      ReactGA.initialize('G-BV1YN4C5ZL', { debug })
      console.info('GA Initialized', { debug, env: process.env.NODE_ENV })
    } else if (process.env.NODE_ENV === 'development' || 'staging') {
      ReactGA.initialize('G-J8G67X8HTW', { debug })
      console.info('GA Initialized', { debug, env: process.env.NODE_ENV })
    }
  }, [])

  useEffect(() => {
    AOS.init({
      once: true,
      // disable: 'phone',
      duration: 600,
      easing: 'ease-out-sine',
    })
  }, [])

  return (
    <>
      <Notifications position="top-right" radius="md" />
      <RouterProvider router={router} />
    </>
    // <MantineProvider withCssVariables withGlobalStyles withNormalizeCSS>
    //   <RouterProvider router={router} />
    // </MantineProvider>
  )
}

export { App }
