import {
  Card,
  Text,
  ActionIcon,
  Group,
} from '@mantine/core'
import { IconPlus, IconTrash } from '@tabler/icons-react'
import { randomId, useHover, useToggle } from '@mantine/hooks'

const CreateFormCard = (props) => {
  const { saveItem, toggleCreateForm, form: FormComponent } = props
  const { hovered, ref } = useHover()

  return (
    <Card withBorder shadow={hovered ? 'sm' : 'xs'} ref={ref}>
      <FormComponent onSave={saveItem} onCancel={toggleCreateForm} createMode={true} />
    </Card>
  )
}

const AddButtonCard = (props) => {
  const { toggleCreateForm, addNewLabel } = props
  const { hovered, ref } = useHover()

  return (
    <Card
      className="mix-design-card__add-more"
      withBorder
      component="button"
      onClick={toggleCreateForm}
      shadow={hovered ? 'sm' : 'xs'}
      ref={ref}
    >
      <Group position="center">
        <IconPlus size="1.125rem" />
        <Text size="md">{addNewLabel}</Text>
      </Group>
    </Card>
  )
}

export const DynamicFormList = (props) => {
  const {
    form,
    fieldKey = 'admixtures',
    addNewLabel = 'Add Admixture',
    dynamicFormComponent: DynamicForm,
    maxItems = 4,
  } = props,
    [isCreating, toggleCreateForm] = useToggle([false, true])

  const saveItem = (values) => {
    toggleCreateForm()
    form.insertListItem(fieldKey, {
      ...values,
      key: randomId(),
    })
  }

  const updateListItem = (index) => (field, value) => {
    form.setFieldValue(`${fieldKey}.${index}.${field}`, value)
  }

  const deleteItem = (index) => () => {
    form.removeListItem(fieldKey, index)
  }

  const dynamicItems = form.values[fieldKey].map((item, index) => (
    <Card className="mix-design-card" key={randomId()} withBorder shadow="sm">
      <ActionIcon
        className="mix-design-card__delete"
        size="lg"
        color="red"
        variant="filled"
        radius="lg"
        onClick={deleteItem(index)}
      >
        <IconTrash size="1.25rem" />
      </ActionIcon>

      <DynamicForm
        inlineEdit={false}
        initialValues={item}
        onUpdate={updateListItem(index)}
        inline={true}
        editMode={true}
      />
    </Card>
  ))

  const actionCard = {
    [true]: <CreateFormCard saveItem={saveItem} toggleCreateForm={toggleCreateForm} form={DynamicForm} />,
    [false]: <AddButtonCard toggleCreateForm={toggleCreateForm} addNewLabel={addNewLabel} />,
  }

  return (
    <>
      {dynamicItems}
      {dynamicItems.length < maxItems ? (
        actionCard[isCreating]
      ) : null}
    </>
  )
}
