
import { useEffect, useMemo, useState } from 'react'
// import { useForm, zodResolver } from '@mantine/form'
import { notifications } from '@mantine/notifications'
import {
  // CloseButton,
  Container,
  // Select,
  SimpleGrid,
  // TextInput,
  Text,
  Title,
  // Code,
  // Stack,
  // Tooltip,
  rem,
  ActionIcon,
  // Button,
  createStyles,
  // Center,
  // NumberInput,
  Card,
  // Avatar,
  // Progress,
  Badge,
  // Group,
  Flex,
  Menu,
} from '@mantine/core'
import { randomId } from '@mantine/hooks'
import {
  IconDotsVertical,
  IconTrash,
  IconPencil,
  // IconExternalLink,
  IconX,
  IconCheck,
  IconExclamationCircle,
  // IconArrowNarrowLeft,
} from '@tabler/icons-react'
import fetchService from '../../services/fetch.service'
import { useNavigate, useParams } from 'react-router-dom'
import './view.scss'
import { ActionLinkToDesigns } from './shared/action-link-to-designs'

const useStyles = createStyles((theme) => ({
  card: {
    overflow: 'inherit',
  },
  button: {
    borderRadius: theme.radius.sm,
    padding: `${theme.spacing.sm} ${theme.spacing.lg}`,
    cursor: 'pointer',
    height: rem('3.25rem'),
    maxWidth: rem('13rem'),
  },
}));

const ManageDesignMenu = (props) => {
  const {
    children,
    design = {},
    onDelete,
    onEdit,
  } = props

  const isDraft = design.status !== 'PUBLISHED',
    iconSize = 16

  return (
    <Menu shadow="md" width={200}>
      <Menu.Target>{children}</Menu.Target>
      <Menu.Dropdown>
        <Menu.Label>{design.meta.mixDesignNumber}</Menu.Label>
        {isDraft ? (
          <Menu.Item
            onClick={() => onEdit(design)}
            icon={<IconPencil size={iconSize}/>}
          >
            Edit Design
          </Menu.Item>
        ) : null}
        <Menu.Item
          color="red"
          onClick={() => onDelete(design)}
          icon={<IconTrash size={iconSize} />}
        >
          Delete Mix Design
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  )
}

export const MixDesignView = () => {
  const params = useParams(),
    [design, setDesign] = useState(null),
    [loaded, setLoaded] = useState(false),
    navigate = useNavigate(),
    classes = useStyles(),
    // loadedSpec = loaded && !!design,
    notificationId = {
      load: 'view-design-load',
      delete: 'view-design-delete',
    }

  useEffect(() => {
    const loadDesignSpec = async () => {
      return await fetchService.get(`/auth/design/${params.designId}`)
    }

    notifications.show({
      id: notificationId.load,
      loading: true,
      title: 'Loading Mix Design',
      autoClose: false,
      withCloseButton: false,
    })

    loadDesignSpec()
      .then(({ status, result }) => {
        const design = result?.design
        notifications.update({
          id: notificationId.load,
          color: 'teal',
          title: 'Mix Design Loaded',
          icon: <IconCheck style={{ width: rem(18), height: rem(18) }} />,
          loading: false,
          autoClose: 2000,
        })
        setDesign(design)
      })
      .catch((err) => {
        console.warn('Unable to load design', err)
        notifications.update({
          id: notificationId.load,
          color: 'red',
          title: 'Unable to find Mix Design',
          message: 'We were unable to find the requested mix design. Please try again later, if this continues, please contact support.',
          icon: <IconExclamationCircle style={{ width: rem(18), height: rem(18) }} />,
          loading: false,
          autoClose: false,
        })
      })
      .finally(() => {
        setLoaded(true)
      })
  }, [params])

  const missingSpec = useMemo(() => {
    if (!loaded) return null
    if (loaded && !design) {
      return (
        <Text>404 Mix Design Not Found</Text>
      )
    }
  }, [loaded, design])

  const handleEdit = (design) => {
    navigate(`/dashboard/design/${design.id}/edit`)
  }

  const handleDelete = async (design) => {
    notifications.show({
      id: `${notificationId.delete}.${design.id}`,
      loading: true,
      title: 'Deleting Mix Design',
      autoClose: false,
      withCloseButton: false,
    })

    await fetchService.delete(`/auth/design/${design.id}`)
      .then(({ status, result }) => {
        notifications.update({
          id: `${notificationId.delete}.${design.id}`,
          color: 'teal',
          title: 'Mix Design Deleted',
          message: `Your Mix Design '${design.meta.mixDesignNumber}' has been DELETED.`,
          icon: <IconCheck style={{ width: rem(18), height: rem(18) }} />,
          loading: false,
        })

        navigate('/dashboard/designs')
      })
      .catch((err) => {
        console.warn('Unable to delete mix design', err)
        const errorMessage = (err?.response?.status === 404)
          ? `The Mix Design '${design.meta.mixDesignNumber}' was not found and could not be deleted.`
          : `We ran into an issue trying to delete '${design.meta.mixDesignNumber}' you can try again later or contact support.${err?.response?.data?.error?.message ? `[${err.response.data.error.message}]` : ''}`

        notifications.update({
          id: `${notificationId.delete}.${design.id}`,
          color: 'red',
          title: 'Unable to delete Mix Design',
          message: errorMessage,
          icon: <IconX style={{ width: rem(18), height: rem(18) }} />,
          loading: false,
        })
      })
      .finally(() => {
      })
  }

  const renderSpecList = (specs, index = null) => {
    const specList = specs.map(({ label, value }) => (
      <div key={randomId()} mb="md" pl="md" pr="md">
        <Text size="xs" color="dimmed">{label}</Text>
        <Text fw={500} size="sm">{value}</Text>
      </div>
    ))

    return (
      <SimpleGrid
        className={isNaN(index) ? '' : `spec-${index}`}
        cols={3}
        spacing="md"
        breakpoints={[
          { maxWidth: '52rem', cols: 1, spacing: 'sm' },
          { maxWidth: '72rem', cols: 3, spacing: 'sm' },
          { minWidth: '92rem', cols: 3, spacing: 'md' },
        ]}
        p={5}
        key={randomId()}
      >
        {specList}
      </SimpleGrid>
    )
  }

  const renderSpec = useMemo(() => {
    if (!loaded || loaded && !design) return null
    
    const missingValue = '<empty>'
    const fillSpec = (label, value) => ({
      label,
      value: value === undefined || value === null || value === '' ? missingValue : value,
    })
  
    const metaSpec = [
      fillSpec('Custom Name', design.meta.name || ''),
      fillSpec('Mix Design Number', design.meta.mixDesignNumber || '<MISSING VALUE>'),
      fillSpec('Producer Number', design.meta.producerNumber || '<MISSING VALUE>'),
    ]

    const cementSpec = [
      fillSpec('Name', design.cement.name),
      fillSpec('Material Code', design.cement.materialCode),
      fillSpec('Manufacturer Code', design.cement.manufacturerCode),
      fillSpec('Total', design.cement.total),
      fillSpec('Bags', design.cement.bags),
      fillSpec('Type', design.cement.type === 'other' ? `Custom: ${design.cement.customType}` : design.cement.type),
    ]

    const waterSpec = [
      fillSpec('Gallons', design.water.gallons),
      fillSpec('Water : Cement Ratio', design.water.cementRatio),
    ]

    const specialtySpec = [
      fillSpec('Name', design.specialty.name),
      fillSpec('Material Code', design.specialty.materialCode),
      fillSpec('Manufacturer Code', design.specialty.mfrCode),
      fillSpec('Total', design.specialty.total),
    ]

    const aggregatesSpecArr = design.aggregates.map((aggregate) => {
      return [
        fillSpec('Type', aggregate.type),
        fillSpec('Name', aggregate.name),
        fillSpec('Material Code', aggregate.materialCode),
        fillSpec('Manufacturer Code', aggregate.mfrCode),
        fillSpec('Weight', aggregate.weight),
      ]
    })

    const admixturesSpecArr = design.admixtures.map((admixture) => {
      return [
        fillSpec('Name', admixture.name),
        fillSpec('Material Code', admixture.materialCode),
        fillSpec('Manufacturer Code', admixture.mfrCode),
        fillSpec('Dosage Rate', admixture.dosageRate),
      ]
    })

    return (
      <Card className={classes.card} withBorder padding="lg" radius="md">
        <Flex justify="flex-end" align="center">
          <Badge mr="sm" color={design.status === 'PUBLISHED' ? 'green' : 'orange'}>{design.status}</Badge>
          <ManageDesignMenu design={design} onDelete={handleDelete} onEdit={handleEdit}>
            <ActionIcon variant="transparent" color="blue" radius="xl" size="sm" aria-label="Options">
              <IconDotsVertical />
            </ActionIcon>
          </ManageDesignMenu>
        </Flex>

        <Text fz="lg" fw={300} mt="lg" mb="md">Design Identification</Text>
        {renderSpecList(metaSpec)}

        <Text fz="lg" fw={300} mt="lg" mb="md">Cement</Text>
        {renderSpecList(cementSpec)}

        <Text fz="lg" fw={300} mt="lg" mb="md">Water</Text>
        {renderSpecList(waterSpec)}

        <Text fz="lg" fw={300} mt="lg" mb="md">Specialty / Latex</Text>
        {renderSpecList(specialtySpec)}

        <Text fz="lg" fw={300} mt="lg" mb="md">Aggregates</Text>
        {aggregatesSpecArr.map((agg, index) => renderSpecList(agg, index))}

        <Text fz="lg" fw={300} mt="lg" mb="md">Admixtures</Text>
        {admixturesSpecArr.map((agg, index) => renderSpecList(agg, index))}

        <Text c="dimmed" fz="sm" mt="md">
          {design.status === 'PUBLISHED' ? (
            `Published on ${design.createdAt}`
          ) : (
            `Draft saved on ${design.updatedAt}`
          )}
        </Text>
      </Card>
    )
  }, [loaded, design])

  return (
    <Container my="md">
      <Title order={2} weight={500} mt="xl" mb="lg">View Mix Design</Title>
      <ActionLinkToDesigns />

      {missingSpec}
      {renderSpec}
    </Container>
  );
}
