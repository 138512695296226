export class CsrfStore {
  csrfToken = null
  csrfPromise = null
  fetchService = null

  constructor(fetchService) {
    // console.log('initialize Csrf', fetchService)
    this.fetchService = fetchService
  }

  _getTokenValue = async () => {
    try {
      // console.log('GET CSRF')
      const response = await this.fetchService.get('/auth/csrf', {
        skipCsrf: true,
        headers: {
          'cache-control': 'no-cache',
        },
      })

      if (response._csrf) {
        this.csrfToken = response._csrf
        return this.csrfToken
      }

      return null
    } catch (err) {
      err.message = `Error fetching CSRF token[1] -- ${err.message}`
      throw err
    }
  }

  getToken = async () => {
    if (this.csrfToken) return this.csrfToken
    if (this.csrfPromise) return this.csrfPromise

    try {
      this.csrfPromise = this._getTokenValue()
      return await this.csrfPromise
    } catch (err) {
      this.csrfToken = null
      this.csrfPromise = null
      err.message = `Error fetching CSRF token[2] -- ${err.message}`
      throw err
    }
  }
}
