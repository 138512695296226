import { Container as MantineContainer } from '@mantine/core'
import classes from './container.module.scss'

export const Container = (props) => {
  const { children, ...rest } = props

  return (
    <MantineContainer className={classes.wrapper} {...rest}>
      {children}
    </MantineContainer>
  )
}
