
import { useEffect, useState } from 'react'
import { useLoaderData, useNavigate } from 'react-router-dom'
import { z } from 'zod'
import { useForm, zodResolver } from '@mantine/form'
import {
  Container,
  SimpleGrid,
  TextInput,
  Title,
  Text,
  rem,
  createStyles,
  Center,
  Card,
} from '@mantine/core'

import { FormHeader } from '../../common/form-header'
import { ErrorSection } from '../../common/error-section'
import { SubmitButton } from '../../common/submit-button'
import { ROLES, formatRole } from './helper'
import { ActionLinkToUsers } from './shared/action-link-to-users'
import { saveUser } from './actions'
import { SelectUserRole } from './shared/select-user-role'

const useStyles = createStyles((theme) => ({
  card: {
    overflow: 'inherit',
  },
}))

/**
 *  
 */

export const transpile = (importedSpec = null) => {
  if (!importedSpec || importedSpec === undefined) return {}

  const {
    name,
    email,
    phone,
    role,
  } = importedSpec

  return {
    name,
    email,
    phone,
    role,
  }
}

const userSchema = z.object({
  name: z.string({
    required_error: 'You must provide a name',
    invalid_type_error: 'This name does not look right',
  }).min(3, { message: 'A name should be at least three characters' }),
  phone: z.string({
    required_error: 'You must provide a phone number',
    invalid_type_error: 'This phone number does not look right',
  }).length(10, { message: 'This phone number does not look right, it should be 10 digits (i.e., 3125551234)' }),
  role: z.string(),
})

export const ManageUserView = () => {
  const importedUser = useLoaderData()
  const { classes } = useStyles()
  const navigate = useNavigate()
  const [forbidden, setForbidden] = useState(false)

  const form = useForm({
    validate: zodResolver(userSchema.required()),
    initialValues: {
      name: '',
      phone: '',
      role: '',
    },
  })

  const { getInputProps: getFormProps } = form,
    [user, setUser] = useState(null),
    [submitting, setSubmitting] = useState(false),
    [error, setError] = useState(null)

  // load an imported ticket if provided
  useEffect(() => {
    if (importedUser && !importedUser.errorCode) {
      setUser(importedUser)
      const userValues = transpile(importedUser)
      form.setValues(userValues)
    } else if (importedUser && importedUser.errorCode) {
      if (importedUser.errorCode === 403) setForbidden(true)
    }
  }, [importedUser])

  const validate = () => {
    form.validate()
    return form.isValid()
  }

  /**
   * Save user
   */
  const submitUser = async () => {
    if (submitting) return
    if (!validate()) {
      setError('There was some invalid information found, please correct')
      console.warn('Unable to submit form', {
        errors: {
          user: form.errors,
        },
      })
      return
    }

    setError('')
    setSubmitting(true)

    if (await saveUser(user, form.values)) {
      navigate(`/dashboard/user/${user.userId || user.id}`)
    }

    setSubmitting(false)
  }

  const RoleOptions = Object.values(ROLES).map((roleKey) => ({
    label: formatRole(roleKey),
    value: roleKey,
  }))

  if (forbidden) {
    return (
      <Container my="md">
      <Title order={2} weight={500} mt="xl" mb="lg">View User</Title>
      <ActionLinkToUsers />

      <Card className={classes.card} withBorder padding="lg" radius="md">
        <Text>You lack permission to view this user</Text>
      </Card>
    </Container>
    )
  }

  return (
    <Container my="md">
      <Title order={2} weight={500} mt="xl" mb="lg">
        Manage User
      </Title>

      <ActionLinkToUsers />

      {/** Ticket Details */}
      <Card withBorder padding="lg" radius="md" mb="lg">
        <FormHeader>Personal Information</FormHeader>

        {/* Person Information (name, phone number) */}
        <SimpleGrid
          cols={3}
          mb="lg"
          spacing="md"
          breakpoints={[
            { maxWidth: '52rem', cols: 1, spacing: 'sm' },
            { maxWidth: '72rem', cols: 3, spacing: 'sm' },
            { minWidth: '92rem', cols: 3, spacing: 'md' },
          ]}
        >
          <TextInput
            label="Name"
            withAsterisk
            {...getFormProps('name')}
          />

          <TextInput
            label="Phone"
            placeholder="3125551234"
            maxLength={10}
            withAsterisk
            {...getFormProps('phone')}
          />
        </SimpleGrid>
      </Card>

      {/** Company Settings */}
      <Card className={classes.card} withBorder padding="lg" radius="md" mb="lg">
        <FormHeader>Company Settings</FormHeader>

        {/* Person Information (name, phone number) */}
        <SimpleGrid
          cols={3}
          mb="lg"
          spacing="md"
          breakpoints={[
            { maxWidth: '52rem', cols: 1, spacing: 'sm' },
            { maxWidth: '72rem', cols: 3, spacing: 'sm' },
            { minWidth: '92rem', cols: 3, spacing: 'md' },
          ]}
        >
          <SelectUserRole {...getFormProps('role')} dropdownPosition="top" />
        </SimpleGrid>
      </Card>

      {error ? (
        <ErrorSection message={error} />
      ) : null}

      <SimpleGrid
        mt={rem(50)}
        cols={2}
        spacing="md"
        breakpoints={[
          { maxWidth: '52rem', cols: 1, spacing: 'sm' },
          { maxWidth: '72rem', cols: 2, spacing: 'sm' },
          { minWidth: '92rem', cols: 2, spacing: 'md' },
        ]}
      >
        <Center>
          <SubmitButton
            onClick={submitUser}
            text="Update User"
            submitting={submitting}
          />
        </Center>
      </SimpleGrid>
    </Container>
  );
}
