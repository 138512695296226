import { useCallback, useEffect, useState } from "react"
import { IntervalPicker } from "./interval-picker"
import { Box, Paper, clsx } from "@mantine/core"
import { Product } from "./product"
import './style.scss'
import fetchService from "../../services/fetch.service"

export const ProductTable = (props) => {
  const {
    className,
    onProductsLoaded,
    onFetchingProducts,
    onError,
    products = [],
    setErrors,
    showDashboardLink,
    user = {},
  } = props
  
  const [priceInterval, setPriceInterval] = useState('month'),
    [filteredProducts, setFilteredProducts] = useState([]),
    [priceSelected, setPriceSelected] = useState()

  const handleProductSelected = useCallback(async ({ price }) => {
    if (priceSelected) return

    setPriceSelected(price.id)

    try {
      const response = await fetchService.post('/public/checkout', {
        timeout: (10 * 1000), // increase timeout to 10s in case partner is slow
        body: { priceId: price.id, userId: user.userId },
      })

      if (response.result.redirectUrl) {
        window.location.href = response.result.redirectUrl
      } else {
        console.warn('Unable to create checkout', response)
        throw new Error('No checkout redirect url provided')
      }
    } catch (err) {
      setPriceSelected(null)
      if (setErrors) {
        setErrors('We are unable to generate an invoice currently. Please try again here or through the eConcrete.ly dashboard.')
        if (showDashboardLink) showDashboardLink(true)
      }
    }
  }, [priceSelected, setPriceSelected, setErrors])

  useEffect(() => {
    const hasMatchingInterval = (price) => price.recurring.interval === priceInterval,
      productCards = []

    for (const product of products) {
      const price = product.price.find(hasMatchingInterval)
      if (!price) continue
      productCards.push({
        product,
        price,
      })
    }

    setFilteredProducts(productCards)
  }, [priceInterval, products])

  return (
    <Paper className="product-table-container" p="md">
      <IntervalPicker onClick={(interval) => {console.log('set to', interval); setPriceInterval(interval)}} interval={priceInterval} />
      <div className={clsx('product-table', className)}>
        {filteredProducts.map(({product, price}, idx) => (
          <Product
            key={product.id}
            order={idx}
            product={product}
            price={price}
            onSelect={handleProductSelected}
            priceSelected={priceSelected}
          />
        ))}
      </div>
    </Paper>
  )
}
