import { useEffect } from 'react'
import { Notifications } from '@mantine/notifications'
import {
  createStyles,
} from '@mantine/core'
import { Outlet, useNavigate } from 'react-router-dom'
import { useMediaQuery, useToggle } from '@mantine/hooks'
import { NavbarNested } from './navbar/navbar-nested'
import fetchService from '../services/fetch.service'
import browserStorageService, { STORAGE_KEY } from '../services/browser-storage.service'

const AUTOCLOSE_NOTIFICATION = 10 * 1000
const desktopNavWidth = '17rem'
const mobileNavWidth = '4rem'

const useStyles = createStyles((theme) => ({
  aside: {
    width: mobileNavWidth,
    transition: 'width 200ms ease-in-out',
    position: 'fixed',
    left: 0,
    top: 0,
    height: '100%',
    // overflow: 'scroll',
    paddingBottom: theme.spacing.md,
    zIndex: 100,

    /**
     * Simplify media query writing with theme functions
     * xs: 36em
     * sm: 48em 768px
     * md: 62em
     * lg: 75em
     * xl: 88em
     */
    [theme.fn.largerThan('sm')]: {
      width: desktopNavWidth,
    },
  },
  asideOpen: {
    width: desktopNavWidth,
  },
  main: {
    marginLeft: mobileNavWidth,

    [theme.fn.largerThan('sm')]: {
      marginLeft: desktopNavWidth,
    },
  },
}));

export const DashboardView = () => {
  const [activeMenu, toggleMenu] = useToggle([true, false]),
    { classes, cx } = useStyles(),
    navigate = useNavigate(),
    isDesktop = useMediaQuery('(min-width: 48em)')

  // force menu open when desktop mode, close menu when not
  useEffect(() => {
    const toggleState = isDesktop
      ? true
      : false

    toggleMenu(toggleState)
  }, [isDesktop, toggleMenu])

  useEffect(() => {
    const loadCurrentUser = async () => {
      const { result } = await fetchService.post('/auth/me')
      return result
    }

    loadCurrentUser()
      .then(async ({ user, preferences }) => {
        if (!browserStorageService.initialized) {
          browserStorageService.initialize(user.userId)
          await browserStorageService.createSession(user, { preferences })
        } else {
          await browserStorageService.updateSession(user, { preferences })
        }

        if (user.flags.blocked) {
          navigate('/logout')
        }
      })
      .catch((err) => {
        console.warn('Unable to load current user details', err)
      })
  }, [])

  const handleToggleMenu = () => {
    if (!isDesktop) {
      toggleMenu()
    }
  }

  return (
    <div className="wrapper">
      <aside className={cx(classes.aside, { [classes.asideOpen]: activeMenu === true, ['is-active']: activeMenu === true })}>
        <NavbarNested isDesktop={isDesktop} active={activeMenu} toggleMenu={handleToggleMenu} />
      </aside>

      <main className={cx(classes.main, { ['is-active']: activeMenu === true})}>
        <div className="wrapper-inner">
          <Outlet />
        </div>
      </main>
    </div>
  );
}
